import classNames from 'classnames';
import LineChartRechart from 'components/Charts/LineChartRechart';
import UsersReachedDrawer from 'components/ListLastUsersReached/components/UsersReachedDrawer';
import dayjs from 'dayjs';
import {object, string} from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {evolutionAnalyticsService} from 'services';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import {Swaler} from 'swaler';
import {generateDatesArray} from '../..';
import './_Styles.scss';

const propTypes = {
  evolution: object.isRequired,
  timeRange: object.isRequired,
  dataKey: string, // used to force a key value for data
};

const logger = new Swaler('ViewsChart');

const ViewsChart = ({evolution, timeRange, dataKey = null}) => {
  const [startDate, endDate] = timeRange;

  const isTour = evolution.type === EVOLUTION_TYPE_TOUR;
  const isBanner = evolution.type === EVOLUTION_TYPE_BANNER;
  const isHint = evolution.type === EVOLUTION_TYPE_HINT;
  const isSurvey = evolution.type === EVOLUTION_TYPE_SURVEY;
  const isChecklist = evolution.type === EVOLUTION_TYPE_CHECKLIST;

  const [breakdownDate, setBreakdownDate] = useState(null);

  const {data: analyticsHourly, refetch: refetchAnalyticsHourly} = useQuery({
    queryKey: ['analyticsHourly', evolution.uid, startDate, endDate],
    queryFn: () => {
      return evolutionAnalyticsService.getAnalyticsHourly({
        startDate,
        endDate: dayjs(endDate).add(1, 'day').toDate(),
        evolutionIds: [evolution.uid],
      });
    },
    onError: (err) => {
      logger.error('Could not fetch analytics, failed with err', err.message);
    },
  });

  useEffect(() => {
    refetchAnalyticsHourly();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange]);

  const datesArr = generateDatesArray(startDate, endDate).filter((d) =>
    dayjs(d).isBefore(dayjs().endOf('day'))
  );

  // use this if we ultimately want to be able to filter the series on segments
  // we will have to be careful of performance here probably
  const series = useMemo(() => {
    return datesArr.reduce((acc, cur) => {
      const date = cur.format('YYYY-MM-DD');
      const timestamp = cur.unix();

      const foundData = analyticsHourly?.filter((d) => {
        return dayjs.unix(d.createdAt / 1000).isSame(cur, 'day');
      });

      let dataValue = 0;
      if (foundData?.length > 0) {
        dataValue = foundData.reduce(
          (acc, cur) =>
            (acc += parseInt(
              dataKey != null
                ? cur[dataKey]
                : isTour
                ? cur.sum_tour_started_count
                : isBanner
                ? parseInt(cur.sum_banner_seen_count, 10) +
                  parseInt(cur.sum_tour_started_count, 10) // legacy
                : isHint
                ? cur.sum_hint_group_seen_count
                : isSurvey
                ? parseInt(cur.sum_survey_started_count, 10) +
                  parseInt(cur.sum_views_count, 10) // legacy
                : isChecklist
                ? parseInt(cur.sum_checklist_started_count, 10)
                : 0,
              10
            )),
          0
        );
      }
      acc.push({
        date,
        timestamp,
        count: dataValue,
      });

      return acc;
    }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datesArr, evolution, analyticsHourly]);

  return (
    <div className="views-chart-wrapper">
      <div
        className={classNames('views-chart', {
          empty: series == null,
        })}>
        {series != null ? (
          <LineChartRechart
            data={series}
            tooltipLabel={isChecklist ? 'user' : 'view'}
            onViewBreakdown={(timestamp) => {
              setBreakdownDate(timestamp);
            }}
          />
        ) : (
          <div className="no-data">No data to show</div>
        )}
      </div>

      {breakdownDate != null && (
        <UsersReachedDrawer
          isOpen={breakdownDate != null}
          onRequestClose={() => setBreakdownDate(null)}
          evolution={evolution}
          defaultStartDate={dayjs(breakdownDate * 1000).toDate()}
          defaultEndDate={dayjs(breakdownDate * 1000)
            .add(1, 'day')
            .toDate()}
          disableFilters
          eventType={
            isChecklist && dataKey === 'sum_checklist_dismissed_count'
              ? 'CHECKLIST_DISMISSED'
              : null
          }
          eventColumnLabel={
            isChecklist
              ? dataKey === 'sum_checklist_dismissed_count'
                ? 'Dismissed at'
                : 'Started checklist at'
              : null
          }
        />
      )}
    </div>
  );
};

ViewsChart.propTypes = propTypes;

export default ViewsChart;
