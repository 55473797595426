import Conditions from 'components/Conditions';
import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {func} from 'prop-types';
import React, {useContext} from 'react';
import {
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
} from 'services/steps';
import './_Styles.scss';

const propTypes = {
  onClose: func,
};
const defaultProps = {
  onClose: () => {},
};

const LogicManager = ({onClose}) => {
  const {
    controlledEvolution: evolution,
    selectedStep: step,
    updateStep,
    isInApp,
  } = useContext(BuilderContext);
  const {options = [], type} = step;

  const canAddConditions = [
    STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
    STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    STEP_TYPE_NPS,
    STEP_TYPE_SLIDER,
    STEP_TYPE_OPINION_SCALE,
  ].includes(type);

  return (
    <div className="logic-manager-wrapper">
      <div className="logic-manager">
        <div className="logic-manager-header">
          <div className="logic-manager-title">Conditions</div>
          {isInApp !== true && <i className="icon-close" onClick={onClose} />}
        </div>
        <Divider />
        <div className="conditions-wrapper">
          <Conditions
            step={step}
            steps={evolution.steps}
            options={options}
            updateStep={(upt) => updateStep(step.uid, upt)}
            canAddConditions={canAddConditions}
          />
        </div>
      </div>
    </div>
  );
};

LogicManager.propTypes = propTypes;
LogicManager.defaultProps = defaultProps;

export default LogicManager;
