import {BuilderContext} from 'contextes/builder';
import {buildStormwindUrl} from 'helpers/utils';
import IframeResizer from 'iframe-resizer-react';
import React, {useContext, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {projectService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('PostPreview');

const PostPreview = () => {
  const {evolution, selectedLanguage} = useContext(BuilderContext);

  const iframeRef = useRef(null);

  const sendPostPreviewUpdate = () => {
    if (iframeRef.current != null) {
      iframeRef.current?.sendMessage({
        action: 'POST_PREVIEW_UPDATE',
        data: {
          evolution: {
            ...evolution,
            preventCollapse: true,
          },
        },
      });
    }
  };

  useEffect(() => {
    sendPostPreviewUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evolution]);

  useEffect(() => {
    if (iframeRef.current != null) {
      iframeRef.current?.sendMessage({
        action: 'CHANGELOG_OVERRIDE_LANGUAGE',
        data: {language: selectedLanguage},
      });
    }
  }, [selectedLanguage]);

  const project = useSelector((state) => generalSelector.getProject(state));

  const url = buildStormwindUrl(
    projectService.getProjectPortalUrl(project),
    `/w/feed`,
    'preview=true'
  );

  return (
    <div className="post-preview">
      <div className="post-preview-title">Post Preview</div>
      <div className="changelog-wrapper">
        <IframeResizer
          forwardRef={iframeRef}
          title="changelog-preview-iframe"
          loading="lazy"
          src={url}
          className="changelog-iframe"
          allowFullScreen
          onMessage={({message}) => {
            try {
              const m = JSON.parse(message);

              if (m.action === 'POST_PREVIEW_REQUEST') {
                sendPostPreviewUpdate();
              }
            } catch (err) {
              logger.error(`Couldn't parse message sent by widget: ${err}`);
            }
          }}
        />
      </div>
    </div>
  );
};

export default PostPreview;
