import classNames from 'classnames';
import fontColorContrast from 'font-color-contrast';
import React from 'react';
import './_Styles.scss';
import Jimo from './imgs/jimo.svg';

const JimoLabel = ({background, backgroundColor}) => {
  const colorToUSe =
    backgroundColor != null
      ? backgroundColor
      : background?.type === 'gradient'
      ? background?.secondaryColor
      : background?.primaryColor;
  const textColor = fontColorContrast(
    colorToUSe != null
      ? colorToUSe.length > 7
        ? colorToUSe.slice(0, colorToUSe.length - 2)
        : colorToUSe
      : '#FFFFFF'
  );

  const theme = textColor === '#000000' ? 'light' : 'dark';

  return (
    <div className={classNames('poke-renderer-poke-label', theme)}>
      <span className="poke-label-content">
        Powered by
        <img src={Jimo} alt="Jimo" className="jimo-logo" />
      </span>
    </div>
  );
};

export default JimoLabel;
