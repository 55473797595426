import classNames from 'classnames';
import Markdown from 'markdown-to-jsx';
import React, {useContext, useEffect} from 'react';
import sanitizeHtml from 'sanitize-html';
import {decodeHtml} from '../../../Poke/components/BlockBody';
import {VariableToSpan} from '../../../Poke/components/BlockTitle';
import ClickableBlockOverlay from '../../../Poke/components/ClickableBlockOverlay';
import {BLOCK_CHECKLIST_HEADER_TITLE} from '../../../Poke/constants/blocks';
import {PokeContext, PokeStateContext} from '../../../Poke/context';
import './_Styles.scss';

const ChecklistTitle = () => {
  const {user} = useContext(PokeContext);
  const {
    blocks,
    onBlockSelected,
    onTaskSelected,
    selectedBlock,
    inBuilder,
    language,
    onUrlClick,
    isMinimized,
    addFontFamily,
  } = useContext(PokeStateContext);

  const block = blocks.find((b) => b.type === BLOCK_CHECKLIST_HEADER_TITLE);

  const {fontColor, fontFamily, ...restStyle} = block.style;

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  const translation = block.translations?.find((t) => t.language === language);

  const [title] = block?.value?.split('|-|');

  const sanitizedContent = sanitizeHtml(
    translation != null ? decodeHtml(translation.value) : decodeHtml(title),
    {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([
        'img',
        'iframe',
        'ins',
        'del',
        'variable',
      ]),
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        a: ['href', 'target'],
        variable: ['class', 'data-attribute', 'data-fallback', 'data-source'],
      },
    }
  );

  document.documentElement.style.setProperty(
    '--checklist-header-title-font-size',
    `${restStyle.fontSize}px`
  );

  const ATagtoDiv = ({children, ...props}) => {
    const {href} = props;
    return (
      <span className="a-div" onClick={() => onUrlClick(href)}>
        {children}
      </span>
    );
  };

  return (
    <div
      className={classNames('checklist-title', {
        'poke-block-clickable': inBuilder === true,
        selected: selectedBlock === BLOCK_CHECKLIST_HEADER_TITLE,
      })}
      style={{
        ...restStyle,
        fontFamily,
        color: fontColor,
      }}
      onClick={(e) => {
        if (inBuilder === true) {
          e.stopPropagation();

          onBlockSelected(BLOCK_CHECKLIST_HEADER_TITLE);
          onTaskSelected(null);
        }
      }}>
      <Markdown
        className="body-markdown"
        options={{
          overrides: {
            a: {
              component: ATagtoDiv,
            },
            variable: {
              component: (props) => (
                <VariableToSpan {...props} user={user} inBuilder={inBuilder} />
              ),
            },
          },
        }}>
        {sanitizedContent}
      </Markdown>

      <div
        className={classNames('checklist-expand', {
          'is-mini': isMinimized,
        })}>
        <i className="icon-chevron-bottom" />
      </div>

      <ClickableBlockOverlay />
    </div>
  );
};

export default ChecklistTitle;
