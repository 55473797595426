import classNames from 'classnames';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import AnimateHeight from 'react-animate-height';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {
  BLOCK_TYPE_ANIMATION,
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHECKLIST_DISMISS,
  BLOCK_TYPE_CHECKLIST_HEADER,
  BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
  BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
  BLOCK_TYPE_CHECKLIST_TASK_LIST,
  BLOCK_TYPE_CHECKLIST_TRIGGER,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_CONCEPT,
  BLOCK_TYPE_INTERVIEW,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_NPS,
  BLOCK_TYPE_OPEN_QUESTION,
  BLOCK_TYPE_OPINION,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_SLIDER,
  BLOCK_TYPE_STEPPER,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
} from 'services/steps';
import {v4 as uuidv4} from 'uuid';
import {getDefaultBlockFromType} from '../BlockManager/utils';
import {blocksObj} from '../Navigator';
import {MODE_ADD_BLOCK} from '../PokeBuilderSidebar';
import './_Styles.scss';

const propTypes = {};
const defaultProps = {};

const ChecklistNavigator = () => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const {
    selectedStepId,
    selectedBlockType,
    setSelectedBlockType,
    selectedStep: step,
    updateStep,
    setMode,
    selectedChecklistItemId,
    setSelectedChecklistItemId,
  } = useContext(BuilderContext);

  if (selectedStepId == null || step == null) {
    return <></>;
  }

  const {blocks = []} = step;

  const orderedBlockTypes = [
    BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
    BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
    BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
    BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
    BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
    BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
    BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA,
    BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
    BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
    BLOCK_TYPE_USER,
    BLOCK_TYPE_MEDIA,
    BLOCK_TYPE_LABEL,
    BLOCK_TYPE_TITLE,
    BLOCK_TYPE_BODY,
    BLOCK_TYPE_STEPPER,
    BLOCK_TYPE_CHOICE,
    BLOCK_TYPE_SLIDER,
    BLOCK_TYPE_NPS,
    BLOCK_TYPE_OPINION,
    BLOCK_TYPE_ANIMATION,
    BLOCK_TYPE_OPEN_QUESTION,
    BLOCK_TYPE_CONCEPT,
    BLOCK_TYPE_INTERVIEW,
    BLOCK_TYPE_PRIMARY_CTA,
    BLOCK_TYPE_SECONDARY_CTA,
  ];

  const NavigatorItem = ({
    type,
    iconClassName,
    iconWrapperClassName = '',
    name,
    deletable,
    level,
    taskItemId,
    className,
  }) => {
    const onDeleteClick = (e) => {
      e.stopPropagation();

      updateStep(step.uid, {
        blocks: blocks.filter((b) => {
          if (type === BLOCK_TYPE_CHECKLIST_TASK_ITEM) {
            return b.uid !== taskItemId && b.parentBlockId !== taskItemId;
          } else {
            if (taskItemId != null) {
              return b.type !== type || b.parentBlockId !== taskItemId;
            }
            return b.type !== type;
          }
        }),
      });

      if (selectedChecklistItemId != null) {
        setSelectedBlockType(BLOCK_TYPE_CHECKLIST_TASK_ITEM);
      } else {
        setSelectedBlockType(BLOCK_TYPE_CHECKLIST_HEADER);
      }
    };

    return (
      <div
        className={classNames('navigator-item', className, `level-${level}`, {
          selected:
            selectedBlockType === type &&
            (selectedBlockType !== BLOCK_TYPE_CHECKLIST_TASK_ITEM ||
              selectedChecklistItemId === taskItemId),
        })}
        onClick={() => {
          setSelectedBlockType(type);
          setSelectedChecklistItemId(taskItemId);
        }}>
        <div className={classNames('icon-wrapper', iconWrapperClassName)}>
          <i className={iconClassName} />
        </div>
        {name}
        {deletable === true && (
          <div className="delete-icon-wrapper" onClick={onDeleteClick}>
            <i className="icon-close" />
          </div>
        )}
      </div>
    );
  };

  const isHeaderSelected =
    selectedBlockType === BLOCK_TYPE_CHECKLIST_HEADER ||
    ([
      BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
      BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
      BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
    ].includes(selectedBlockType) &&
      selectedChecklistItemId == null);
  const isTaskListSelected =
    selectedBlockType === BLOCK_TYPE_CHECKLIST_TASK_LIST ||
    selectedChecklistItemId != null;

  const headerBlock = blocks.find(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_HEADER
  );
  const headerSubBlocks = blocks.filter(
    (b) => b.parentBlockId === headerBlock?.uid
  );
  const filteredHeaderSubBlocks = orderedBlockTypes.filter((type) =>
    headerSubBlocks.some((block) => block.type === type)
  );

  const taskItemBlocks = blocks
    .filter((b) => b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM)
    .sort((a, b) => {
      const valueA = a.value;
      const valueB = b.value;
      const [_, indexA] = valueA.split('|-|');
      const [__, indexB] = valueB.split('|-|');

      return indexA - indexB;
    });

  const hasDismiss =
    blocks.find((b) => b.type === BLOCK_TYPE_CHECKLIST_DISMISS) != null;

  const handleAddTask = () => {
    const newTaskId = uuidv4();

    const existingTaskItemBlock = blocks.find(
      (b) => b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM
    );
    const existingCheckboxBlock = blocks.find(
      (b) => b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX
    );
    const existingTitleBlock = blocks.find(
      (b) => b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE
    );
    const existingPrimaryCtaBlock = blocks.find(
      (b) => b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA
    );

    const newBlocks = [
      {
        ...getDefaultBlockFromType(
          BLOCK_TYPE_CHECKLIST_TASK_ITEM,
          project.defaultTheme
        ),
        ...(existingTaskItemBlock != null && {
          style: existingTaskItemBlock.style,
        }),
        uid: newTaskId,
        value: `Task #${taskItemBlocks.length + 1}|-|${taskItemBlocks.length}`,
      },
      {
        ...getDefaultBlockFromType(
          BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
          project.defaultTheme
        ),
        ...(existingCheckboxBlock != null && {
          style: existingCheckboxBlock.style,
        }),
        parentBlockId: newTaskId,
      },
      {
        ...getDefaultBlockFromType(
          BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
          project.defaultTheme
        ),
        ...(existingTitleBlock != null && {
          style: existingTitleBlock.style,
        }),
        parentBlockId: newTaskId,
      },
      {
        ...getDefaultBlockFromType(
          BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
          project.defaultTheme
        ),
        ...(existingPrimaryCtaBlock != null && {
          style: existingPrimaryCtaBlock.style,
        }),
        parentBlockId: newTaskId,
        value: 'Go',
      },
    ];

    updateStep(step.uid, {
      blocks: [...blocks, ...newBlocks],
    });

    setSelectedBlockType(BLOCK_TYPE_CHECKLIST_TASK_ITEM);
    setSelectedChecklistItemId(newTaskId);
  };

  return (
    <div className="checklist-navigator-wrapper">
      <div className="checklist-navigator">
        <div className="section-title">Navigator</div>
        <div
          className={classNames('blocks-wrapper', {
            'is-checklist-open': selectedBlockType == null,
          })}>
          <div
            className={classNames('blocks-title', {
              selected: selectedBlockType == null,
            })}
            onClick={() => {
              setSelectedBlockType(null);
              setSelectedChecklistItemId(null);
            }}>
            <i className="isax isax-task-square" />
            <div className={classNames('step-name')}>Checklist</div>
          </div>
          <div className="blocks">
            <NavigatorItem
              key={BLOCK_TYPE_CHECKLIST_HEADER}
              type={BLOCK_TYPE_CHECKLIST_HEADER}
              iconClassName="icon-text"
              iconWrapperClassName="header"
              name="Header"
              level={1}
              className={classNames({
                activated:
                  isHeaderSelected === true || selectedBlockType == null,
                'top-block': isHeaderSelected === true,
              })}
            />
            <div className={classNames('blocks', {})}>
              <AnimateHeight
                height={isHeaderSelected === true ? 'auto' : 0}
                duration={300}>
                <div
                  className={classNames('blocks', {
                    expanded: isHeaderSelected === true,
                  })}>
                  {filteredHeaderSubBlocks.map((type) => {
                    const {iconClassName, name} = blocksObj.find(
                      (b) => b.type === type
                    );
                    return (
                      <NavigatorItem
                        key={type}
                        type={type}
                        iconWrapperClassName={
                          type === BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS
                            ? 'progress'
                            : ''
                        }
                        iconClassName={iconClassName}
                        name={name}
                        level={2}
                        className={classNames({
                          activated: isHeaderSelected === true,
                        })}
                        deletable={
                          [
                            BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
                            BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
                          ].includes(type) !== true
                        }
                      />
                    );
                  })}
                  <div
                    className="add-block-wrapper subtitle-4 sw-1"
                    onClick={() => setMode(MODE_ADD_BLOCK)}>
                    <div className="add-block-content">
                      <i className="icon-plus" />
                      Add element
                    </div>
                  </div>
                </div>
              </AnimateHeight>
            </div>
            <NavigatorItem
              key={BLOCK_TYPE_CHECKLIST_TASK_LIST}
              type={BLOCK_TYPE_CHECKLIST_TASK_LIST}
              iconClassName="isax isax-firstline"
              iconWrapperClassName="task-list"
              name="Task List"
              level={1}
              className={classNames({
                activated:
                  (isTaskListSelected === true &&
                    selectedChecklistItemId == null) ||
                  selectedBlockType == null,
                'top-block': isTaskListSelected === true,
              })}
            />
            <AnimateHeight
              height={isTaskListSelected === true ? 'auto' : 0}
              duration={300}>
              <div className={classNames('blocks')}>
                {taskItemBlocks.map((item) => {
                  const {value, uid} = item;
                  const [name, indexOrder] = value.split('|-|');

                  const itemBlocks = blocks.filter(
                    (b) => b.parentBlockId === uid
                  );

                  const filteredBlocks = orderedBlockTypes.filter((type) =>
                    itemBlocks.some((block) => block.type === type)
                  );

                  const expanded =
                    selectedChecklistItemId === uid &&
                    selectedBlockType != null;

                  return (
                    <>
                      <NavigatorItem
                        key={uid}
                        blockId={uid}
                        type={BLOCK_TYPE_CHECKLIST_TASK_ITEM}
                        iconClassName="isax isax-tick-square"
                        iconWrapperClassName="task-item"
                        name={name}
                        level={2}
                        taskItemId={uid}
                        deletable
                        className={classNames({
                          activated:
                            selectedBlockType != null &&
                            isTaskListSelected === true &&
                            (selectedChecklistItemId === uid ||
                              selectedChecklistItemId == null),
                          'top-block': expanded === true,
                        })}
                      />
                      <AnimateHeight
                        height={expanded === true ? 'auto' : 0}
                        duration={300}>
                        <div
                          className={classNames('blocks', {
                            expanded: expanded === true,
                          })}>
                          {filteredBlocks.map((type) => {
                            const {iconClassName, name} =
                              blocksObj.find((b) => b.type === type) || {};

                            return (
                              <NavigatorItem
                                key={type}
                                blockId={uid}
                                type={type}
                                iconClassName={iconClassName}
                                name={name}
                                level={3}
                                taskItemId={uid}
                                deletable={
                                  [
                                    BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
                                    BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
                                  ].includes(type) !== true
                                }
                                className={classNames({
                                  activated: expanded === true,
                                })}
                              />
                            );
                          })}
                          <div
                            className="add-block-wrapper sw-1"
                            onClick={() => setMode(MODE_ADD_BLOCK)}>
                            <div className="add-block-content subtitle-4">
                              <i className="icon-plus" />
                              Add element
                            </div>
                          </div>
                        </div>
                      </AnimateHeight>
                    </>
                  );
                })}
                <div
                  className={classNames('add-block-wrapper sw-1', {
                    detached: selectedChecklistItemId != null,
                  })}
                  onClick={handleAddTask}>
                  <div className="add-block-content subtitle-4">
                    <i className="icon-plus" />
                    Add task
                  </div>
                </div>
              </div>
            </AnimateHeight>
            {hasDismiss === true && (
              <NavigatorItem
                key={BLOCK_TYPE_CHECKLIST_DISMISS}
                type={BLOCK_TYPE_CHECKLIST_DISMISS}
                iconClassName="isax isax-forbidden-2"
                iconWrapperClassName="checklist-dismiss"
                name="Dismiss"
                level={1}
                className={classNames({
                  activated:
                    selectedBlockType === BLOCK_TYPE_CHECKLIST_DISMISS ||
                    selectedBlockType == null,
                })}
              />
            )}
          </div>
        </div>
        <div className="blocks-wrapper empty">
          <div
            className={classNames('blocks-title', {
              selected: selectedBlockType === BLOCK_TYPE_CHECKLIST_TRIGGER,
            })}
            onClick={() => {
              setSelectedBlockType(BLOCK_TYPE_CHECKLIST_TRIGGER);
              setSelectedChecklistItemId(null);
            }}>
            <i className="icon-slot-snippet" />
            <div className="step-name">Trigger</div>
          </div>
        </div>
      </div>
    </div>
  );
};

ChecklistNavigator.propTypes = propTypes;
ChecklistNavigator.defaultProps = defaultProps;

export default ChecklistNavigator;
