import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import {BLOCK_TYPE_CHECKLIST_HEADER} from 'services/steps';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import './_Styles.scss';

const ChecklistHeader = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_CHECKLIST_HEADER, updateObj);
  };

  const block = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_HEADER
  );

  if (block == null) {
    return <></>;
  }

  const {style} = block;

  return (
    <div className="block-settings stepper">
      <div className="section">
        <div className="section-title">Style</div>
        <div className="section-content">
          <ChecklistHeaderStyle
            style={style}
            updateStyle={updateBlock}
            hideStepActive={style.type === 'text'}
          />
        </div>
      </div>
    </div>
  );
};

export const ChecklistHeaderStyle = ({style, updateStyle}) => {
  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Background</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Background color"
            value={style.backgroundColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, backgroundColor: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Border</div>
        <div className="section-item-content">
          <ColorPickerInput
            title="Border color"
            value={style.borderColor || null}
            onChange={(value) =>
              updateStyle({
                style: {
                  ...style,
                  borderColor: value,
                },
              })
            }
            erasable
          />
        </div>
      </div>
    </>
  );
};

export default ChecklistHeader;
