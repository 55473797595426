import {EVENT_ADD_BLOCK} from 'amplitude';
import amplitude from 'amplitude-js';
import Block from 'components/Block';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import {BLOCKS, CATEGORY_1, CATEGORY_2, CATEGORY_3} from 'helpers/poke';
import {useRef, useState} from 'react';
import './_Styles.scss';

export const getFilteredBlocks = (search) => {
  return search.length === 0
    ? BLOCKS
    : BLOCKS.filter(
        (b) =>
          b.title.toLowerCase().includes(search.toLowerCase()) ||
          b.description.toLowerCase().includes(search.toLowerCase())
      );
};

export const DropdownAddBlock = ({
  open = null,
  dropdownProps = {},
  omitBlocks = [],
  onAdd = () => {},
  onClose = () => {},
}) => {
  const [search, setSearch] = useState('');

  const refDropdownAdd = useRef();

  const handleAdd = (step) => {
    onAdd(step);
    refDropdownAdd.current.close();
  };
  const blocksCategory1 = getFilteredBlocks(search).filter(
    (b) => b.category === CATEGORY_1 && omitBlocks.includes(b.step) === false
  );
  const blocksCategory2 = getFilteredBlocks(search).filter(
    (b) => b.category === CATEGORY_2 && omitBlocks.includes(b.step) === false
  );
  const blocksCategory3 = getFilteredBlocks(search).filter(
    (b) => b.category === CATEGORY_3 && omitBlocks.includes(b.step) === false
  );

  return (
    <Dropdown
      innerRef={refDropdownAdd}
      className="dropdown-add-block"
      position="top right"
      offsetX={12}
      onClose={() => {
        setSearch('');
        onClose();
      }}
      repositionOnResize={false}
      open={open}
      {...dropdownProps}>
      <div className="search-wrapper">
        <Input
          labelTextLeft={<i className="icon-search"></i>}
          placeholder="Find a block type"
          muted
          value={search}
          onChange={({target}) => setSearch(target.value)}></Input>
      </div>
      <div className="block-categories">
        {blocksCategory1.length > 0 && (
          <>
            <div className="category-title">{CATEGORY_1}</div>
            <div className="items">
              {blocksCategory1.map((b, index) => (
                <Block
                  key={b.step || index}
                  muted
                  iconBackgroundColor={b.iconBackgroundColor}
                  title={b.title}
                  description={b.description}
                  icon={b.icon}
                  comingSoon={b.comingSoon}
                  beta={b.beta}
                  onClick={() => {
                    amplitude.getInstance().logEvent(EVENT_ADD_BLOCK, {
                      type: b.title,
                    });
                    handleAdd(b.step);
                  }}
                />
              ))}
            </div>
          </>
        )}
        {blocksCategory2.length > 0 && (
          <>
            <div className="category-title">{CATEGORY_2}</div>
            <div className="items">
              {blocksCategory2.map((b, index) => (
                <Block
                  key={b.step || index}
                  muted
                  iconBackgroundColor={b.iconBackgroundColor}
                  title={b.title}
                  description={b.description}
                  icon={b.icon}
                  comingSoon={b.comingSoon}
                  beta={b.beta}
                  onClick={() => {
                    amplitude.getInstance().logEvent(EVENT_ADD_BLOCK, {
                      type: b.title,
                    });
                    handleAdd(b.step);
                  }}
                />
              ))}
            </div>
          </>
        )}
        {blocksCategory3.length > 0 && (
          <>
            <div className="category-title">{CATEGORY_3}</div>
            <div className="items">
              {blocksCategory3.map((b, index) => (
                <Block
                  key={b.step || index}
                  muted
                  iconBackgroundColor={b.iconBackgroundColor}
                  title={b.title}
                  description={b.description}
                  icon={b.icon}
                  comingSoon={b.comingSoon}
                  beta={b.beta}
                  onClick={() => {
                    amplitude.getInstance().logEvent(EVENT_ADD_BLOCK, {
                      type: b.title,
                    });
                    handleAdd(b.step);
                  }}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </Dropdown>
  );
};
