import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import {Rules as RulesComponent} from 'scenes/PokeAudience/components/PathSelector';
import './_Styles.scss';

const propTypes = {};

const defaultProps = {};

const Rules = () => {
  const {controlledEvolution: evolution, setControlledEvolution: setEvolution} =
    useContext(BuilderContext);

  return (
    <div className="rules-modal">
      <RulesComponent
        paths={evolution.boostedPaths}
        onPathsChange={(paths) => {
          setEvolution({...evolution, boostedPaths: paths});
        }}
        pathOperator={evolution.boostedPathOperator}
        onPathOperatorChange={(operator) => {
          setEvolution({...evolution, boostedPathOperator: operator});
        }}
        small
      />
    </div>
  );
};

Rules.propTypes = propTypes;
Rules.defaultProps = defaultProps;

export default Rules;
