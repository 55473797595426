import {Element} from 'domhandler';
import {getDefaultBlockFromType} from 'scenes/PokeBuilder/components/BlockManager/utils';
import {buildLogic} from 'scenes/SuccessTracker/components/Event/components/EventConditions/utils';
import {F_BOOST_SLOT_TOP_BAR} from 'services/evolution';
import {
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHECKLIST,
  BLOCK_TYPE_CHECKLIST_HEADER,
  BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
  BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
  BLOCK_TYPE_CHECKLIST_TASK_LIST,
  BLOCK_TYPE_CHECKLIST_TRIGGER,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
  getDefaultStep,
  getDefaultStepStyle,
  STEP_CONDITION_ACTION_TYPE_EXPAND_CHECKLIST,
  STEP_TYPE_CHECKLIST,
  STEP_TYPE_SUCCESS,
} from 'services/steps';
import {payloadSlateToDomConfig, slateToHtml} from 'slate-serializers';
import {v4 as uuidv4} from 'uuid';

const config = {
  ...payloadSlateToDomConfig,
  elementTransforms: {
    ...payloadSlateToDomConfig.elementTransforms,
    a: ({node, children = []}) => {
      const attrs = {};
      if (node.linkType) {
        attrs['data-link-type'] = node.linkType;
      }
      if (node.newTab) {
        attrs.target = '_blank';
      }
      return new Element(
        'a',
        {
          href: node.url,
          ...attrs,
        },
        children
      );
    },
  },
};

export const createStepFromPost = (post, boostFlags) => {
  const blocks = [];

  const postBlocks = post.steps[0].blocks;

  const textBlock = postBlocks.find((block) => block.type === BLOCK_TYPE_TITLE);
  const bodyBlock = postBlocks.find((block) => block.type === BLOCK_TYPE_BODY);
  const labelBlock = postBlocks.find(
    (block) => block.type === BLOCK_TYPE_LABEL
  );
  const ctaBlock = postBlocks.find(
    (block) => block.type === BLOCK_TYPE_PRIMARY_CTA
  );
  const profileBlock = postBlocks.find(
    (block) => block.type === BLOCK_TYPE_USER
  );

  if (textBlock != null) {
    blocks.push({
      ...getDefaultBlockFromType(BLOCK_TYPE_TITLE),
      value: textBlock.value,
      translations: textBlock.translations.map((t) => ({
        ...t,
        uid: uuidv4(),
      })),
    });
  }

  // eslint-disable-next-line no-unused-vars
  const [_text, _action, optAction] = (ctaBlock?.value || '').split(';');

  // if there is a cta, add it with the same behavior as the original post
  if (ctaBlock != null && optAction === 'url-open') {
    blocks.push({
      ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
      value: ctaBlock.value,
      translations: ctaBlock.translations.map((t) => ({
        ...t,
        uid: uuidv4(),
      })),
    });
  } else {
    // otherwise, add a default cta to open the post
    blocks.push({
      ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
      value: `Read more;step-next;post-open;${post.uid}`,
    });
  }

  if (boostFlags !== F_BOOST_SLOT_TOP_BAR) {
    if (bodyBlock != null) {
      // if media_embed found in body, add a media block
      const media = bodyBlock.rawValue.find(
        (block) => ['img', 'media_embed'].includes(block.type) === true
      );
      if (media != null) {
        blocks.push({
          ...getDefaultBlockFromType(BLOCK_TYPE_MEDIA),
          value: `${media.type === 'img' ? 'image' : 'video'};${
            media.url
          };media;fill`,
        });
      }

      // remove images and videos from body
      const rawValue = bodyBlock.rawValue.filter((block) => {
        if (['media_embed', 'img'].includes(block.type) === true) {
          return false;
        }
        if (
          block.type === 'paragraph' &&
          block.children?.length === 1 &&
          block.children[0].text === ''
        ) {
          return false;
        }
        return true;
      });
      const value = slateToHtml(rawValue, config);

      blocks.push({
        ...getDefaultBlockFromType(BLOCK_TYPE_BODY),
        value: value,
        rawValue: rawValue,
        translations: bodyBlock.translations.map((t) => {
          const rawValue = t.rawValue.filter(
            (block) => block.type !== 'media_embed'
          );
          const value = slateToHtml(rawValue, config);

          return {
            ...t,
            uid: uuidv4(),
            value: value,
            rawValue: rawValue,
          };
        }),
      });
    }

    if (labelBlock != null) {
      const tags = post.tags.map((tag) => tag.name).join(' - ');

      blocks.push({
        ...getDefaultBlockFromType(BLOCK_TYPE_LABEL),
        value: tags,
      });
    }

    if (profileBlock != null) {
      blocks.push({
        ...getDefaultBlockFromType(BLOCK_TYPE_USER),
        value: profileBlock.value,
        file: profileBlock.file,
      });
    }
  }

  return getDefaultStep({
    blocks,
  });
};

export const getDefaultChecklistSteps = (project) => {
  const checklistHeaderId = uuidv4();
  const task1Id = uuidv4();
  const task2Id = uuidv4();

  const steps = [
    getDefaultStep({
      type: STEP_TYPE_CHECKLIST,
      blocks: [
        {
          ...getDefaultBlockFromType(
            BLOCK_TYPE_CHECKLIST_HEADER,
            project?.defaultTheme
          ),
          uid: checklistHeaderId,
        },
        {
          ...getDefaultBlockFromType(
            BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
            project?.defaultTheme
          ),
          parentBlockId: checklistHeaderId,
        },
        {
          ...getDefaultBlockFromType(
            BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
            project?.defaultTheme
          ),
          value: 'bar',
          parentBlockId: checklistHeaderId,
        },
        {
          ...getDefaultBlockFromType(
            BLOCK_TYPE_CHECKLIST_TASK_LIST,
            project?.defaultTheme
          ),
        },
        {
          ...getDefaultBlockFromType(
            BLOCK_TYPE_CHECKLIST_TASK_ITEM,
            project?.defaultTheme
          ),
          uid: task1Id,
          value: 'Task #1|-|0',
        },
        {
          ...getDefaultBlockFromType(
            BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
            project?.defaultTheme
          ),
          parentBlockId: task1Id,
        },
        {
          ...getDefaultBlockFromType(
            BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
            project?.defaultTheme
          ),
          parentBlockId: task1Id,
          value: 'Go',
        },
        {
          ...getDefaultBlockFromType(
            BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
            project?.defaultTheme
          ),
          parentBlockId: task1Id,
        },
        {
          ...getDefaultBlockFromType(
            BLOCK_TYPE_CHECKLIST_TASK_ITEM,
            project?.defaultTheme
          ),
          uid: task2Id,
          value: 'Task #2|-|1',
        },
        {
          ...getDefaultBlockFromType(
            BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
            project?.defaultTheme
          ),
          parentBlockId: task2Id,
        },
        {
          ...getDefaultBlockFromType(
            BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
            project?.defaultTheme
          ),
          parentBlockId: task2Id,
          value: 'Go',
        },
        {
          ...getDefaultBlockFromType(
            BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
            project?.defaultTheme
          ),
          parentBlockId: task2Id,
        },
        getDefaultBlockFromType(
          BLOCK_TYPE_CHECKLIST_TRIGGER,
          project?.defaultTheme
        ),
      ],
      triggers: [
        {
          uid: uuidv4(),
          conditions: [],
          logic: buildLogic(),
          actions: [
            {
              uid: uuidv4(),
              type: STEP_CONDITION_ACTION_TYPE_EXPAND_CHECKLIST,
            },
          ],
        },
      ],
      style: {
        ...getDefaultStepStyle(),
        ...(getDefaultBlockFromType(BLOCK_TYPE_CHECKLIST, project?.defaultTheme)
          ?.style || {}),
      },
    }),
    getDefaultStep({
      type: STEP_TYPE_SUCCESS,
      name: 'Success',
      blocks: [
        {
          ...getDefaultBlockFromType(BLOCK_TYPE_TITLE, project?.defaultTheme),
          value: 'Congrats 🎉',
          rawValue: [
            {
              object: 'block',
              type: 'paragraph',
              children: [
                {
                  object: 'text',
                  text: 'Congrats 🎉',
                },
              ],
              data: {},
            },
          ],
        },
        {
          ...getDefaultBlockFromType(BLOCK_TYPE_BODY, project?.defaultTheme),
          value: 'You have completed the checklist.',
          rawValue: [
            {
              object: 'block',
              type: 'paragraph',
              children: [
                {
                  object: 'text',
                  text: 'You have completed the checklist.',
                },
              ],
              data: {},
            },
          ],
        },
        {
          ...getDefaultBlockFromType(
            BLOCK_TYPE_PRIMARY_CTA,
            project?.defaultTheme
          ),
          value: 'Close;close;',
        },
      ],
    }),
  ];

  return steps;
};
