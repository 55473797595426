import classNames from 'classnames';
import Divider from 'components/Divider';
import RadioGroup from 'components/RadioGroup';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {useContext} from 'react';
import {getDefaultBlockFromType} from 'scenes/PokeBuilder/components/BlockManager/utils';
import {
  BLOCK_TYPE_CHECKLIST_DISMISS,
  F_STEP_CHECKLIST_STEP_COMPLETION_ORDERED,
  getDefaultStepStyle,
} from 'services/steps';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import {positionsArr} from '../../components/modals/Position';
import Shadow from '../../components/modals/Shadow';
import Width from '../../components/modals/Width';
import ChecklistConditionsWrapper from '../ChecklistListItem/ChecklistConditionsWrapper';
import './_Styles.scss';

const dismissOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

const completionOrderOptions = [
  {label: 'In order', value: true},
  {label: 'In any order', value: false},
];

const ChecklistSettings = () => {
  const {
    controlledEvolution: evolution,
    setControlledEvolution: setEvolution,
    selectedStep,
    updateStep,
  } = useContext(BuilderContext);

  const handleThemeStyleChange = (changes) => {
    updateStep(selectedStep?.uid, {
      style: {...selectedStep.style, ...changes},
    });
  };

  const stepStyle = selectedStep?.style || getDefaultStepStyle();

  const selectedAlignment = positionsArr.find(
    (o) => o.value === evolution?.boostedPositionFlags
  );

  const {triggers = [], blocks = []} = selectedStep;
  const autoExpandTrigger = triggers[0];

  const hasDismiss =
    blocks.find((b) => b.type === BLOCK_TYPE_CHECKLIST_DISMISS) != null;

  return (
    <div className="block-settings checklist-block">
      <div className="section">
        <div className="section-title">Behavior</div>
        <div className="section-content">
          <div className="section-item triggers">
            <div className="section-item-title">Auto expand</div>
            <div className="section-item-content">
              <ChecklistConditionsWrapper
                trigger={autoExpandTrigger}
                setTrigger={(trigger) => {
                  updateStep(selectedStep?.uid, {
                    triggers: [trigger],
                  });
                }}
                title="Auto expand checklist"
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Dismissible</div>
            <div className="section-item-content">
              <RadioGroup
                options={dismissOptions}
                value={hasDismiss}
                onSelect={(value) => {
                  if (value) {
                    updateStep(selectedStep?.uid, {
                      blocks: [
                        ...blocks,
                        getDefaultBlockFromType(
                          BLOCK_TYPE_CHECKLIST_DISMISS,
                          evolution.theme
                        ),
                      ],
                    });
                  } else {
                    updateStep(selectedStep?.uid, {
                      blocks: selectedStep.blocks.filter(
                        (b) => b.type !== BLOCK_TYPE_CHECKLIST_DISMISS
                      ),
                    });
                  }
                }}
              />
            </div>
          </div>

          <div className="section-item">
            <div className="section-item-title">Completion</div>
            <div className="section-item-content">
              <SelectGroup
                isSearchable={false}
                className="progress-option-select"
                options={completionOrderOptions}
                value={completionOrderOptions.find(
                  (o) =>
                    o.value ===
                    hasFlag(
                      F_STEP_CHECKLIST_STEP_COMPLETION_ORDERED,
                      selectedStep.stepFlags
                    )
                )}
                onChange={(option) => {
                  if (option.value === true) {
                    updateStep(selectedStep?.uid, {
                      stepFlags: addFlag(
                        F_STEP_CHECKLIST_STEP_COMPLETION_ORDERED,
                        selectedStep.stepFlags
                      ),
                    });
                  } else {
                    updateStep(selectedStep?.uid, {
                      stepFlags: removeFlag(
                        F_STEP_CHECKLIST_STEP_COMPLETION_ORDERED,
                        selectedStep.stepFlags
                      ),
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />

      <div className="section">
        <div className="section-title">General</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Position</div>
            <div className="section-item-content snippet-position-picker">
              <div className="alignment-figure-wrapper">
                <div className="alignment-figure">
                  <div className="alignment-label">
                    {selectedAlignment != null ? (
                      <>Always show from {selectedAlignment.position}</>
                    ) : (
                      <>Select a position</>
                    )}
                  </div>
                  {positionsArr.map((o) => {
                    if (o.value >= 128 || o.value === 8) {
                      return <></>;
                    }
                    return (
                      <div
                        className={classNames('position-dot', o.position, {
                          selected: evolution?.boostedPositionFlags === o.value,
                        })}
                        onClick={() =>
                          setEvolution({
                            ...evolution,
                            boostedPositionFlags: o.value,
                          })
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Width</div>
            <div className="section-item-content">
              <PopupSetting
                trigger={
                  <ClickableInput
                    value={
                      stepStyle.width != null
                        ? `${stepStyle.width}px`
                        : 'Default'
                    }
                  />
                }
                title="Width"
                content={
                  <Width
                    value={stepStyle.width}
                    onChange={(value) =>
                      updateStep(selectedStep?.uid, {
                        style: {...stepStyle, width: value},
                      })
                    }
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">Style</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Background</div>
            <div className="section-item-content background-picker">
              <ColorPickerInput
                title="Background color"
                value={stepStyle.backgroundColor || null}
                onChange={(value) =>
                  handleThemeStyleChange({backgroundColor: value})
                }
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Border</div>
            <div className="section-item-content">
              <ColorPickerInput
                title="Border color"
                value={stepStyle.borderColor || null}
                onChange={(value) =>
                  handleThemeStyleChange({borderColor: value})
                }
                erasable
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Shadow</div>
            <div className="section-item-content shadow-picker">
              <PopupSetting
                trigger={
                  <ClickableInput
                    value={stepStyle.shadow?.color}
                    leftLabel={
                      <span
                        className="preview-shadow-color"
                        style={{
                          background: stepStyle.shadow?.color || '#FFFFFF',
                        }}
                      />
                    }
                    erasable={true}
                    onErase={() =>
                      handleThemeStyleChange({
                        shadow: {color: null, opacity: 0, x: 0, y: 0, blur: 0},
                      })
                    }
                  />
                }
                title="Shadow"
                content={
                  <Shadow
                    value={stepStyle.shadow}
                    onChange={(value) =>
                      handleThemeStyleChange({shadow: value})
                    }
                  />
                }
              />
            </div>
          </div>
          {/* <div className="section-item">
            <div className="section-item-title">Overlay</div>
            <div className="section-item-content">
              <ColorPickerInput
                title="Overlay"
                value={stepStyle.overlay}
                onChange={(value) => handleThemeStyleChange({overlay: value})}
                erasable
                inputProps={{
                  onClick: () => {
                    if (stepStyle.overlay == null) {
                      handleThemeStyleChange({overlay: '#00000029'});
                    }
                  },
                }}
              />
            </div>
          </div> */}
          <div className="section-item">
            <div className="section-item-title">Radius</div>
            <div className="section-item-content">
              <PixelPicker
                value={stepStyle.borderRadius}
                min={0}
                max={50}
                onChange={(value) =>
                  handleThemeStyleChange({borderRadius: value})
                }
                radius
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default ChecklistSettings;
