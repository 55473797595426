import {default as classNames, default as classnames} from 'classnames';
import LivePreviewNew from 'components/LivePreviewNew';
import {BuilderContext} from 'contextes/builder';
import {GlobalContext} from 'contextes/Global';
import {hasFlag} from 'helpers/bitwise';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {
  F_BOOST_SLOT_HINT,
  F_OPTION_POKE_CARD_WITH_POINTER,
} from 'services/evolution';
import {
  BLOCK_TYPE_HINT,
  BLOCK_TYPE_HOTSPOT,
  F_STEP_HEIGHT_CUSTOM,
  F_STEP_IS_SELECTING_PRESET,
} from 'services/steps';
import {Poke} from 'shared/front/components/Poke';
import {BlockArrow} from 'shared/front/components/Poke/components/BlockArrow';
import ClickableBlockOverlay from 'shared/front/components/Poke/components/ClickableBlockOverlay';
import {
  F_SLOT_DOT,
  F_SLOT_NAVIGATION,
  F_SLOT_SNIPPET,
  F_SLOT_TOP_BAR,
} from 'shared/front/components/Poke/constants/poke';
import {PokeContext} from 'shared/front/components/Poke/context';
import {
  HINT_TYPE_HIDDEN,
  HINT_TYPE_ICON,
  HINT_TYPE_LABEL,
} from '../BlockEditor/blocks/Hint';
import {
  HOTSPOT_SHAPE_DEFAULT,
  HOTSPOT_SHAPE_EXCLAMATION_MARK,
  HOTSPOT_SHAPE_QUESTION_MARK,
} from '../BlockEditor/blocks/Hotspot';
import {
  HINT_ICON_BUILT_IN,
  HINT_ICON_CUSTOM,
  hintIconsList,
} from '../BlockEditor/components/modals/IconPicker';
import {positionsArr} from '../BlockEditor/components/modals/Position';
import {MODE_NAVIGATOR} from '../PokeBuilderSidebar';
import './_styles.scss';
import {BlockGradient} from './components/BlockGradient';
import JimoLabel from './components/JimoLabel';
import ZoomManager from './components/ZoomManager';
import {getHintPositionStyle, getSnippetPositioningStyle} from './utils';

export const PokeRender = () => {
  const {
    evolution,
    controlledEvolution: poke,
    selectedStepId,
    setSelectedBlockType,
    selectedBlockType,
    updateStep,
    selectedStep,
    inConcept,
    previewedPreset,
    selectedLanguage,
    setMode,
  } = useContext(BuilderContext);

  const project = useSelector((state) => generalSelector.getProject(state));

  const {addFontFamily} = useContext(GlobalContext);

  const [dimension, setDimension] = useState({
    width: 0,
    height: 0,
  });
  const [style, setStyle] = useState({});
  const [playAnimation, setPlayAnimation] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [hintDimension, setHintDimension] = useState({width: 0, height: 0});

  const isMounted = useRef(false);
  const prevSelectedStepId = useRef(null);
  const pokeRef = useRef();

  const [conceptParentStepId, conceptStepId] =
    inConcept != null ? inConcept.split('|') : [];
  const currentStepIndex =
    inConcept != null
      ? poke.steps.findIndex((s) => s.uid === conceptParentStepId)
      : poke.steps.findIndex((s) => s.uid === selectedStepId);
  const currentConceptStepIndex =
    inConcept != null
      ? poke.steps[currentStepIndex].prototypes[0].steps.findIndex(
          (s) => s.uid === conceptStepId
        )
      : 0;

  useEffect(() => {
    if (pokeRef.current != null) {
      const {width, height} = pokeRef.current.getBoundingClientRect();
      setDimension({width, height});
    }
  }, [pokeRef?.current?.scrollHeight, pokeRef?.current?.scrollWidth]);

  /** Animation In & Out : play it on change */
  useEffect(() => {
    if (
      isMounted.current === true &&
      selectedStepId === prevSelectedStepId.current
    ) {
      setPlayAnimation(poke.style?.animationIn?.concat('-in') ?? null);
    }
  }, [poke.style?.animationIn]);
  useEffect(() => {
    if (
      isMounted.current === true &&
      selectedStepId === prevSelectedStepId.current
    ) {
      setPlayAnimation(poke.style?.animationOut?.concat('-out') ?? null);
    }
  }, [poke.style?.animationOut]);

  useEffect(() => {
    if (isMounted.current === false) {
      isMounted.current = true;
    }
  }, []);

  useEffect(() => {
    prevSelectedStepId.current = selectedStepId;
  }, [selectedStepId]);

  const isNavigationStep = hasFlag(F_SLOT_NAVIGATION, poke.boostFlags);

  const handleSelectedBlockType = (type) => {
    setSelectedBlockType(type);
    setMode(MODE_NAVIGATOR);
  };

  if (isNavigationStep) {
    return <></>;
  }

  const isSnippet = hasFlag(F_SLOT_SNIPPET, poke.boostFlags);
  const isBanner = hasFlag(F_SLOT_TOP_BAR, poke.boostFlags);
  const isHotspot = hasFlag(F_SLOT_DOT, poke.boostFlags);
  const isHint = hasFlag(F_BOOST_SLOT_HINT, poke.boostFlags);

  const hasPointer = hasFlag(
    F_OPTION_POKE_CARD_WITH_POINTER,
    poke.optionsFlags
  );

  const {style: pokeStyle, boostedPositionFlags} = poke ?? {};
  const {background, shadow, borderRadius, borderColor} = pokeStyle ?? {};
  const {x, y, blur, color} = shadow ?? {};
  const boxShadow = color != null ? `${x}px ${y}px ${blur}px ${color}` : 'none';
  const {type, animated, primaryColor, secondaryColor} = background ?? {};
  const gradient = [primaryColor, secondaryColor];

  const pokeWrapperStyle = {
    ...(inConcept != null
      ? {backgroundColor: 'white'}
      : type === 'color'
      ? {backgroundColor: primaryColor}
      : type === 'gradient'
      ? animated === true
        ? {
            backgroundColor: secondaryColor || '#fff',
          }
        : {
            backgroundImage: `linear-gradient(180deg, ${gradient[0]}, ${
              gradient[0].length > 7
                ? `${gradient[0].slice(0, 7)}80`
                : `${gradient[0]}}80)`
            }`,
            backgroundColor: gradient[1],
          }
      : {}),
    boxShadow: boxShadow,
    ...(inConcept == null
      ? {borderRadius: `${borderRadius}px`}
      : {borderRadius: 'unset'}),
    ...(borderColor != null ? {outline: `1px solid ${borderColor}`} : {}),
  };

  const positionItem = positionsArr.find(
    (p) => p.value === poke.boostedPositionFlags
  );
  const position = positionItem?.position;

  const [indexTourStep] = poke.tourStepInfo?.split(';') ?? [0];

  const {stepsBefore, stepsAfter} = evolution?.tourSteps?.reduce(
    (acc, cur) => {
      if (cur.uid === poke.uid) {
        return acc;
      }

      const [index] = cur.tourStepInfo?.split(';');

      if (index < indexTourStep) {
        acc.stepsBefore =
          acc.stepsBefore + cur.steps.filter((s) => s.removed !== true).length;
      } else if (index > indexTourStep) {
        acc.stepsAfter =
          acc.stepsAfter + cur.steps.filter((s) => s.removed !== true).length;
      }
      return acc;
    },
    {stepsBefore: 0, stepsAfter: 0}
  );

  const isSelectingPreset = hasFlag(
    F_STEP_IS_SELECTING_PRESET,
    selectedStep?.stepFlags
  );

  if (isSelectingPreset === true) {
    if (previewedPreset == null) {
      return null;
    }
    return (
      <LivePreviewNew
        poke={previewedPreset.experience}
        selectedStepId={previewedPreset.stepId}
      />
    );
  }

  let forcedOffsetX = null,
    forcedOffsetY = null,
    pointerPosition = 'top';
  const pointerOffset = 16.970562748477143 / 2;
  if (position != null) {
    const [primaryPosition, secondaryPosition] = position.split('-');
    if (secondaryPosition === 'left') {
      forcedOffsetX =
        -dimension.width / 2 +
        hintDimension.width / 2 +
        borderRadius +
        pointerOffset;
    } else if (secondaryPosition === 'right') {
      forcedOffsetX =
        dimension.width / 2 -
        hintDimension.width / 2 -
        borderRadius -
        pointerOffset;
    } else if (secondaryPosition === 'bottom') {
      forcedOffsetY =
        dimension.height / 2 -
        hintDimension.height / 2 -
        borderRadius -
        pointerOffset;
    } else if (secondaryPosition === 'top') {
      forcedOffsetY =
        -dimension.height / 2 +
        hintDimension.height / 2 +
        borderRadius +
        pointerOffset;
    }

    if (primaryPosition === 'right') {
      pointerPosition = 'left';
    } else if (primaryPosition === 'bottom') {
      pointerPosition = 'top';
    } else if (primaryPosition === 'left') {
      pointerPosition = 'right';
    } else if (primaryPosition === 'top') {
      pointerPosition = 'bottom';
    }
  }

  return (
    <PokeContext.Provider
      value={{
        poke: {
          ...poke,
          steps: poke?.steps?.sort((a, b) => a.inderOrder - b.indexOrder),
          stepsBefore,
          stepsAfter,
        },
        currentStepIndex,
        currentConceptStepIndex,
      }}>
      <div
        className="poke-render-overlay"
        style={{backgroundColor: poke?.style?.overlay}}
      />
      <div
        className="poke-render-zoom-wrapper"
        style={{
          ...(isBanner === true
            ? {
                transform: ``,
                width: '100%',
                display: 'block',
              }
            : {}),
          ...(inConcept != null
            ? {
                transform: ``,
                width: '100%',
                height: '100%',
              }
            : {}),
        }}>
        <div
          className={classnames('poke-wrapper', {
            selected: selectedBlockType == null,
          })}
          style={{
            transform: `scale(${zoom})`,
            ...(isBanner === true
              ? {
                  transform: ``,
                  width: '100%',
                }
              : {}),
            ...(inConcept != null
              ? {
                  transform: ``,
                  width: '100%',
                  height: '100%',
                }
              : {}),
            ...(isSnippet && inConcept == null
              ? getSnippetPositioningStyle(boostedPositionFlags, zoom) // pass zoom to include in transform
              : {}),
          }}>
          <div
            className="poke-render-wrapper"
            style={{
              height: inConcept != null ? '100%' : 'auto',
            }}>
            <div
              className={classnames('poke-render', {
                [`poke-animation-${playAnimation}`]: playAnimation != null,
              })}
              style={{
                ...style,
                width: isBanner === true || inConcept != null ? '100%' : 'auto',
                height: inConcept != null ? '100%' : 'auto',
                position: 'relative',
                ...pokeWrapperStyle,
              }}>
              <Poke
                forwardRef={pokeRef}
                inBuilder
                inConcept={inConcept != null}
                JimoLabel={
                  project.whiteLabeling === false
                    ? () => <JimoLabel background={background} />
                    : undefined
                }
                onDimensionChange={() => {}}
                onContainerStyleChange={(style) => {
                  setStyle(style);
                }}
                onBlockSelected={handleSelectedBlockType}
                selectedBlock={selectedBlockType}
                language={selectedLanguage}
                onPokeHeightChange={({height, selectedStepId}) => {
                  if (
                    selectedStep != null &&
                    hasFlag(F_STEP_HEIGHT_CUSTOM, selectedStep.stepFlags) !==
                      true &&
                    height !== selectedStep.style?.height &&
                    height !== 0
                  ) {
                    updateStep(selectedStep.uid, {
                      style: {
                        ...selectedStep.style,
                        height: Math.min(height, 900),
                      },
                    });
                  }
                }}
                pointerDirection="top"
                addFontFamily={addFontFamily}
              />
              {inConcept == null && <BlockGradient poke={poke} />}
            </div>

            {hasPointer === true && (
              <BlockArrow
                direction={pointerPosition}
                pokeSize={{height: dimension.height, width: dimension.width}}
                borderRadius={poke.style?.borderRadius}
                forcedOffsetX={forcedOffsetX}
                forcedOffsetY={forcedOffsetY}
              />
            )}
            {inConcept == null && <ClickableBlockOverlay isNotCardElem />}
          </div>

          {isHint === true && (
            <Hint
              poke={poke}
              dimension={dimension}
              hintDimension={hintDimension}
              setHintDimension={setHintDimension}
              selected={selectedBlockType === BLOCK_TYPE_HINT}
              onClick={() => setSelectedBlockType(BLOCK_TYPE_HINT)}
            />
          )}
          {isHotspot === true && (
            <Dot
              poke={poke}
              dimension={dimension}
              onClick={() => setSelectedBlockType(BLOCK_TYPE_HOTSPOT)}
            />
          )}
        </div>
      </div>
      {isBanner !== true && <ZoomManager zoom={zoom} setZoom={setZoom} />}
    </PokeContext.Provider>
  );
};

export const Dot = ({poke, dimension, onClick = () => {}}) => {
  const {boostedDotStyle} = poke;
  const [backgroundColor, size, shape, animation] = boostedDotStyle.split(';');

  return (
    <div
      onClick={onClick}
      className={classnames('hotspot-preview', {
        'animation-pulse':
          animation === 'pulse' && shape === HOTSPOT_SHAPE_DEFAULT,
      })}
      style={{
        color: backgroundColor,
        width: `${size}px`,
        height: `${size}px`,
        transform: `translate(-50%, -50%) translate(-${
          dimension.width / 2 + 20
        }px, -${dimension.height / 2 + 20}px)`,
        ...(shape === HOTSPOT_SHAPE_DEFAULT ? {backgroundColor} : {}),
        ...([
          HOTSPOT_SHAPE_EXCLAMATION_MARK,
          HOTSPOT_SHAPE_QUESTION_MARK,
        ].includes(shape)
          ? {fontSize: `${size}px`}
          : {}),
      }}>
      {shape === HOTSPOT_SHAPE_QUESTION_MARK && (
        <i className="icon-hotspot-shape-1"></i>
      )}
      {shape === HOTSPOT_SHAPE_EXCLAMATION_MARK && (
        <i className="icon-hotspot-shape-2"></i>
      )}
    </div>
  );
};

export const Hint = ({
  poke,
  dimension,
  hintDimension = {width: 0, height: 0},
  setHintDimension = () => {},
  selected = false,
  onClick = () => {},
}) => {
  const {addFontFamily} = useContext(GlobalContext);

  const {steps} = poke;
  const step = steps[0];
  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_HINT);

  const {value = '', style = {}} = block ?? {};
  const {
    type,
    iconSource,
    iconName,
    iconUrl,
    fontSize,
    fontWeight,
    fontFamily,
    padding,
    borderRadius,
    backgroundColor,
    color,
    borderColor,
    iconColor,
    pulsating,
  } = style;

  useEffect(() => {
    addFontFamily(fontFamily);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontFamily]);

  let content = null;

  if (type === HINT_TYPE_ICON) {
    const icon = hintIconsList.find((i) => i.value === iconName);

    content = (
      <div
        ref={(r) => {
          if (
            r != null &&
            (r?.scrollWidth !== hintDimension.width ||
              r?.scrollHeight !== hintDimension.height)
          ) {
            setHintDimension({
              width: r?.scrollWidth || 0,
              height: r?.scrollHeight || 0,
            });
          }
        }}
        className={classNames('hint-icon-preview', 'animation-pulse')}
        style={{
          color: iconColor,
          width: `${fontSize}px`,
          height: `${fontSize}px`,
          fontSize: `${fontSize}px`,
        }}>
        {iconSource === HINT_ICON_BUILT_IN && iconName && icon?.icon}
        {iconSource === HINT_ICON_CUSTOM && iconUrl && (
          <img
            src={iconUrl}
            alt="hint"
            style={{width: '100%', height: '100%'}}
          />
        )}
        {pulsating === true && <div class="pulsating-circle" />}
      </div>
    );
  }

  if (type === HINT_TYPE_LABEL) {
    content = (
      <div
        ref={(r) => {
          if (
            r != null &&
            (r?.scrollWidth !== hintDimension.width ||
              r?.scrollHeight !== hintDimension.height)
          ) {
            setHintDimension({
              width: r?.scrollWidth || 0,
              height: r?.scrollHeight || 0,
            });
          }
        }}
        className="hint-label-preview"
        style={{
          backgroundColor: backgroundColor,
          fontWeight: fontWeight,
          padding: `${padding}px`,
          borderRadius: `${borderRadius}px`,
          fontSize: `${fontSize}px`,
          fontFamily: fontFamily,
          lineHeight: `${fontSize}px`,
          color: color,
          ...(borderColor != null ? {border: `1px solid ${borderColor}`} : {}),
        }}>
        {value}
      </div>
    );
  }

  if (type === HINT_TYPE_HIDDEN) {
    content = (
      <div
        ref={(r) => {
          if (
            r != null &&
            (r?.scrollWidth !== hintDimension.width ||
              r?.scrollHeight !== hintDimension.height)
          ) {
            setHintDimension({
              width: r?.scrollWidth || 0,
              height: r?.scrollHeight || 0,
            });
          }
        }}
        className="hint-hidden-preview body-4 n-700">
        <i className="icon-target" />
        Your target element
      </div>
    );
  }

  return (
    <div
      className={classNames('hint-wrapper', {selected: selected})}
      style={{
        ...getHintPositionStyle(poke, dimension, hintDimension),
        position: 'absolute',
      }}
      onClick={onClick}>
      {content}
      <ClickableBlockOverlay isNotCardElem />
    </div>
  );
};
