import {generalActions} from 'actions';
import {removeFlag} from 'helpers/bitwise';
import {useDispatch, useSelector} from 'react-redux';
import {dataSelector, generalSelector} from 'selectors';
import {projectService, subscriptionService} from 'services';
import {F_IS_PAYING, F_IS_TRIALING, PLAN_BASIC_ID} from 'services/subscription';

export const useUpdatePlan = () => {
  const dispatch = useDispatch();

  const plans = useSelector((state) =>
    dataSelector.getSubscriptionsPlans(state)
  );
  const project = useSelector((state) => generalSelector.getProject(state));

  const uptProject = (data) => dispatch(generalActions.uptProject(data));
  const uptSubscription = (data) =>
    dispatch(generalActions.uptProjectSubscription(data));

  async function update({planId, mau}) {
    const plan = plans.find((p) => p.uid === planId);
    console.log('planId => ', planId);
    console.log('plan => ', plan);
    const subscription = await subscriptionService.getSubscriptionById(
      project.subscription.uid,
      {
        relations: ['payment_method'],
      }
    );
    const updatedProject = await projectService.getProject();

    uptProject({
      ...updatedProject,
      thresholdJimers: planId === PLAN_BASIC_ID ? 50 : mau,
      thresholdSeats: planId === PLAN_BASIC_ID ? 2 : plan.seats,
    });
    uptSubscription({
      plan: planId,
      paymentMethodBrand: subscription.paymentMethodBrand,
      paymentMethodLast4: subscription.paymentMethodLast4,
      extraFlags:
        planId === PLAN_BASIC_ID
          ? removeFlag(
              F_IS_PAYING,
              removeFlag(F_IS_TRIALING, subscription.extraFlags)
            )
          : subscription.extraFlags,
    });
  }

  return {
    update,
  };
};
