import Divider from 'components/Divider';
import TextArea from 'components/TextArea';
import Toggle from 'components/Toggle';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {useContext, useState} from 'react';
import {F_EXTRA_ANONYMOUS_COMMENTS_DISABLED} from 'services/project';
import './_Styles.scss';

const Feedback = () => {
  const {project, setProject} = useContext(BuilderContext);

  const [isWelcomeTitleFocused, setIsWelcomeTitleFocused] = useState(false);
  const [isWelcomeMessageFocused, setIsWelcomeMessageFocused] = useState(false);

  const isAnonymousCommentsDisabled = hasFlag(
    F_EXTRA_ANONYMOUS_COMMENTS_DISABLED,
    project.extraFlags
  );

  return (
    <div className="block-settings changelog-feedback">
      <div className="section">
        <div className="section-title">Message</div>
        <div className="section-content">
          <TextArea
            value={
              isWelcomeTitleFocused === true
                ? project.portalWelcomeTitle
                : project.portalWelcomeTitle ||
                  `Help us improve ${project.name}`
            }
            placeholder={`Help us improve ${project.name}`}
            onChange={({target}) => {
              setProject({
                ...project,
                portalWelcomeTitle: target.value,
              });
            }}
            className="title-content-input welcome-title-content-input"
            onFocus={() => {
              setIsWelcomeTitleFocused(true);
            }}
            onBlur={() => {
              setIsWelcomeTitleFocused(false);
            }}
          />
          <TextArea
            value={
              isWelcomeMessageFocused === true
                ? project.portalWelcomeMessage
                : project.portalWelcomeMessage ||
                  `Welcome to our feedback changelog, you can vote potential update, preview upcoming features and have a real impact on ${project.name}.`
            }
            placeholder={`Welcome to our feedback changelog, you can vote potential update, preview upcoming features and have a real impact on ${project.name}.`}
            onChange={({target}) => {
              setProject({
                ...project,
                portalWelcomeMessage: target.value,
              });
            }}
            className="title-content-input welcome-message-content-input"
            onFocus={() => {
              setIsWelcomeMessageFocused(true);
            }}
            onBlur={() => {
              setIsWelcomeMessageFocused(false);
            }}
          />
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-content">
          <div className="section-item-toggle">
            <div className="section-item-text">
              <div className="section-item-title subtitle-4">
                Anonymous feedback
              </div>
              <div className="section-item-subtitle body-3 n-700">
                Allow anonymous users to submit feedback
              </div>
            </div>
            <div>
              <Toggle
                checked={isAnonymousCommentsDisabled === true ? false : true}
                onChange={() => {
                  const extraFlags = isAnonymousCommentsDisabled
                    ? removeFlag(
                        F_EXTRA_ANONYMOUS_COMMENTS_DISABLED,
                        project.extraFlags
                      )
                    : addFlag(
                        F_EXTRA_ANONYMOUS_COMMENTS_DISABLED,
                        project.extraFlags
                      );

                  setProject({
                    ...project,
                    extraFlags: extraFlags,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
