import Alert from 'components/Alert';
import Button from 'components/Button';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useEffect, useRef, useState} from 'react';
import ModalSelectElement from 'scenes/SuccessTracker/components/ModalSelectElement';
import {fileService} from 'services';
import {Swaler} from 'swaler';
import ManualSelectorEditor from '../../../ManualSelectorEditor';
import './_Styles.scss';

const logger = new Swaler('EditDropdown');

export const ElementSelector = ({
  data,
  onChange,
  isInput,
  withMultiple,
  withElementsCount,
  onModalOpen = () => {},
  onSelectElement = null,
}) => {
  const [showSelectElementModal, setShowSelectElementModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const {file, querySelector, querySelectorManual} = data;

  const showSelectElementModalRef = useRef();

  useEffect(() => {
    showSelectElementModalRef.current = showSelectElementModal;
    onModalOpen(showSelectElementModal);
  }, [showSelectElementModal]);

  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }
    const response = await fetch(file);
    const blob = await response.blob();
    const uploadableFile = new File([blob], 'upload.png', {type: 'image/png'});

    try {
      const uploadedFile = await fileService.uploadPublicFile({
        file: uploadableFile,
      });
      return uploadedFile;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to upload file', code);
      toastDanger([title, message], {actions});
      return null;
    }
  };

  const handleElementSelected = async (data) => {
    // If the modal is not open, do nothing (means this callback is called from another modal opened event)
    if (showSelectElementModalRef.current !== true) {
      return;
    }
    setIsUploading(true);
    const file = await uploadFile(data.image);
    setIsUploading(false);

    onChange({
      querySelector: data.querySelector,
      querySelectorManual: data.querySelectorManual,
      ...(file != null
        ? {
            file,
          }
        : {
            file: null,
          }),
    });
  };

  const wording = isInput ? 'input' : 'element';

  return (
    <div className="element-selector-wrapper">
      {querySelectorManual?.elementText != null ||
      querySelectorManual?.cssSelector != null ? (
        <ManualSelectorEditor
          querySelectorManual={querySelectorManual}
          onChange={(newQuerySelectorManual) => {
            onChange({
              querySelectorManual: {
                ...querySelectorManual,
                ...newQuerySelectorManual,
              },
              querySelector: querySelector,
              file: file,
            });
          }}
          onSwitchToAuto={() => {
            onChange({
              querySelectorManual: null,
              querySelector: querySelector,
              file: file,
            });
          }}
          withText={!isInput}
          withMultiple={withMultiple}
          withElementsCount={withElementsCount}
        />
      ) : !querySelector ? (
        <>
          <Alert info title={`Select your ${wording} in-app or manually`}>
            Start by clicking a button below to either select an {wording} in
            your app or manually set the selector.
          </Alert>
          <div className="select-element-btn-wrapper">
            <Button
              thin
              primary
              iconLeft="icon-target"
              onClick={() => {
                if (onSelectElement != null) {
                  onSelectElement();
                } else {
                  setShowSelectElementModal(true);
                }
              }}>
              Select {wording}
            </Button>
            <Button
              thin
              iconLeft="isax isax-edit-2"
              onClick={() =>
                onChange({
                  querySelectorManual: {
                    elementText: '',
                    cssSelector: '',
                    matchElement: 'first',
                  },
                  querySelector: querySelector,
                  file: file,
                })
              }>
              Manual selector
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="selector-preview-wrapper">
            {/* {file?.publicUrl != null ? (
              <img src={file?.publicUrl} alt="element" />
            ) : (
              <div className="image-not-found">
                <i className="icon-image" />
                No preview found
              </div>
            )} */}
            <div className="query-selector body-3 n-700">{querySelector}</div>
          </div>
          <div className="select-element-btn-wrapper">
            <Button
              thin
              primary
              onClick={() => {
                if (onSelectElement != null) {
                  onSelectElement();
                } else {
                  setShowSelectElementModal(true);
                }
              }}
              iconLeft="icon-target">
              Reselect {wording}
            </Button>
            <Button
              thin
              iconLeft="isax isax-edit-2"
              onClick={() =>
                onChange({
                  querySelectorManual: {
                    elementText: '',
                    cssSelector: querySelector,
                    matchElement: 'first',
                  },
                  querySelector: querySelector,
                  file: file,
                })
              }>
              Edit selector
            </Button>
          </div>
        </>
      )}

      {showSelectElementModal === true && (
        <ModalSelectElement
          isOpen={showSelectElementModal}
          onRequestClose={() => setShowSelectElementModal(false)}
          onElementSelected={handleElementSelected}
          onlyInput={isInput}
        />
      )}
    </div>
  );
};
