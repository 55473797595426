import {number} from 'prop-types';
import React, {useContext} from 'react';
import {AudienceContext} from 'scenes/PokeAudience';
import AudienceSection from 'scenes/PokeAudience/components/AudienceSection';
import './_Styles.scss';
import SummaryInfos from './components/SummaryInfos';
import SummaryPreview from './components/SummaryPreview';

const propTypes = {
  index: number.isRequired,
};

const Summary = () => {
  const {
    evolution,
    setSelectedSection,
    jimersCount,
    sections,
    setDisableAnimation,
  } = useContext(AudienceContext);

  return (
    <AudienceSection
      className="summary"
      title="Target Experience"
      titleOnly
      alwaysExpanded>
      <SummaryInfos
        evolution={evolution}
        setSelectedSection={setSelectedSection}
        jimersCount={jimersCount}
        sections={sections}
        setDisableAnimation={setDisableAnimation}
      />
      <SummaryPreview />
    </AudienceSection>
  );
};

Summary.propTypes = propTypes;

export default Summary;
