import classNames from 'classnames';
import React, {useContext, useEffect, useRef, useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {hasFlag} from '../../helpers/bitwise';
import {F_OPTION_DISMISS_ON_CROSS_CLICK} from '../Poke';
import {BlockClose} from '../Poke/components/BlockClose';
import LayoutBottom from '../Poke/components/LayoutBottom';
import LayoutMiddle from '../Poke/components/LayoutMiddle';
import LayoutTop from '../Poke/components/LayoutTop';
import {
  BLOCK_CHECKLIST_TASK_ITEM,
  BLOCK_CHECKLIST_TRIGGER,
} from '../Poke/constants/blocks';
import {PokeContext, PokeStateContext} from '../Poke/context';
import './_Styles.scss';
import ChecklistBody from './components/ChecklistBody';
import ChecklistFooter from './components/ChecklistFooter';
import ChecklistHeader from './components/ChecklistHeader';
import ChecklistTaskItem from './components/ChecklistTaskItem';
import {
  CHECKLIST_STEP_TYPE_CHECKLIST,
  CHECKLIST_STEP_TYPE_SUCCESS,
} from './utils';

const Checklist = ({
  inBuilder = false,
  selectedBlock = null,
  selectedTaskId = null,
  JimoLabel = null,
  isMinimized = false,
  completedTasks = [],
  language = null,

  addFontFamily = () => {},
  onBlockSelected = () => {},
  onTaskSelected = () => {},
  setIsMinimized = () => {},
  onDimensionChange = () => {},
  onClose = () => {},
  onChecklistDismissed = () => {},
  onChecklistCompleted = () => {},
  onTaskCompleted = () => {},
  onDisplaySuccessStep = () => {},
  onTaskClicked = () => {},
  onTaskSkipped = () => {},
}) => {
  const {poke, currentStepIndex: forceCurrentStepIndex} =
    useContext(PokeContext);

  const steps = poke?.steps ?? [];
  const checklistStep = steps.find(
    (step) => step.type === CHECKLIST_STEP_TYPE_CHECKLIST
  );

  const {blocks = []} = checklistStep;

  const [isHovering, setIsHovering] = useState(false);
  const [hoveredTaskId, setHoveredTaskId] = useState(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const checklistRef = useRef();
  const hoveringTaskTimeoutRef = useRef();

  const checklistHeight = checklistRef?.current?.clientHeight;
  const checklistWidth = checklistRef?.current?.clientWidth;

  const taskItemBlocks = blocks
    .filter((b) => b.type === BLOCK_CHECKLIST_TASK_ITEM)
    .sort((a, b) => {
      const valueA = a.value;
      const valueB = b.value;
      // eslint-disable-next-line no-unused-vars
      const [_, indexA] = valueA.split('|-|');
      // eslint-disable-next-line no-unused-vars
      const [__, indexB] = valueB.split('|-|');

      return indexA - indexB;
    });

  const currentStep = poke.steps[currentStepIndex];

  const isChecklistStep = currentStep?.type === CHECKLIST_STEP_TYPE_CHECKLIST;
  const isSuccessStep = currentStep?.type === CHECKLIST_STEP_TYPE_SUCCESS;

  useEffect(() => {
    if (inBuilder !== true) {
      return;
    }

    if (selectedBlock === BLOCK_CHECKLIST_TRIGGER) {
      setIsMinimized(true);
    } else {
      setIsMinimized(false);
    }
  }, [selectedBlock]);

  useEffect(() => {
    if (!checklistHeight || !checklistWidth) {
      return;
    }
    onDimensionChange([checklistWidth, checklistHeight]);
  }, [checklistHeight, checklistWidth]);

  useEffect(() => {
    if (forceCurrentStepIndex != null) {
      setCurrentStepIndex(forceCurrentStepIndex);
    }
  }, [forceCurrentStepIndex]);

  useEffect(() => {
    setHoveredTaskId(null);
  }, [selectedBlock]);

  useEffect(() => {
    if (inBuilder !== true) {
      return;
    }

    if (hoveredTaskId != null && isHovering === false) {
      clearTimeout(hoveringTaskTimeoutRef.current);
      hoveringTaskTimeoutRef.current = setTimeout(() => {
        setHoveredTaskId(null);
      }, 1000);
    } else {
      clearTimeout(hoveringTaskTimeoutRef.current);
    }
  }, [hoveredTaskId, isHovering]);

  useEffect(() => {
    if (completedTasks.length === taskItemBlocks.length) {
      onChecklistCompleted();
      setTimeout(() => {
        setCurrentStepIndex(1);
      }, 1000);
    }
  }, [completedTasks]);

  useEffect(() => {
    if (isSuccessStep === true) {
      onDisplaySuccessStep();
    }
  }, [isSuccessStep]);

  const triggerBlock = blocks.find((b) => b.type === BLOCK_CHECKLIST_TRIGGER);

  const shouldShowBlockClose =
    hasFlag(F_OPTION_DISMISS_ON_CROSS_CLICK, poke.optionsFlags) === true;

  const nextTaskToComplete =
    inBuilder === true
      ? taskItemBlocks[1]
      : taskItemBlocks.find((task) => !completedTasks.includes(task.uid));

  if (triggerBlock == null) {
    return null;
  }

  const {style} = currentStep;
  const {style: triggerStyle} = triggerBlock;

  const {backgroundColor, background, shadow, borderRadius, borderColor} =
    style ?? {};
  const {x, y, blur, color} = shadow ?? {};
  const boxShadow = color != null ? `${x}px ${y}px ${blur}px ${color}` : 'none';
  const {type, animated, primaryColor, secondaryColor} = background ?? {};
  const gradient = [primaryColor, secondaryColor];

  const checklistStyle = {
    ...(isMinimized === true && isChecklistStep === true
      ? triggerStyle?.width != null
        ? {width: `${triggerStyle.width}px`}
        : {}
      : {}),
    ...(isMinimized === false || isSuccessStep === true
      ? style?.width != null
        ? {width: `${style.width}px`}
        : {}
      : {}),
    ...(isChecklistStep === true
      ? {
          backgroundColor,
        }
      : {}),
    ...(isSuccessStep === true
      ? type === 'color'
        ? {backgroundColor: primaryColor}
        : type === 'gradient'
        ? animated === true
          ? {
              backgroundColor: secondaryColor || '#fff',
            }
          : {
              backgroundImage: `linear-gradient(180deg, ${gradient[0]}, ${
                gradient[0].length > 7
                  ? `${gradient[0].slice(0, 7)}80`
                  : `${gradient[0]}}80)`
              }`,
              backgroundColor: gradient[1],
            }
        : {}
      : {}),

    boxShadow: boxShadow,
    borderRadius: `${borderRadius}px`,
    ...(borderColor != null ? {outline: `1px solid ${borderColor}`} : {}),
  };

  const {style: wrapperStyle} = poke;
  const hasElementAnimationsDisabled =
    wrapperStyle?.withElementsAnimations === false;

  return (
    <PokeStateContext.Provider
      value={{
        addFontFamily,
        inBuilder,
        onBlockSelected,
        onTaskSelected,
        selectedBlock: hoveredTaskId ? null : selectedBlock,
        selectedTaskId:
          hoveredTaskId || selectedTaskId || nextTaskToComplete?.uid,
        blocks,
        JimoLabel,
        setHoveredTaskId,
        isMinimized,
        setIsMinimized,
        completedTasks,
        currentStep,
        language,
        close: onClose,
        onDismiss: onChecklistDismissed,
        onTaskCompleted,
        onTaskClicked,
        onTaskSkipped,
      }}>
      <div
        ref={checklistRef}
        className={classNames('checklist', {
          'is-mini': isMinimized === true && isChecklistStep === true,
          'disable-elements-animations':
            isSuccessStep === true && hasElementAnimationsDisabled === true,
          'is-success': isSuccessStep === true,
        })}
        onClick={() => {
          onBlockSelected(null);
          onTaskSelected(null);
        }}
        style={{
          ...checklistStyle,
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}>
        {isChecklistStep && (
          <>
            <ChecklistHeader />
            <AnimateHeight duration={200} height={isMinimized ? 0 : 'auto'}>
              <ChecklistBody>
                {taskItemBlocks.map((task, index) => (
                  <ChecklistTaskItem
                    key={task.uid}
                    task={task}
                    completed={
                      inBuilder === true
                        ? index === 0
                        : completedTasks.includes(task.uid)
                    }
                    isLastTask={index === taskItemBlocks.length - 1}
                  />
                ))}
              </ChecklistBody>
              <ChecklistFooter />
            </AnimateHeight>
          </>
        )}
        {isSuccessStep && (
          <>
            <LayoutTop />
            <LayoutMiddle />
            <LayoutBottom JimoLabel={JimoLabel} />
            {shouldShowBlockClose === true && <BlockClose />}
          </>
        )}
      </div>
    </PokeStateContext.Provider>
  );
};

export default Checklist;
