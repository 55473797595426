import {uptProject} from 'actions/general';
import Button from 'components/Button';
import {ModalConfirm} from 'components/Modal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Tooltip from 'components/Tooltip';
import {BuilderContext} from 'contextes/builder';
import {errorHelpers} from 'helpers';
import {addFlag, hasFlag} from 'helpers/bitwise';
import {isPokeValid} from 'helpers/poke';
import {bool} from 'prop-types';
import {useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useRouteMatch} from 'react-router-dom/cjs/react-router-dom.min';
import {
  ROUTE_CHANGELOG_BUILDER,
  ROUTE_CHANGELOG_BUILDER_WITH_ID,
  ROUTE_FEED,
  ROUTE_POST_BUILDER_AUDIENCE,
  ROUTE_POST_BUILDER_WRITER,
} from 'router/routes.const';
import {evolutionService, projectService} from 'services';
import {F_EXTRA_HAS_PUBLISHED_CHANGELOG} from 'services/project';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('PokeBuilderHeader');

const propTypes = {
  isFetching: bool,
  isInApp: bool,
  isLoading: bool,
};

const defaultProps = {
  isFetching: false,
  isInApp: false,
  isLoading: false,
};

const tabChangelog = (
  <>
    <i className="isax isax-directbox-notif" />
    Customize Changelog
  </>
);
const tabPost = (
  <>
    <i className="isax isax-edit" />
    Write Post
  </>
);

const PostBuilderHeader = ({isFetching, isInApp, isLoading}) => {
  const routeMatch = useRouteMatch();
  const history = useHistory();

  const dispatch = useDispatch();
  const updateProject = (project) => {
    dispatch(uptProject(project));
  };

  const {
    evolution,
    hasUnSaveChanges,
    refetchEvolution,
    messenger,
    lastAutoSaveAt,
    changelogTheme: theme,
    project,
  } = useContext(BuilderContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [troubleshootingMenuOpen, setTroubleshootingMenuOpen] = useState(false);

  const hasAlreadyPublishedChangelog = hasFlag(
    F_EXTRA_HAS_PUBLISHED_CHANGELOG,
    project.extraFlags
  );

  const issues = isPokeValid(evolution);
  const isSubmitDisabled = issues !== true;

  useEffect(() => {
    if (isSubmitDisabled !== true) {
      setTroubleshootingMenuOpen(false);
    }
  }, [isSubmitDisabled]);

  const savePoke = async () => {
    setIsSaving(true);
    let success = false;
    try {
      await evolutionService.updateEvolution(evolution.uid, {
        ...evolution,
      });
      await refetchEvolution();
      toastSuccess('Changes saved!', {toastId: 'changes-saved'});
      success = true;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Update evolution failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsSaving(false);
    }

    return success;
  };

  const saveProject = async () => {
    setIsSaving(true);
    try {
      const updatedProject = await projectService.updateProject(project.uid, {
        ...project,
        changelogStyle: theme,
        ...(hasFlag(F_EXTRA_HAS_PUBLISHED_CHANGELOG, project.extraFlags) !==
          true && {
          extraFlags: addFlag(
            F_EXTRA_HAS_PUBLISHED_CHANGELOG,
            project.extraFlags
          ),
        }),
      });
      updateProject(updatedProject);
      toastSuccess('Changes saved!', {toastId: 'changes-saved'});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('An error occurred in changelog theme editor : ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsSaving(false);
    }
  };

  const handleTargetAndPublishClick = async () => {
    setIsSubmitting(true);
    const success = await savePoke();
    setIsSubmitting(false);

    if (success === true) {
      refetchEvolution();
      if (isInApp === true) {
        messenger.sendEvolutionSaved({evolution});
      } else {
        history.push(ROUTE_POST_BUILDER_AUDIENCE(evolution.uid));
      }
    }
  };

  const isSetupChangelog = [
    ROUTE_CHANGELOG_BUILDER,
    ROUTE_CHANGELOG_BUILDER_WITH_ID(),
  ].includes(routeMatch.path);
  const isPostWriter = routeMatch.path === ROUTE_POST_BUILDER_WRITER();

  return (
    <div className="post-builder-header">
      <div className="left-wrapper">
        <Button
          thin
          className="btn-back"
          iconLeft="icon-arrow-left"
          iconOnly
          onClick={() => {
            if (hasUnSaveChanges()) {
              setShowExitModal(true);
            } else {
              history.push(ROUTE_FEED);
            }
          }}>
          Leave
        </Button>
      </div>
      <div className="center-wrapper">
        {/* {hasAlreadyPublishedChangelog !== true && (
          <Tabs
            defaultTab={isSetupChangelog ? tabChangelog : tabPost}
            onTabChange={async (tab) => {
              if (tab === tabChangelog && !isSetupChangelog) {
                if (evolution?.uid != null) {
                  history.push(ROUTE_CHANGELOG_BUILDER_WITH_ID(evolution?.uid));
                }
              } else if (tab === tabPost && !isPostWriter) {
                await saveProject();
                history.push(
                  ROUTE_POST_BUILDER_WRITER(evolution?.uid || 'new')
                );
              }
            }}>
            <div label={tabChangelog} disabled={isSaving || isLoading} />
            <div label={tabPost} disabled={isSaving || isLoading} />
          </Tabs>
        )} */}
      </div>
      <div className="right-wrapper">
        {evolution.isDraft === true && lastAutoSaveAt != null && (
          <Tooltip
            key={lastAutoSaveAt}
            className="tooltip-auto-save"
            arrow
            arrowStyle={{
              color: '#159201',
              width: '12px',
              height: '8px',
            }}
            trigger={
              <div key={lastAutoSaveAt} className="last-auto-save">
                <i className="icon-tick"></i>
              </div>
            }
            offsetY={2}
            offsetX={10}>
            Work automatically saved!
          </Tooltip>
        )}
        {isSetupChangelog && hasAlreadyPublishedChangelog === true ? (
          <Button
            loading={isSaving && !isSubmitting}
            primary
            disabled={isSubmitting}
            onClick={() => {
              saveProject();
            }}>
            Save changes
          </Button>
        ) : (
          <>
            <Button
              loading={isSaving && !isSubmitting}
              disabled={isSubmitting}
              onClick={() => {
                if (isSetupChangelog) {
                  saveProject();
                } else if (isPostWriter) {
                  savePoke();
                }
              }}>
              Save
            </Button>
            <Button
              reverted
              primary
              loading={isSubmitting}
              onClick={async () => {
                if (isSetupChangelog) {
                  await saveProject();
                  history.push(
                    ROUTE_POST_BUILDER_WRITER(evolution?.uid || 'new')
                  );
                } else if (isPostWriter) {
                  handleTargetAndPublishClick();
                }
              }}
              iconRight="icon-arrow-right">
              {isSetupChangelog ? 'Create post' : 'Target & publish'}
            </Button>
          </>
        )}
      </div>
      <ModalConfirm
        className="exit-modal"
        title="Exit without saving?"
        isOpen={showExitModal}
        onConfirm={() => {
          setShowExitModal(false);
          history.push(ROUTE_FEED);
        }}
        onCancel={() => setShowExitModal(false)}
        cancelText="Cancel"
        confirmText="Exit"
        cancelBtnProps={{
          cta: false,
        }}
        confirmBtnProps={{
          danger: true,
          primary: false,
          cta: false,
        }}>
        <div className="content">
          You have unsaved changes, are you sure you want to exit without
          saving?
        </div>
      </ModalConfirm>
    </div>
  );
};

PostBuilderHeader.propTypes = propTypes;
PostBuilderHeader.defaultProps = defaultProps;

export default PostBuilderHeader;
