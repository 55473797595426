import commaNumber from 'comma-number';
import Dropdown from 'components/Dropdown';
import {Menu, MenuItem} from 'components/Menu';
import SelectGroup from 'components/Select';
import {useRef} from 'react';
import {useSelector} from 'react-redux';
import {dataSelector} from 'selectors';
import {ADDON_MAU, getMauPrice, hasAnnualBilling} from 'services/subscription';

export const DropdownMau = ({trigger, onSelect, asSelector = false, mau}) => {
  const addons = useSelector((state) =>
    dataSelector.getSubscriptionsAddons(state)
  );

  const refDropdownMau = useRef(null);

  const addonMau = addons.find((addon) => addon.uid === ADDON_MAU);
  const tiers = addonMau?.prices
    ?.find(
      (price) =>
        (hasAnnualBilling() === true && price.recurrence === 'year') ||
        (hasAnnualBilling() === false && price.recurrence === 'month')
    )
    ?.tiers.map((t) => (t.upTo == null ? {...t, upTo: 50000} : t))
    .sort((a, b) => {
      return a.upTo - b.upTo;
    });
  const selectOptions = tiers.map((t) => {
    const price = getMauPrice(t.upTo ?? 50000, hasAnnualBilling());

    return {
      label: `${commaNumber(t.upTo ?? 50000)} MAU - `.concat(
        price === 0
          ? 'Included in your plan'
          : `$${price}/${hasAnnualBilling() ? 'year' : 'month'}`
      ),
      value: t.upTo ?? 50000,
    };
  });

  if (asSelector === true) {
    return (
      <SelectGroup
        className="select-cta-action"
        value={selectOptions.find((o) => o.value === mau)}
        options={selectOptions}
        onChange={(o) => onSelect(o.value)}
      />
    );
  }
  return (
    <Dropdown
      className="checkout-mau-dropdown"
      innerRef={refDropdownMau}
      trigger={trigger}
      position="bottom"
      offsetX={4}>
      <Menu className="dropdown-mau">
        {tiers?.map((tier) => (
          <MenuItem
            key={tier.upTo}
            className="subtitle-3"
            onClick={() => {
              refDropdownMau.current.close();
              onSelect(tier.upTo);
            }}>
            {commaNumber(tier.upTo ?? 50000)} MAU
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
};
