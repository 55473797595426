import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import {useEffect, useState} from 'react';
import {
  CustomIndicatorLabel,
  TriggerContext,
} from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/Trigger';
import TriggerActions from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/TriggerActions';
import EventConditions, {
  AddConditionDropdown,
} from 'scenes/SuccessTracker/components/Event/components/EventConditions';
import {buildLogic} from 'scenes/SuccessTracker/components/Event/components/EventConditions/utils';
import {EVENT_CONDITION_TYPE_TOUR_SEEN} from 'services/event';
import './_Styles.scss';

const ChecklistCompletedConditions = ({
  trigger,
  setTrigger: handleChange,
  newlyAddedCondition,
}) => {
  const {conditions: conditionsProp, logic: logicProp} = trigger;

  const [conditions, setConditions] = useState(conditionsProp || []);
  const [logic, setLogic] = useState(logicProp || buildLogic(trigger));

  useEffect(() => {
    handleChange({
      ...trigger,
      conditions,
      logic,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions, logic]);

  return (
    <TriggerContext.Provider
      value={{
        trigger,
        setTrigger: (update) => {
          handleChange({
            ...trigger,
            ...update,
          });
        },
        isExpanded: true,
        preventAddAction: true,
      }}>
      <div className="checklist-completed-conditions">
        <div className="trigger-conditions">
          {conditions.length > 0 && (
            <CustomIndicatorLabel text="If" className="if" />
          )}
          <EventConditions
            conditions={conditions}
            setConditions={setConditions}
            logic={logic}
            setLogic={setLogic}
            borderless
            compact
            settingsPositioning={['right center', 'left center']}
            extraConditionTypes={[EVENT_CONDITION_TYPE_TOUR_SEEN]}
            CustomEmptyState={(props) => (
              <AddConditionDropdown
                trigger={
                  <div className="custom-condition-empty-state">
                    <CustomIndicatorLabel text="If" className="if" />
                    <Button
                      className="add-condition-btn"
                      thin
                      tertiary
                      iconLeft="icon-plus">
                      Add condition
                    </Button>
                  </div>
                }
                {...props}
                extraConditionTypes={[EVENT_CONDITION_TYPE_TOUR_SEEN]}
                omitTypes={['logicGroup']}
              />
            )}
            newlyAddedCondition={newlyAddedCondition}
          />
        </div>
        <Divider />
        <div className={classNames('trigger-actions-wrapper')}>
          <CustomIndicatorLabel className="then" text="Then" />
          <div className="trigger-actions-content">
            <div className="trigger-icon-wrapper">
              <i className="isax isax-play-circle5 b-400" />
            </div>

            <TriggerActions />
          </div>
        </div>
      </div>
    </TriggerContext.Provider>
  );
};

export default ChecklistCompletedConditions;
