import Button from 'components/Button';
import {PermissionsPoke} from 'constants/permissions';
import {hasPermissions} from 'helpers/permission';
import {useRouteMatch} from 'react-router-dom';
import {
  ROUTE_BANNERS,
  ROUTE_CHECKLISTS,
  ROUTE_HINTS,
  ROUTE_SURVEYS,
  ROUTE_TOURS,
} from 'router/routes.const';
import './_Styles.scss';

const Header = ({setShowNewPushModal}) => {
  const match = useRouteMatch();
  const canCreatePoke = hasPermissions(PermissionsPoke.CREATE_EDIT);

  let title = '';

  if (match.path === ROUTE_TOURS) {
    title = 'Tours & Modals';
  } else if (match.path === ROUTE_SURVEYS) {
    title = 'Surveys';
  } else if (match.path === ROUTE_BANNERS) {
    title = 'Banners';
  } else if (match.path === ROUTE_HINTS) {
    title = 'Hints';
  } else if (match.path === ROUTE_CHECKLISTS) {
    title = 'Checklists';
  }

  return (
    <div className="pokes-header">
      <div className="content">
        <div className="title">{title}</div>
      </div>
      {canCreatePoke && (
        <Button
          className="new-push-btn"
          primary
          iconLeft="icon-plus"
          reverted
          onClick={() => setShowNewPushModal(true)}>
          New {match.path === ROUTE_TOURS ? 'Tour' : title.slice(0, -1)}
        </Button>
      )}
    </div>
  );
};

export default Header;
