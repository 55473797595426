import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {useContext} from 'react';
import {F_BLOCK_ZOOM_ON_MEDIA_CLICK} from 'scenes/PokeBuilder/components/BlockManager/utils';
import {BLOCK_TYPE_CHECKLIST_HEADER, STEP_TYPE_CHECKLIST} from 'services/steps';
import RadioGroup from '../../../../../../components/RadioGroup';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import PixelPicker from '../../components/items/PixelPicker';
import Height, {
  HEIGHT_TYPE_AUTO,
  HEIGHT_TYPE_CUSTOM,
} from '../../components/modals/Height';
import MediaLink from '../../components/modals/MediaLink';
import './_Styles.scss';

const mediaPositionOptions = [
  {label: <i className="icon-align-t" />, value: 'top'},
  {label: <i className="icon-align-b" />, value: 'bottom'},
];

const zoomOnMediaClickOptions = [
  {label: 'Disable', value: false},
  {label: 'Enable', value: true},
];

const Media = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    selectedChecklistItemId,
    selectedBlockType,
  } = useContext(BuilderContext);

  const isChecklistStep = step?.type === STEP_TYPE_CHECKLIST;

  const checklistHeaderBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_HEADER
  );

  const block = step?.blocks.find(
    (b) =>
      b.type === selectedBlockType &&
      ((selectedChecklistItemId == null &&
        [null, checklistHeaderBlock?.uid].includes(b.parentBlockId)) ||
        selectedChecklistItemId === b.parentBlockId)
  );

  const updateBlock = (updateObj, all = false) => {
    if (all !== true && block?.parentBlockId != null) {
      uptBlock(selectedBlockType, updateObj, {
        parentBlockId: block.parentBlockId,
      });
    } else {
      uptBlock(selectedBlockType, updateObj);
    }
  };

  if (block == null) {
    return <></>;
  }

  const {value, file, style, flags} = block;
  const [type, fileUrl, altText, sizing] = value.split(';');

  const hasZoomOnMediaClickFlag = hasFlag(F_BLOCK_ZOOM_ON_MEDIA_CLICK, flags);

  return (
    <div className="block-settings media">
      <div className="section">
        <div className="section-title">Content</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Media</div>
            <div className="section-item-content">
              <PopupSetting
                trigger={
                  <ClickableInput
                    key={fileUrl}
                    value={
                      type === 'image' && file != null
                        ? file?.originalName
                        : fileUrl
                    }
                    erasable={
                      !!(type === 'video' && fileUrl) ||
                      !!(type === 'image' && fileUrl)
                    }
                    onErase={() => {
                      updateBlock({
                        value: `${type};;${altText};${sizing}`,
                        file: null,
                        fileId: null,
                      });
                    }}
                  />
                }
                title="Media"
                content={
                  <MediaLink
                    type={type}
                    fileUrl={fileUrl}
                    altText={altText}
                    sizing={sizing}
                    file={file}
                    onChange={({value, file}) => {
                      updateBlock({value, file, fileId: file?.uid});
                    }}
                  />
                }
              />
            </div>
          </div>

          {type === 'image' && (
            <div className="section-item">
              <div className="section-item-title body-3">Zoom on click</div>
              <div className="section-item-content">
                <RadioGroup
                  value={hasZoomOnMediaClickFlag}
                  options={zoomOnMediaClickOptions}
                  onSelect={(value) =>
                    updateBlock({
                      flags:
                        value === true
                          ? addFlag(F_BLOCK_ZOOM_ON_MEDIA_CLICK, flags)
                          : removeFlag(F_BLOCK_ZOOM_ON_MEDIA_CLICK, flags),
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">
          {isChecklistStep ? (
            <>
              <i className="isax isax-main-component5" />
              Style
              <div className="section-title-description">
                <div className="body-4 n-700">For every</div>
                <div className="block-group subtitle-4 n-800">
                  <div className="icon-wrapper">
                    <i className="icon-image-o" />
                  </div>
                  Media
                </div>
              </div>
            </>
          ) : (
            'Style'
          )}
        </div>
        <div className="section-content">
          <MediaStyle
            style={style}
            updateStyle={(value) =>
              updateBlock(
                value,
                isChecklistStep === true && selectedChecklistItemId != null
              )
            }
            hidePosition={isChecklistStep}
            hidePadding={isChecklistStep}
          />
        </div>
      </div>
    </div>
  );
};

export const MediaStyle = ({style, updateStyle, hidePosition, hidePadding}) => {
  return (
    <>
      {hidePosition !== true && (
        <div className="section-item">
          <div className="section-item-title body-3">Position</div>
          <div className="section-item-content">
            <RadioGroup
              value={style.position}
              options={mediaPositionOptions}
              onSelect={(value) =>
                updateStyle({style: {...style, position: value}})
              }
            />
          </div>
        </div>
      )}
      <div className="section-item">
        <div className="section-item-title body-3">Height</div>
        <div className="section-item-content">
          <PopupSetting
            trigger={
              <ClickableInput
                inputProps={{small: true}}
                value={style.height != null ? `${style.height}px` : 'Auto'}
              />
            }
            title="Height"
            content={
              <Height
                height={style.height}
                type={
                  style.height != null ? HEIGHT_TYPE_CUSTOM : HEIGHT_TYPE_AUTO
                }
                max={500}
                onChangeType={(type) => {
                  if (type === HEIGHT_TYPE_AUTO) {
                    updateStyle({
                      style: {...style, height: undefined},
                    });
                  } else {
                    updateStyle({
                      style: {...style, height: 200},
                    });
                  }
                }}
                onChange={({height}) =>
                  updateStyle({
                    style: {...style, height},
                  })
                }
              />
            }
          />
        </div>
      </div>
      {hidePadding !== true && (
        <div className="section-item">
          <div className="section-item-title body-3">Padding</div>
          <div className="section-item-content">
            <PixelPicker
              small
              value={style.padding}
              min={0}
              max={24}
              onChange={(value) =>
                updateStyle({
                  style: {...style, padding: value},
                })
              }
            />
          </div>
        </div>
      )}
      <div className="section-item">
        <div className="section-item-title body-3">Radius</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.borderRadius}
            min={0}
            max={24}
            onChange={(value) =>
              updateStyle({
                style: {...style, borderRadius: value},
              })
            }
            radius
          />
        </div>
      </div>
    </>
  );
};

export default Media;
