import Divider from 'components/Divider';
import SelectGroup from 'components/Select';
import TextArea from 'components/TextArea';
import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import React, {useContext} from 'react';
import {F_BOOST_SLOT_TOP_BAR} from 'services/evolution';
import {BLOCK_TYPE_LABEL} from 'services/steps';
import RadioGroup from '../../../../../../components/RadioGroup';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import {fontWeightOptions} from '../Title';
import {fontFamiliesOptions} from '../Title/utils';
import './_Styles.scss';

const alignOptions = [
  {label: <i className="icon-align-b-l" />, value: 'left'},
  {label: <i className="icon-align-b-c" />, value: 'center'},
  {label: <i className="icon-align-b-r" />, value: 'right'},
];

const Tag = () => {
  const {
    controlledEvolution: evolution,
    selectedStep: step,
    updateBlock: uptBlock,
  } = useContext(BuilderContext);

  const isBannerStep = hasFlag(F_BOOST_SLOT_TOP_BAR, evolution.boostFlags);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_LABEL, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_LABEL);

  if (block == null) {
    return <></>;
  }

  const {value, style} = block;

  return (
    <div className="block-settings tag">
      <div className="section">
        <div className="section-title">Content</div>
        <div className="section-content">
          <TextArea
            value={value}
            onChange={({target}) => {
              updateBlock({value: target.value});
            }}
            className="tag-content-input"
          />
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">Layout</div>
        <div className="section-content">
          <TagStyle
            style={style}
            updateStyle={updateBlock}
            hideAlign={isBannerStep}
          />
        </div>
      </div>
    </div>
  );
};

export const TagStyle = ({style, updateStyle, hideAlign = false}) => {
  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Font family</div>
        <div className="section-item-content">
          <SelectGroup
            small
            options={fontFamiliesOptions}
            value={fontFamiliesOptions.find(
              (o) => o.value === style.fontFamily
            )}
            onChange={(option) =>
              updateStyle({
                style: {...style, fontFamily: option.value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font-size</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.fontSize}
            min={8}
            max={42}
            onChange={(value) =>
              updateStyle({
                style: {...style, fontSize: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font weight</div>
        <div className="section-item-content">
          <SelectGroup
            isSearchable={false}
            small
            options={fontWeightOptions}
            value={fontWeightOptions.find(
              (o) => o.value === (style.fontWeight || '400')
            )}
            onChange={(option) =>
              updateStyle({
                style: {...style, fontWeight: option.value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Text color</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Text color"
            value={style.fontColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, fontColor: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Background</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Background"
            value={style.primaryColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, primaryColor: value},
              })
            }
            erasable
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Border</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Border"
            value={style.borderColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, borderColor: value},
              })
            }
            erasable
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Padding</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.padding}
            min={0}
            max={24}
            onChange={(value) =>
              updateStyle({
                style: {...style, padding: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Radius</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.borderRadius}
            min={0}
            max={24}
            onChange={(value) =>
              updateStyle({
                style: {...style, borderRadius: value},
              })
            }
            radius
          />
        </div>
      </div>
      {hideAlign !== true && (
        <div className="section-item">
          <div className="section-item-title body-3">Position</div>
          <div className="section-item-content">
            <RadioGroup
              value={style.align}
              options={alignOptions}
              onSelect={(value) =>
                updateStyle({
                  style: {
                    ...style,
                    align: value,
                  },
                })
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Tag;
