import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Dropdown from 'components/Dropdown';
import InputGroup from 'components/Input';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import {getEmojiDataFromNative} from 'emoji-mart';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import React, {useContext, useEffect, useState} from 'react';
import {SLIDER_ABOVE_EMOJI_ANIMATION} from 'scenes/PokeBuilder/components/BlockManager/utils';
import {BLOCK_TYPE_SLIDER} from 'services/steps';
import RadioGroup from '../../../../../../components/RadioGroup';
import ClickableInput from '../../components/items/ClickableInput';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import {fontFamiliesOptions} from '../Title/utils';
import './_Styles.scss';

const animatedOptions = [
  {label: 'Yes', value: 'yes'},
  {label: 'No', value: 'no'},
];

const Slider = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_SLIDER, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_SLIDER);

  if (block == null) {
    return <></>;
  }

  const {value, flags, style} = block;
  const [type, customEmoji, labelLeft, labelRight] = value.split(';');

  return (
    <div className="block-settings slider">
      <div className="section">
        <div className="section-title">Styles</div>
        <div className="section-content">
          <SliderStyle style={style} updateStyle={updateBlock} />
          <div className="section-item">
            <div className="section-item-title">Emoji</div>
            <div className="section-item-content">
              <EmojiPicker
                value={customEmoji}
                onSelect={(value) =>
                  updateBlock({
                    value: `${type};${value};${labelLeft};${labelRight}`,
                  })
                }
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Animation</div>
            <div className="section-item-content">
              <RadioGroup
                value={
                  hasFlag(SLIDER_ABOVE_EMOJI_ANIMATION, flags) ? 'yes' : 'no'
                }
                options={animatedOptions}
                onSelect={(value) =>
                  updateBlock({
                    flags:
                      value === 'yes'
                        ? addFlag(SLIDER_ABOVE_EMOJI_ANIMATION, flags)
                        : removeFlag(SLIDER_ABOVE_EMOJI_ANIMATION, flags),
                  })
                }
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Left label</div>
            <div className="section-item-content">
              <InputGroup
                className="slider-left-label-input"
                value={labelLeft}
                onChange={({target}) =>
                  updateBlock({
                    value: `${type};${customEmoji};${target.value};${labelRight}`,
                  })
                }
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Right label</div>
            <div className="section-item-content">
              <InputGroup
                className="slider-right-label-input"
                value={labelRight}
                onChange={({target}) =>
                  updateBlock({
                    value: `${type};${customEmoji};${labelLeft};${target.value}`,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const EmojiPicker = ({value, onSelect}) => {
  const [emojiName, setEmojiName] = useState(null);

  useEffect(() => {
    const getEmojiData = async () => {
      const emojiData = await getEmojiDataFromNative(value);
      setEmojiName(emojiData?.name);
    };

    getEmojiData();
  }, [value]);

  return (
    <Dropdown
      className="emoji-picker-dropdown"
      position="left top"
      offsetX={120}
      trigger={
        <ClickableInput
          value={emojiName || 'Select emoji'}
          leftLabel={
            <div className="emoji-icon-wrapper">
              <span className="emoji-icon" style={{background: '#FFFFFF'}}>
                {value}
              </span>
            </div>
          }
        />
      }>
      <Picker data={data} onEmojiSelect={(emoji) => onSelect(emoji.native)} />
    </Dropdown>
  );
};

export const SliderStyle = ({style, updateStyle}) => {
  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Size</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.height}
            min={2}
            max={12}
            onChange={(value) =>
              updateStyle({
                style: {...style, height: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Color</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Slider color"
            value={style.primaryColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, primaryColor: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Background</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Background"
            value={style.secondaryColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, secondaryColor: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Labels font</div>
        <div className="section-item-content">
          <SelectGroup
            small
            options={fontFamiliesOptions}
            value={
              fontFamiliesOptions.find((o) => o.value === style.fontFamily) ??
              fontFamiliesOptions.find((font) => font.value === 'Inter')
            }
            onChange={(option) =>
              updateStyle({
                style: {...style, fontFamily: option.value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Labels color</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Color"
            value={style.labelsColor ?? null}
            onChange={(value) =>
              updateStyle({
                style: {...style, labelsColor: value},
              })
            }
            erasable
          />
        </div>
      </div>
    </>
  );
};

export default Slider;
