import classNames from 'classnames';
import Button from 'components/Button';
import {VOTE_CONGRATS} from 'constants/index';
import dayjs from 'dayjs';
import {buildLinkToEvolution, hasUsernameGenerated} from 'helpers/utils';
import {array, object} from 'prop-types';
import {useState} from 'react';
import {CircularProgressbar} from 'react-circular-progressbar';
import {useHistory} from 'react-router-dom';
import SurveyDetails from 'scenes/Users/components/ModalJimerProfile/components/SurveyDetails';
import './_Styles.scss';

const CONTEXT_PORTAL_STANDALONE = 'PORTAL_STANDALONE';
const CONTEXT_PORTAL_WIDGET = 'PORTAL_WIDGET';

const EVENT_TYPE_EVOLUTION_SEEN = 'EVOLUTION_SEEN';

const propTypes = {
  jimer: object.isRequired,
  items: array,
};

const defaultProps = {
  items: [],
};

const TimelineItem = ({
  jimer,
  item,
  isSubEvent = false,
  setSurveyView = () => {},
}) => {
  const history = useHistory();

  const [showSubEvents, setShowSubEvents] = useState(false);

  const username =
    hasUsernameGenerated(jimer.username) && jimer.externalUsername != null
      ? jimer.externalUsername
      : jimer.username;

  let icon,
    content,
    actions,
    extra,
    onWrapperClick = null;

  if (
    [CONTEXT_PORTAL_STANDALONE, CONTEXT_PORTAL_WIDGET].includes(item.context)
  ) {
    if (item.subEvents.length > 1) {
      icon = (
        <div className={classNames('icon-wrapper adoption')}>
          <i className="icon-portal" />
          <div className="count-wrapper">
            <div className="count">{item.subEvents.length}</div>
          </div>
        </div>
      );

      content = (
        <div className="content">
          <span className="context">
            <span className="username">{username}</span> interacted with{' '}
            <span className="portal-count">
              {item.subEvents.length} item{item.subEvents.length > 1 ? 's' : ''}{' '}
              in the changelog
            </span>
          </span>
        </div>
      );
      actions = (
        <Button
          cta
          className={classNames('show-portal-details', {open: showSubEvents})}
          iconOnly
          iconLeft="icon-chevron-right"
        />
      );
      onWrapperClick = () => setShowSubEvents(!showSubEvents);
    } else {
      icon = (
        <div className={classNames('icon-wrapper adoption')}>
          <i className="icon-portal" />
        </div>
      );

      item = item.subEvents[0];
    }
  } else if (item.type === EVENT_TYPE_EVOLUTION_SEEN) {
    if (item.evolution?.context === 'DISCOVERY') {
      icon = (
        <div className={classNames('icon-wrapper discovery')}>
          <i className="icon-user-satisfaction" />
        </div>
      );
    } else {
      icon = (
        <div className={classNames('icon-wrapper adoption')}>
          <i className="icon-filter-announcement" />
        </div>
      );
    }

    content = (
      <div className="content">
        <span className="context">
          <span className="username">{username}</span> viewed{' '}
          <span
            className="evolution-title"
            onClick={() => {
              const url = buildLinkToEvolution(item.evolution);
              history.push(url);
            }}>
            {item.evolution?.title}
          </span>
        </span>
      </div>
    );

    if (item.evolution.surveys?.length > 0) {
      actions = (
        <Button
          cta
          thin
          className="show-surveys"
          iconOnly
          onClick={() => setSurveyView(item.evolution.surveys[0].uid)}>
          See results
        </Button>
      );
    }
  } else if (item.type === 'TOUR') {
    const tour = item.subEvents.find(
      (subEvent) => subEvent.type === 'TOUR_STARTED'
    );
    const seenTourStepsCount = item.subEvents.filter(
      (s) => s.type === 'TOUR_STEP_SEEN'
    ).length;

    if (tour != null) {
      if (tour.evolution.tourSteps?.length > 1) {
        icon = (
          <div className={classNames('icon-wrapper adoption')}>
            <i className="icon-tour" />
          </div>
        );

        content = (
          <div className="content">
            <span className="context">
              <span className="username">{username}</span> completed{' '}
              {seenTourStepsCount} out of {tour.evolution.tourSteps.length}{' '}
              steps of the tour{' '}
              <span
                className="evolution-title"
                onClick={() => {
                  const url = buildLinkToEvolution(tour.evolution);
                  history.push(url);
                }}>
                {tour.evolution?.title}
              </span>
            </span>
          </div>
        );
        actions = (
          <div className="tour-progress-wrapper">
            <div className="tour-progress">
              {seenTourStepsCount}/{tour.evolution.tourSteps.length}
            </div>
            <div className="circular-progress-wrapper">
              <CircularProgressbar
                value={seenTourStepsCount}
                maxValue={tour.evolution.tourSteps.length}
                strokeWidth={12}
                styles={{
                  path: {
                    // Path color
                    stroke: `rgba(3, 165, 39)`,
                    // Customize transition animation
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                  },
                  // Customize the circle behind the path, i.e. the "total progress"
                  trail: {
                    // Trail color
                    stroke: 'rgba(33, 93, 137, 0.06)',
                  },
                }}
              />
            </div>
          </div>
        );
      } else {
        icon = (
          <div className={classNames('icon-wrapper adoption')}>
            <i className="icon-filter-announcement" />
          </div>
        );
        content = (
          <div className="content">
            <span className="context">
              <span className="username">{username}</span> viewed{' '}
              <span
                className="evolution-title"
                onClick={() => {
                  const url = buildLinkToEvolution(item.evolution);
                  history.push(url);
                }}>
                {item.evolution?.title}
              </span>
            </span>
          </div>
        );
      }
    }
  } else if (item.activityType === 'comment') {
    icon = icon || (
      <div className={classNames('icon-wrapper adoption')}>
        <i className="icon-comment-o" />
      </div>
    );

    content = (
      <div className="content comment">
        <div className="comment-context-wrapper">
          <span className="context">
            <span className="username">{username}</span> commented on{' '}
            <span
              className="evolution-title"
              onClick={() => {
                const url = buildLinkToEvolution(item.evolution);
                history.push(url);
              }}>
              {item.evolution?.title}
            </span>
          </span>
        </div>
      </div>
    );
    extra = (
      <div
        className="comment"
        onClick={() => {
          const url = buildLinkToEvolution(item.evolution, {
            toComments: true,
          });
          history.push(url);
        }}>
        “{item.message}“
      </div>
    );
  } else if (item.activityType === 'vote') {
    if (item.value === VOTE_CONGRATS) {
      icon = icon || (
        <div className={classNames('icon-wrapper adoption')}>
          <i className="icon-thumbs-up" />
        </div>
      );

      content = (
        <div className="content">
          <span className="context">
            <span className="username">{username}</span> liked{' '}
            <span
              className="evolution-title"
              onClick={() => {
                const url = buildLinkToEvolution(item.evolution);
                history.push(url);
              }}>
              {item.evolution?.title}
            </span>
          </span>
        </div>
      );
    }
  } else if (item.type === 'CREATE_JIMER') {
    icon = icon || (
      <div className={classNames('icon-wrapper adoption')}>
        <i className="icon-emoji" />
      </div>
    );

    content = (
      <div className="content">
        <span className="context">
          <span className="username">{username}</span> was created !
        </span>
      </div>
    );
  }

  if (content == null) {
    return <></>;
  }
  return (
    <div className="timeline-item-wrapper">
      <div
        className={classNames('timeline-item', {
          'sub-event fade-in-top': isSubEvent === true,
          interactive: onWrapperClick != null,
        })}
        onClick={() => {
          if (onWrapperClick != null) {
            onWrapperClick();
          }
        }}>
        <div className="item-infos">
          {icon}
          <div className="item-content-wrapper">
            {content}
            {isSubEvent !== true && (
              <div className="date">{dayjs(item.createdAt).fromNow()}</div>
            )}
          </div>
          {isSubEvent !== true && (
            <div className="actions-wrapper">{actions}</div>
          )}
        </div>
        {extra != null && <div className="extra">{extra}</div>}
      </div>
      {item.subEvents?.length > 0 && (
        <div
          className={classNames('sub-events-wrapper', {
            stacked: showSubEvents !== true,
          })}>
          {showSubEvents === true && <div className="sub-timeline-trait" />}
          <div
            className={classNames('sub-events', {
              stacked: showSubEvents !== true,
            })}>
            {item.subEvents?.map((subEvent) => (
              <TimelineItem
                key={`${subEvent.uid}-${showSubEvents ? 'show' : 'hide'}}`}
                jimer={jimer}
                item={subEvent}
                isSubEvent
                setSurveyView={setSurveyView}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const Timeline = ({jimer, items, setSurveyView}) => {
  const [surveyDetailsId, setSurveyDetailsId] = useState(null);

  return (
    <div
      className={classNames('timeline-wrapper', {
        'no-padding': surveyDetailsId != null,
      })}>
      {surveyDetailsId != null ? (
        <SurveyDetails
          surveyId={surveyDetailsId}
          onBack={() => setSurveyDetailsId(null)}
        />
      ) : (
        <div className="timeline">
          <div className="timeline-trait" />
          {items.map((item) => (
            <TimelineItem
              key={item.uid}
              jimer={jimer}
              item={item}
              setSurveyView={setSurveyDetailsId}
            />
          ))}
        </div>
      )}
    </div>
  );
};

Timeline.propTypes = propTypes;
Timeline.defaultProps = defaultProps;

export default Timeline;
