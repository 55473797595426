import {default as classNames} from 'classnames';
import {BuilderContext} from 'contextes/builder';
import {getStepIssue} from 'helpers/step';
import {bool, object} from 'prop-types';
import React, {useContext, useEffect} from 'react';
import {STEP_TYPE_CHECKLIST, STEP_TYPE_SUCCESS} from 'services/steps';
import './_Styles.scss';

const propTypes = {
  inDalaran: bool,
  stepEditorRef: object,
  draggedOffset: object,
};

const defaultProps = {
  inDalaran: false,
  stepEditorRef: null,
  draggedOffset: null,
};

const BLOCKS = [
  {type: STEP_TYPE_CHECKLIST, icon: <i className="isax isax-task-square5" />},
  {type: STEP_TYPE_SUCCESS, icon: <i className="isax isax-heart5" />},
];

const ChecklistStepsManager = () => {
  const {evolution, selectedStepId, setSelectedStepId} =
    useContext(BuilderContext);

  useEffect(() => {
    if (selectedStepId == null) {
      setSelectedStepId(evolution.steps[0]?.uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {steps = []} = evolution;

  return (
    <div className="checklist-steps-manager">
      <div className={classNames('step-block-list')}>
        {steps.length > 0 && (
          <div className="droppable-block-list">
            {steps
              .sort((a, b) => a.indexOrder - b.indexOrder)
              .map((step, index) => (
                <StepButton
                  step={step}
                  index={index}
                  isLast={index === steps.length - 1}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

const StepButton = ({step, isLast}) => {
  const {
    selectedStepId,
    setSelectedStepId,
    setInConcept,
    setSelectedBlockType,
  } = useContext(BuilderContext);

  const issue = getStepIssue(step, {isChecklist: true});
  const blockType = BLOCKS.find((b) => b.type === step.type);

  return (
    <div
      className={classNames('block-wrapper', {
        'is-last': isLast,
      })}>
      <div
        className={classNames('block-selector', {
          selected: step.uid === selectedStepId,
          invalid: selectedStepId !== step.uid && issue != null,
        })}
        onClick={() => {
          setInConcept(null);
          setSelectedStepId(step.uid);
          setSelectedBlockType(null);
        }}>
        <div className="block-type-icon">{blockType?.icon}</div>
      </div>
    </div>
  );
};

ChecklistStepsManager.propTypes = propTypes;
ChecklistStepsManager.defaultProps = defaultProps;

export default ChecklistStepsManager;
