import InputGroup from 'components/Input';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import {BLOCK_TYPE_TITLE} from 'services/steps';
import './_Styles.scss';

const PostTitle = () => {
  const {evolution, setEvolution, selectedLanguage} =
    useContext(BuilderContext);

  const step = evolution.steps[0] || {};
  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_TITLE);
  const {value} = block || {};

  const translation = block?.translations?.find(
    (t) => t.language === selectedLanguage
  );

  const actualValue = selectedLanguage != null ? translation?.value : value;

  return (
    <InputGroup
      key={selectedLanguage}
      className="post-title"
      placeholder="Post Title"
      value={actualValue}
      onChange={({target}) => {
        if (selectedLanguage != null) {
          const hasLanguage =
            block.translations?.find((t) => t.language === selectedLanguage) !=
            null;
          setEvolution({
            ...evolution,
            steps: [
              {
                ...evolution.steps[0],
                blocks: evolution.steps[0]?.blocks.map((b) => {
                  if (b.type === BLOCK_TYPE_TITLE) {
                    return {
                      ...b,
                      translations: hasLanguage
                        ? b.translations.map((t) => {
                            if (t.language === selectedLanguage) {
                              return {
                                ...t,
                                value: target.value,
                              };
                            } else {
                              return t;
                            }
                          })
                        : [
                            ...(b?.translations || []),
                            {language: selectedLanguage, value: target.value},
                          ],
                    };
                  }
                  return b;
                }),
              },
            ],
          });
        } else {
          setEvolution({
            ...evolution,
            title: target.value,
            steps: [
              {
                ...evolution.steps[0],
                blocks: evolution.steps[0]?.blocks.map((b) => {
                  if (b.type === BLOCK_TYPE_TITLE) {
                    return {...b, value: target.value};
                  }
                  return b;
                }),
              },
            ],
          });
        }
      }}
    />
  );
};

export default PostTitle;
