import Tabs from 'components/Tabs';
import {
  PermissionsEvent,
  PermissionsSuccessTracker,
} from 'constants/permissions';
import {hasFlag} from 'helpers/bitwise';
import {hasPermissions} from 'helpers/permission';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  useHistory,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min';
import {ROUTE_EVENTS, ROUTE_SUCCESS_TRACKER} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {
  F_PRICING_V0,
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import './_Styles.scss';
import Events from './components/Events';
import SuccessTrackerHeader from './components/SuccessTrackerHeader';
import Trackers from './components/Trackers';

const TAB_TRACKERS = 'Trackers';
const TAB_EVENTS = 'Events';

const SuccessTracker = () => {
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const {update} = useUpdateSubscription();

  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [defaultTrackerType, setDefaultTrackerType] = useState(null);

  const hasSubscriptionAccessToSuccessTracker =
    [PLAN_GROWTH_ID, PLAN_SCALE_ID].includes(subscription.plan) ||
    (subscription.plan === PLAN_STARTUP_ID &&
      hasFlag(F_PRICING_V0, subscription.extraFlags));
  let tab;

  if (routeMatch.path === ROUTE_SUCCESS_TRACKER) {
    tab = TAB_TRACKERS;
  }
  if (routeMatch.path === ROUTE_EVENTS) {
    tab = TAB_EVENTS;
  }

  const handleTabChange = (tab) => {
    if (tab === TAB_TRACKERS) {
      history.push(ROUTE_SUCCESS_TRACKER);
    }
    if (tab === TAB_EVENTS) {
      history.push(ROUTE_EVENTS);
    }
  };

  useEffect(() => {
    if (hasSubscriptionAccessToSuccessTracker === false) {
      update({
        planId: PLAN_GROWTH_ID,
        title: 'Success tracker',
        description: 'Track and analyze your product adoption',
        onPreviewClose: () => history.goBack(),
      });
      return;
    }
  }, [hasSubscriptionAccessToSuccessTracker]);

  return hasSubscriptionAccessToSuccessTracker === false ? (
    <></>
  ) : (
    <div className="s-success-tracker fade-in">
      <SuccessTrackerHeader
        defaultTrackerType={defaultTrackerType}
        showCreateModal={showCreateModal}
        setShowCreateModal={setShowCreateModal}
      />

      <div className="tabs-row">
        <Tabs defaultTab={tab} onTabChange={handleTabChange}>
          {[]
            .concat(
              hasPermissions(PermissionsSuccessTracker.ACCESS)
                ? [<div label={TAB_TRACKERS} />]
                : []
            )
            .concat(
              hasPermissions(PermissionsEvent.ACCESS)
                ? [<div label={TAB_EVENTS} />]
                : []
            )}
        </Tabs>
      </div>
      <div className="success-tracker-content">
        {tab === TAB_TRACKERS && (
          <Trackers
            setShowCreateModal={setShowCreateModal}
            setDefaultTrackerType={setDefaultTrackerType}
          />
        )}
        {tab === TAB_EVENTS && <Events />}
      </div>
    </div>
  );
};

export default SuccessTracker;
