import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import React, {useContext} from 'react';
import {CHOICE_CAN_MULTIPLE} from 'scenes/PokeBuilder/components/BlockManager/utils';
import {BLOCK_TYPE_CHOICE} from 'services/steps';
import {
  CHOICE_DISPOSITION_BLOCK,
  CHOICE_DISPOSITION_INLINE,
} from 'shared/front/components/Poke/components/BlockChoice';
import {v4 as uuidv4} from 'uuid';
import RadioGroup from '../../../../../../components/RadioGroup';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import Shadow from '../../components/modals/Shadow';
import {fontWeightOptions} from '../Title';
import {fontFamiliesOptions} from '../Title/utils';
import './_Styles.scss';

const dispositionOptions = [
  {label: 'Inline', value: CHOICE_DISPOSITION_INLINE},
  {label: 'Block', value: CHOICE_DISPOSITION_BLOCK},
];

const typeOptions = [
  {label: 'Single', value: false},
  {label: 'Multiple', value: true},
];

const checkboxOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

export const getDefaultChoiceOption = () => {
  return {
    uid: uuidv4(),
    content: '',
    labels: [],
    indexOrder: 0,
  };
};

const MultipleChoice = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_CHOICE, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_CHOICE);

  if (step == null || block == null) {
    return <></>;
  }

  const {flags, options, style} = block;

  const handleAddOption = (e) => {
    e.preventDefault();
    updateBlock({
      options: [
        ...options,
        {
          ...getDefaultChoiceOption(),
          indexOrder: options.length,
        },
      ],
    });
  };

  const handleDeleteOption = (index) => {
    updateBlock({
      options: options.filter((e, i) => i !== index),
    });
  };

  const handleOptionContentChange = (optionId, newContent) => {
    updateBlock({
      options: options.map((o) =>
        o.uid === optionId ? {...o, content: newContent} : o
      ),
    });
  };

  return (
    <div className="block-settings multiple-choice">
      <div className="section">
        <div className="section-title">Options</div>
        <div className="section-content">
          <div className="options">
            {options
              .sort((a, b) => a.indexOrder - b.indexOrder)
              .map((option, index) => {
                const content = option.content;
                return (
                  <div className="choice-wrapper" key={option.uid}>
                    <InputGroup
                      required
                      className={classNames('choice-label', {
                        invalid: !content,
                      })}
                      placeholder={`Choice ${index + 1}`}
                      name="name"
                      value={content || ''}
                      onChange={(e) =>
                        handleOptionContentChange(option.uid, e.target.value)
                      }
                      labelTextRight={
                        index > 1 ? (
                          <i
                            className="icon-trash"
                            onClick={() => handleDeleteOption(index)}></i>
                        ) : null
                      }
                    />
                    {index + 1 === options.length ? (
                      <Button
                        className={classNames('add-option-btn')}
                        iconLeft="icon-plus-rounded"
                        iconOnly
                        onClick={handleAddOption}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">Layout</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Choice type</div>
            <div className="section-item-content">
              <RadioGroup
                value={hasFlag(CHOICE_CAN_MULTIPLE, flags)}
                options={typeOptions}
                onSelect={(value) => {
                  if (value) {
                    updateBlock({flags: addFlag(CHOICE_CAN_MULTIPLE, flags)});
                  } else {
                    updateBlock({
                      flags: removeFlag(CHOICE_CAN_MULTIPLE, flags),
                    });
                  }
                }}
              />
            </div>
          </div>
          <MultipleChoiceStyle style={style} updateStyle={updateBlock} />
        </div>
      </div>
    </div>
  );
};

export const MultipleChoiceStyle = ({style, updateStyle}) => {
  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Checkbox</div>
        <div className="section-item-content">
          <RadioGroup
            value={style.withCheckbox || false}
            options={checkboxOptions}
            onSelect={(value) =>
              updateStyle({style: {...style, withCheckbox: value}})
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Disposition</div>
        <div className="section-item-content">
          <RadioGroup
            value={style.disposition}
            options={dispositionOptions}
            onSelect={(value) =>
              updateStyle({style: {...style, disposition: value}})
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font family</div>
        <div className="section-item-content">
          <SelectGroup
            small
            options={fontFamiliesOptions}
            value={fontFamiliesOptions.find(
              (o) => o.value === style.fontFamily
            )}
            onChange={(option) =>
              updateStyle({
                style: {...style, fontFamily: option.value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font-size</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.fontSize}
            min={8}
            max={42}
            onChange={(value) =>
              updateStyle({
                style: {...style, fontSize: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font weight</div>
        <div className="section-item-content">
          <SelectGroup
            isSearchable={false}
            small
            options={fontWeightOptions}
            value={fontWeightOptions.find(
              (o) => o.value === (style.fontWeight || '400')
            )}
            onChange={(option) =>
              updateStyle({
                style: {...style, fontWeight: option.value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Padding</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.padding}
            min={0}
            max={24}
            onChange={(value) =>
              updateStyle({
                style: {...style, padding: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Radius</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.borderRadius}
            min={0}
            max={24}
            onChange={(value) =>
              updateStyle({
                style: {...style, borderRadius: value},
              })
            }
            radius
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Color</div>
        <div className="section-item-content">
          <ColorPickerInput
            title="Color"
            value={style.primaryColor}
            inputProps={{small: true}}
            onChange={(value) =>
              updateStyle({
                style: {...style, primaryColor: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Shadow</div>
        <div className="section-item-content shadow-picker">
          <PopupSetting
            trigger={
              <ClickableInput
                inputProps={{small: true}}
                value={style.shadow?.color}
                leftLabel={
                  <span
                    className="preview-shadow-color"
                    style={{background: style.shadow?.color || '#FFFFFF'}}
                  />
                }
              />
            }
            title="Shadow"
            content={
              <Shadow
                value={style.shadow}
                onChange={(value) => {
                  updateStyle({style: {...style, shadow: value}});
                }}
              />
            }
          />
        </div>
      </div>
    </>
  );
};

export default MultipleChoice;
