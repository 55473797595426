import {toastDanger} from 'components/Toaster';
import {GlobalContext} from 'contextes/Global';
import {errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {useContext} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {subscriptionService} from 'services';
import {
  ADDON_WHITE_LABEL_ID,
  F_MODE_ANNUAL_BILLING,
} from 'services/subscription';
import {Swaler} from 'swaler';

const logger = new Swaler('useAddonCheckout');

export const useAddonCheckout = () => {
  const {setModalAddonCheckout, setModalAddonPurchased} =
    useContext(GlobalContext);

  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );
  const project = useSelector((state) => generalSelector.getProject(state));

  const modeAnnualBilling = hasFlag(
    F_MODE_ANNUAL_BILLING,
    subscription.extraFlags
  );
  const addons = [];

  // Add existing addons (for now, not relevant because we only have one addon)
  if (project.whiteLabeling === true) {
    addons.push(ADDON_WHITE_LABEL_ID);
  }

  async function checkout({addonId, mau = subscription.extraJimers}) {
    if (addons.includes(addonId)) {
      toastDanger([
        'Add-on already enabled',
        "We couldn't update your subscription because you have already enabled this addon.",
      ]);
      return;
    }

    try {
      await subscriptionService.createOrUpdateStripeSubscription({
        plan: subscription.plan,
        mau,
        addons: addons.concat(addonId),
        modeAnnual: modeAnnualBilling,
      });
      setModalAddonCheckout(null);
      setModalAddonPurchased({addonId, mau});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Checkout addon ${addonId} failed with error : `, code);
      return toastDanger([title, message], {actions});
    }
  }

  // Show the addon modal checkout
  async function start(addonId) {
    setModalAddonCheckout({
      addonId,
      onCheckout: checkout,
    });
  }

  return {
    start,
  };
};
