import DefaultLoader from 'components/Loader';
import dayjs from 'dayjs';
import {object} from 'prop-types';
import React, {useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {evolutionAnalyticsService} from 'services';
import {
  BLOCK_TYPE_CHECKLIST_TASK_ITEM,
  STEP_TYPE_CHECKLIST,
} from 'services/steps';
import {Swaler} from 'swaler';
import UsersReachedDrawer from '../UsersReachedDrawer';
import './_Styles.scss';

const ChecklistTaskItem = ({evolution, task, totalViewsCount}) => {
  const [showUsersModal, setShowUsersModal] = useState(false);

  const {value, clicksCount, skippedCount, completedCount} = task;
  const [name] = value.split('|-|');

  const completionPct =
    totalViewsCount > 0
      ? Math.round((completedCount * 100) / totalViewsCount)
      : 0;

  return (
    <div className="checklist-task-activity-item">
      <div className="checklist-task-item-content">
        <div className="checklist-task-info">
          <div className="task-name subtitle-4 n-800">{name}</div>
          {completionPct > 0 && (
            <div
              className="chart-wrapper"
              style={{
                width: `${completionPct}%`,
              }}
            />
          )}
          {/* {task.removed === true && (
            <HiddenStepTooltip
              step={hint}
              isHint
              trigger={
                <div className="label-hidden-wrapper">
                  <Label
                    size="small"
                    type="neutral"
                    primary
                    iconLeft="isax isax-eye-slash"
                    className="label-hidden">
                    Hidden
                  </Label>
                </div>
              }
            />
          )} */}
        </div>
        <div className="checklist-task-clicks body-2 n-700">
          {clicksCount || '-'}
        </div>
        <div className="checklist-task-skipped body-2 n-700">
          {skippedCount || '-'}
        </div>
        <div className="checklist-task-completed body-2 n-700">
          {completedCount || '-'}
        </div>
        <div className="checklist-task-completion-rate body-2 n-800">
          {completionPct > 0 ? `${completionPct}%` : '-'}
        </div>
      </div>

      {showUsersModal && (
        <UsersReachedDrawer
          isOpen={showUsersModal}
          onRequestClose={() => setShowUsersModal(false)}
          block={task}
          evolution={evolution}
          title={`Users who viewed "${evolution.title}"`}
        />
      )}
    </div>
  );
};

const propTypes = {
  evolution: object,
  startDate: object.isRequired,
  endDate: object.isRequired,
};

const defaultProps = {
  evolution: null,
};

const logger = new Swaler('HintActivity');

const ChecklistTaskActivity = ({evolution, startDate, endDate}) => {
  const checklistStep = useMemo(() => {
    return evolution?.steps?.find((step) => step.type === STEP_TYPE_CHECKLIST);
  }, [evolution]);

  const tasks = checklistStep.blocks.filter(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM
  );

  const {
    data: analyticsHourlyByBlock = [],
    isLoading: isLoadingAnalyticHourlyByBlock,
  } = useQuery({
    queryKey: [
      'analyticsHourlyPerBlock',
      ...(tasks?.map((c) => c.uid) || []),
      startDate,
      endDate,
    ],
    queryFn: () => {
      return evolutionAnalyticsService.getAnalyticsHourlyByBlock({
        startDate,
        endDate: dayjs(endDate).add(1, 'day').toDate(),
        blockIds: tasks?.map((c) => c.uid) || [],
      });
    },
    enabled: tasks?.length > 0,
    onError: (err) => {
      logger.error(
        'Could not fetch tasks analytics, failed with err',
        err.message
      );
    },
  });

  const tasksWithAnalytics = analyticsHourlyByBlock?.reduce((acc, cur) => {
    const index = acc.map((block) => block.uid).indexOf(cur.block_uid);
    if (index >= 0) {
      if (acc[index].clicksCount == null) {
        acc[index].clicksCount = parseInt(cur.sum_clicks_count, 10);
      } else {
        acc[index].clicksCount =
          acc[index].clicksCount + parseInt(cur.sum_clicks_count, 10);
      }
      if (acc[index].skippedCount == null) {
        acc[index].skippedCount = parseInt(cur.sum_skipped_count, 10);
      } else {
        acc[index].skippedCount =
          acc[index].skippedCount + parseInt(cur.sum_skipped_count, 10);
      }
      if (acc[index].completedCount == null) {
        acc[index].completedCount = parseInt(cur.sum_completed_count, 10);
      } else {
        acc[index].completedCount =
          acc[index].completedCount + parseInt(cur.sum_completed_count, 10);
      }
    }
    return acc;
  }, JSON.parse(JSON.stringify(tasks || [])));

  tasksWithAnalytics.sort((a, b) => {
    const valueA = a.value;
    const valueB = b.value;
    const [_, indexA] = valueA.split('|-|');
    const [__, indexB] = valueB.split('|-|');

    return indexA - indexB;
  });

  const totalViewsCount = evolution.analytics?.checklistStartedCount || 0;

  return (
    <div className="checklist-task-activity">
      {isLoadingAnalyticHourlyByBlock ? (
        <div className="loader-wrapper">
          <DefaultLoader />
        </div>
      ) : (
        <>
          <div className="checklist-task-activity-header">
            <div>Task</div>
            <div style={{justifySelf: 'right'}}>Clicked</div>
            <div style={{justifySelf: 'right'}}>Skipped</div>
            <div style={{justifySelf: 'right'}}>Completed</div>
            <div style={{justifySelf: 'right'}}>Completion rate</div>
          </div>
          <div className="checklist-task-activity-content">
            {tasksWithAnalytics?.map((task, index) => {
              return (
                <ChecklistTaskItem
                  index={index}
                  evolution={evolution}
                  task={task}
                  totalViewsCount={totalViewsCount}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

ChecklistTaskActivity.propTypes = propTypes;
ChecklistTaskActivity.defaultProps = defaultProps;

export default ChecklistTaskActivity;
