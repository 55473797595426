import classNames from 'classnames';
import React, {useContext} from 'react';
import ClickableBlockOverlay from '../../../Poke/components/ClickableBlockOverlay';
import {BLOCK_CHECKLIST_HEADER} from '../../../Poke/constants/blocks';
import {PokeStateContext} from '../../../Poke/context';
import ChecklistParagraph from '../ChecklistParagraph';
import ChecklistProgress from '../ChecklistProgress';
import ChecklistTitle from '../ChecklistTitle';
import './_Styles.scss';

const ChecklistHeader = () => {
  const {
    blocks,
    isMinimized,
    inBuilder,
    selectedBlock,
    onBlockSelected,
    onTaskSelected,
    currentStep,
    setIsMinimized,
  } = useContext(PokeStateContext);

  const {style: stepStyle} = currentStep ?? {};
  const {borderRadius} = stepStyle ?? {};

  const block = blocks.find((b) => b.type === BLOCK_CHECKLIST_HEADER);

  const {style} = block;

  return (
    <div
      className={classNames('checklist-header', {
        'is-minimized': isMinimized,
        'poke-block-clickable': inBuilder === true,
        selected: selectedBlock === BLOCK_CHECKLIST_HEADER,
      })}
      style={{
        ...style,
        borderRadius:
          isMinimized === true
            ? `${borderRadius}px`
            : `${borderRadius}px ${borderRadius}px 0 0`,
      }}
      onClick={(e) => {
        e.stopPropagation();

        if (inBuilder === true) {
          onBlockSelected(BLOCK_CHECKLIST_HEADER);
          onTaskSelected(null);
          return;
        }

        setIsMinimized(!isMinimized);
      }}>
      <ChecklistTitle />
      <ChecklistParagraph />
      <ChecklistProgress />

      <ClickableBlockOverlay />
    </div>
  );
};

export default ChecklistHeader;
