import {generalActions} from 'actions';
import {EVENT_DELETE_PROJECT} from 'amplitude';
import amplitude from 'amplitude-js';
import Button from 'components/Button';
import Input from 'components/Input';
import {ModalConfirm, ModalConfirmV2} from 'components/Modal';
import {toastDanger, toastInfo, toastSuccess} from 'components/Toaster';
import Toggle from 'components/Toggle';
import copy from 'copy-to-clipboard';
import {crispHelpers, errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {useAddonCancel} from 'hooks/useAddonCancel';
import {useAddonCheckout} from 'hooks/useAddonCheckout';
import {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import {
  ROUTE_ONBOARDING_PROJECT,
  ROUTE_SETTINGS_PLAN,
  ROUTE_SWITCH_PROJECT,
} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {generalSelector} from 'selectors';
import {projectService, subscriptionService} from 'services';
import {isPaying} from 'services/project';
import {
  ADDON_WHITE_LABEL_ID,
  F_PRICING_V0,
  hasSaasMantraPlan,
} from 'services/subscription';
import {Swaler} from 'swaler';
import Saver from '../../components/Saver';
import './_Styles.scss';

const logger = new Swaler('SettingsGeneral');

export default function SettingsGeneral() {
  const dispatch = useDispatch();
  const history = useHistory();

  const project = useSelector((state) => generalSelector.getProject(state));
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );
  const projects = useSelector((state) => generalSelector.getProjects(state));
  const user = useSelector((state) => generalSelector.getUser(state));

  const setProjects = (projects) =>
    dispatch(generalActions.setProjects(projects));
  const uptProject = (data) => dispatch(generalActions.uptProject(data));

  const {start} = useAddonCheckout();
  const {cancel} = useAddonCancel();

  const [inputProjectName, setProjectName] = useState(project.name);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showModalConfirmDeleteLogo, setShowModalConfirmDeleteLogo] =
    useState(false);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [deletingLogo, setDeletingLogo] = useState(false);

  const refInputFileLogo = useRef(null);

  const hasChanges = () => {
    return project.name !== inputProjectName;
  };
  const handleDeleteProject = async () => {
    setDeleting(true);
    try {
      await projectService.deleteProject(project.uid);
      amplitude.getInstance().logEvent(EVENT_DELETE_PROJECT, {
        projectId: project.uid,
        projectName: project.name,
      });
      setDeleting(false);
      if (projects.length > 1) {
        const switchToProject = projects.find((p) => p.uid !== project.uid);

        history.push(ROUTE_SWITCH_PROJECT(switchToProject.uid));
        setProjects(projects.filter((p) => p.uid !== project.uid));
        return toastInfo([
          'Project deleted!',
          `Your project ${project.name} has been deleted and you have been automatically switched to ${switchToProject.name}!`,
        ]);
      } else {
        return history.push(ROUTE_ONBOARDING_PROJECT);
      }
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting project failed with error ', code);
      setDeleting(false);
      return toastDanger([title, message], {actions});
    }
  };
  const handleUpdate = async () => {
    setUpdating(true);
    try {
      const projectUpdated = await projectService.updateProject(project.uid, {
        name: inputProjectName,
      });

      uptProject(projectUpdated);
      setUpdating(false);
      toastSuccess('Project updated!', {toastId: 'project-updated'});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating project failed with error ', code);
      setUpdating(false);
      return toastDanger([title, message], {actions});
    }
  };
  const handleUploadLogo = async (e) => {
    const file = e.target.files.length !== 0 ? e.target.files[0] : null;

    if (file == null) {
      return;
    }
    setUploadingLogo(true);
    try {
      const uploadedFile = await projectService.uploadPortalLogo({file});
      setUploadingLogo(false);
      uptProject({portalLogoUrl: uploadedFile.publicUrl});
      toastSuccess(['Logo uploaded', 'Your project logo has been updated!'], {
        toastId: 'logo-uploaded',
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setUploadingLogo(false);
      logger.error('Upload changelog logo failed with error ', code);
      return toastDanger([title, message], {actions});
    }
  };
  const handleDeletePortalLogo = async () => {
    setDeletingLogo(true);
    try {
      await projectService.deletePortalLogo();
      uptProject({portalLogoUrl: null});
      setDeletingLogo(false);
      setShowModalConfirmDeleteLogo(false);
      toastSuccess('Logo deleted!', {toastId: 'logo-deleted'});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      setDeletingLogo(false);
      setShowModalConfirmDeleteLogo(false);
      logger.error('Delete changelog logo failed with error ', code);
      return toastDanger([title, message], {actions});
    }
  };

  return (
    <SettingsBody className="s-settings-general">
      <div className="title-3">General</div>
      <div className="settings-card card-main">
        <div className="general-project-logo">
          <div className="left-wrapper">
            <div
              className="project-logo"
              style={
                project.portalLogoUrl != null
                  ? {
                      backgroundImage: `url(${project.portalLogoUrl})`,
                    }
                  : {
                      backgroundColor: '#1260EB',
                    }
              }>
              {project.portalLogoUrl == null && project.name[0].toUpperCase()}
            </div>
            <div className="label-wrapper">
              <span className="subtitle-3">Project logo</span>
              <small className="body-3 n-700">
                PNG or JPEG format under 500kb (56x56px)
              </small>
            </div>
          </div>
          <div className="right-wrapper">
            <input
              style={{display: 'none'}}
              type="file"
              ref={refInputFileLogo}
              onChange={handleUploadLogo}
            />
            <Button
              iconLeft="isax isax-document-upload"
              thin
              loading={uploadingLogo}
              disabled={deletingLogo}
              onClick={() => refInputFileLogo.current.click()}>
              Upload image
            </Button>
            {project.portalLogoUrl != null && (
              <Button
                danger
                thin
                loading={deletingLogo}
                disabled={uploadingLogo}
                onClick={() => setShowModalConfirmDeleteLogo(true)}>
                Remove image
              </Button>
            )}
          </div>
        </div>
        <div className="general-project-name">
          <Input
            legend="Project Name"
            danger={projectService.isValidName(inputProjectName) === false}
            helper={
              projectService.isValidName(inputProjectName) === false
                ? "Your project's name cannot exceed 32 characters."
                : null
            }
            placeholder="My amazing product"
            value={inputProjectName}
            onChange={({target}) => setProjectName(target.value)}></Input>
        </div>
        <div className="general-project-id">
          <Input
            legend="Project Id"
            value={project.uid}
            iconRight="isax isax-copy"
            iconRightProps={{
              onClick: () => {
                copy(project.uid);
                toastSuccess('Identifier copied!', {toastId: 'id-copied'});
              },
            }}
            disabled
          />
        </div>
      </div>
      <div className="settings-card card-white-label">
        <div className="left-wrapper">
          <span className="subtitle-3">Hide Jimo label</span>
          <span className="body-3 n-700">
            Remove Jimo label to make sure your brand won't be associate with
            Jimo.
          </span>
        </div>
        <div className="right-wrapper">
          {isPaying() === true ? (
            <>
              {hasFlag(F_PRICING_V0, subscription.extraFlags) === true ? (
                <Button
                  primary
                  onClick={() =>
                    crispHelpers.startCrispThread(
                      `Hi! I'd like to ${
                        subscriptionService.hasAddon(ADDON_WHITE_LABEL_ID)
                          ? 'purchase'
                          : 'cancel'
                      } the white label addon but i'm on a old pricing. Can you help me?`
                    )
                  }
                  thin>
                  Contact sales
                </Button>
              ) : (
                <Toggle
                  checked={subscriptionService.hasAddon(ADDON_WHITE_LABEL_ID)}
                  onChange={() => {
                    if (subscriptionService.hasAddon(ADDON_WHITE_LABEL_ID)) {
                      cancel(ADDON_WHITE_LABEL_ID);
                    } else {
                      start(ADDON_WHITE_LABEL_ID);
                    }
                  }}
                />
              )}
            </>
          ) : (
            <>
              <Button
                primary
                onClick={() => history.push(ROUTE_SETTINGS_PLAN)}
                disabled={hasSaasMantraPlan(project)}
                thin>
                {hasSaasMantraPlan(project) === true
                  ? 'Included in your plan'
                  : 'Upgrade'}
              </Button>
            </>
          )}
        </div>
      </div>
      {process.env.NODE_ENV === 'development' &&
        user?.uid === project.owner?.uid && (
          <div className="settings-card card-delete-project">
            <div className="left-wrapper">
              <span className="subtitle-3 r-400">Delete project</span>
              <span className="body-3 n-700">
                Permanently delete your account and all associated data in Jimo
              </span>
            </div>
            <div className="right-wrapper">
              <Button
                primary
                danger
                onClick={() => setShowConfirmModal(true)}
                thin>
                Delete {project.name}
              </Button>
            </div>
          </div>
        )}
      <ModalConfirmV2
        isConfirming={deletingLogo}
        cancelText="Cancel"
        confirmText="Remove image"
        title="Remove Project Image?"
        onConfirm={handleDeletePortalLogo}
        onCancel={() => setShowModalConfirmDeleteLogo(false)}
        isOpen={showModalConfirmDeleteLogo}
        confirmBtnProps={{danger: true}}>
        <div className="body-2 n-500">
          Proceeding will permanently remove the logo from your project. This
          action cannot be undone.
        </div>
      </ModalConfirmV2>
      <ModalConfirm
        isOpen={showConfirmModal}
        onConfirm={handleDeleteProject}
        onRequestClose={() => setShowConfirmModal(true)}
        onCancel={() => setShowConfirmModal(false)}
        isConfirming={deleting}>
        All data related to {project.name} will be lost definitely!
      </ModalConfirm>
      <Saver
        onSave={handleUpdate}
        isOpen={hasChanges()}
        isSaving={updating}></Saver>
    </SettingsBody>
  );
}
