import {
  createBoldPlugin,
  createItalicPlugin,
  createStrikethroughPlugin,
  createUnderlinePlugin,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
} from '@udecode/plate-basic-marks';
import {
  createPlugins,
  Plate,
  PlateContent,
  PlateElement,
  PlateLeaf,
} from '@udecode/plate-common';
import {createEmojiPlugin} from '@udecode/plate-emoji';
import {
  createHeadingPlugin,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
} from '@udecode/plate-heading';
import {createJuicePlugin} from '@udecode/plate-juice';
import {createLinkPlugin, ELEMENT_LINK} from '@udecode/plate-link';
import {
  createImagePlugin,
  createMediaEmbedPlugin,
  ELEMENT_IMAGE,
  ELEMENT_MEDIA_EMBED,
} from '@udecode/plate-media';
import {
  createParagraphPlugin,
  ELEMENT_PARAGRAPH,
} from '@udecode/plate-paragraph';
import {createDeserializeCsvPlugin} from '@udecode/plate-serializer-csv';
import {createDeserializeDocxPlugin} from '@udecode/plate-serializer-docx';
import {createDeserializeMdPlugin} from '@udecode/plate-serializer-md';

import {SlateTransformer} from '@accordproject/markdown-slate';
import {createTrailingBlockPlugin} from '@udecode/plate';
import {
  createListPlugin,
  ELEMENT_LI,
  ELEMENT_OL,
  ELEMENT_UL,
} from '@udecode/plate-list';
import {toastDanger} from 'components/Toaster';
import {Element} from 'domhandler';
import {errorHelpers} from 'helpers';
import {fileService} from 'services';
import {payloadSlateToDomConfig, slateToHtml} from 'slate-serializers';
import {Swaler} from 'swaler';
import AdvancedEditorToolbar from '../elements/AdvancedEditorToolbar';
import {HeadingElement} from '../elements/HeadingElement';
import ImageElement from '../elements/ImageElement';
import {LinkElement} from '../elements/LinkElement';
import {LinkFloatingToolbar} from '../elements/LinkFloatingToolbar';
import {ListElement} from '../elements/ListElement';
import {MediaEmbedElement} from '../elements/MediaEmbedElement';
import {handleEmbedUrl} from '../utils';
import './_Styles.scss';

const H1Element = (props) => <HeadingElement variant="h1" {...props} />;
const H2Element = (props) => <HeadingElement variant="h2" {...props} />;
const H3Element = (props) => <HeadingElement variant="h3" {...props} />;
const H4Element = (props) => <HeadingElement variant="h4" {...props} />;
const BoldLeaf = (props) => <PlateLeaf as="strong" {...props} />;
const ItalicLeaf = (props) => <PlateLeaf as="em" {...props} />;
const StrikethroughLeaf = (props) => <PlateLeaf as="s" {...props} />;
const UnderlineLeaf = (props) => <PlateLeaf as="u" {...props} />;
const ULElement = (props) => <ListElement variant="ul" {...props} />;
const OLElement = (props) => <ListElement variant="ol" {...props} />;
const LIElement = (props) => <PlateElement as="li" {...props} />;

const logger = new Swaler('AdvancedEditor');

const AdvancedEditor = ({
  value,
  rawValue,
  onChange,
  disabled,
  placeholder,
  onFocus = () => {},
  onBlur = () => {},
}) => {
  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }

    try {
      // Check if the file is a base64 string
      if (typeof file === 'string' && file.startsWith('data:')) {
        const blob = await fetch(file).then((res) => res.blob());

        // Optionally, you can generate a filename or use a default one
        const fileName = 'uploaded_file';
        const mimeType = blob.type; // Get the mime type from the blob
        const fileExtension = mimeType.split('/')[1]; // Extract the extension from the mime type

        // Convert blob to File object
        file = new File([blob], `${fileName}.${fileExtension}`, {
          type: mimeType,
        });
      }

      const uploadedFile = await fileService.uploadPublicFile({
        file,
      });

      return uploadedFile;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to upload file', code);
      toastDanger([title, message], {actions});
      return null;
    }
  };

  const plugins = createPlugins(
    [
      createParagraphPlugin(),
      createHeadingPlugin(),
      createLinkPlugin({
        renderAfterEditable: LinkFloatingToolbar,
      }),
      createImagePlugin({
        options: {
          // disableUploadInsert: true,
          uploadImage: async (data) => {
            const file = data;
            const uploadedFile = await uploadFile(file);

            if (uploadedFile != null) {
              return uploadedFile.publicUrl;
            }
            return null;
          },
        },
      }),
      createMediaEmbedPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createEmojiPlugin(),
      createDeserializeDocxPlugin(),
      createDeserializeCsvPlugin(),
      createDeserializeMdPlugin(),
      createJuicePlugin(),
      createTrailingBlockPlugin({
        type: ELEMENT_PARAGRAPH,
        level: 1,
      }),
      createListPlugin(),
    ],
    {
      components: {
        [ELEMENT_IMAGE]: ImageElement,
        [ELEMENT_LINK]: LinkElement,
        [ELEMENT_H1]: H1Element,
        [ELEMENT_H2]: H2Element,
        [ELEMENT_H3]: H3Element,
        [ELEMENT_H4]: H4Element,
        [ELEMENT_MEDIA_EMBED]: MediaEmbedElement,
        [MARK_BOLD]: BoldLeaf,
        [MARK_ITALIC]: ItalicLeaf,
        [MARK_STRIKETHROUGH]: StrikethroughLeaf,
        [MARK_UNDERLINE]: UnderlineLeaf,
        [ELEMENT_UL]: ULElement,
        [ELEMENT_OL]: OLElement,
        [ELEMENT_LI]: LIElement,
      },
    }
  );

  const config = {
    ...payloadSlateToDomConfig,
    elementTransforms: {
      ...payloadSlateToDomConfig.elementTransforms,
      a: ({node, children = []}) => {
        const attrs = {};
        if (node.linkType) {
          attrs['data-link-type'] = node.linkType;
        }
        if (node.newTab) {
          attrs.target = '_blank';
        }
        return new Element(
          'a',
          {
            href: node.url,
            ...attrs,
          },
          children
        );
      },
      img: ({node, children = []}) => {
        const width = node.width || 0;
        const attrs = {};
        if (node.linkType) {
          attrs['data-link-type'] = node.linkType;
        }
        if (node.newTab) {
          attrs.target = '_blank';
        }
        if (width > 0) {
          attrs.style = `width: ${width}px`;
        }
        return new Element(
          'img',
          {
            src: node.url,
            ...attrs,
          },
          children
        );
      },
      // handle video embeds
      media_embed: ({node, children = []}) => {
        const width = node.width || 0;
        const attrs = {};
        if (node.linkType) {
          attrs['data-link-type'] = node.linkType;
        }
        if (node.newTab) {
          attrs.target = '_blank';
        }
        if (width > 0) {
          attrs.style = `width: ${width}px`;
        }
        const formatedUrl = handleEmbedUrl(node.url);
        return new Element(
          'iframe',
          {
            src: formatedUrl,
            ...attrs,
          },
          children
        );
      },
    },
  };

  const handleChange = (nextValue) => {
    // serialize slate state to an html string
    const html = slateToHtml(nextValue, config);
    onChange({rawValue: nextValue, value: html});
  };

  let editorValue;

  if (rawValue != null) {
    editorValue = rawValue;
  } else {
    const slateTransformer = new SlateTransformer();
    const slateValue = slateTransformer.fromMarkdown(value);
    editorValue = slateValue.document.children;
  }

  return (
    <div className="advanced-editor">
      <Plate plugins={plugins} value={editorValue} onChange={handleChange}>
        <AdvancedEditorToolbar />

        <PlateContent
          className="editor-wrapper"
          readOnly={disabled}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </Plate>
    </div>
  );
};

export default AdvancedEditor;
