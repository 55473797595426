import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import {BLOCK_TYPE_CONCEPT} from 'services/steps';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import PixelPicker from '../../components/items/PixelPicker';
import ConceptThumbnailLink from '../../components/modals/ConceptThumbnailLink';
import './_Styles.scss';
import iconFigma from './icons/figma.png';
import iconInvision from './icons/invision.png';
import iconMaze from './icons/maze.png';

const Concept = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_CONCEPT, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_CONCEPT);

  if (block == null) {
    return <></>;
  }

  const {value, style, file} = block;
  const [type, conceptTestUrl, fileUrl, altText] = value.split(';');

  return (
    <div className="block-settings concept">
      <div className="section">
        <div className="section-title">Prototype</div>
        <div className="section-content">
          <div className="section-item">
            <InputGroup
              className="prototype-info-input"
              value={conceptTestUrl}
              placeholder="Prototype url (figma, invision, etc..)"
              onChange={({target}) => {
                updateBlock({
                  value: `${type};${target.value};${fileUrl};${altText}`,
                });
              }}
            />
          </div>
        </div>
        <div className="icons-brand-wrapper fade-in">
          <a
            href="https://help.usejimo.com/help-center/v/using-jimo/integrations/figma"
            target="_blank"
            rel="noreferrer">
            <img src={iconFigma} alt="Figma" />
          </a>
          <a
            href="https://help.usejimo.com/help-center/v/using-jimo/integrations/invision"
            target="_blank"
            rel="noreferrer">
            <img src={iconInvision} alt="Invision" />
          </a>
          <a
            href="https://help.usejimo.com/help-center/v/using-jimo/integrations/maze"
            target="_blank"
            rel="noreferrer">
            <img src={iconMaze} alt="Maze" />
          </a>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">Layout</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Image</div>
            <div className="section-item-content">
              <PopupSetting
                trigger={
                  <ClickableInput
                    key={fileUrl}
                    value={file?.originalName}
                    leftLabel={
                      fileUrl ? (
                        <div className="preview-prototype-media">
                          <img src={fileUrl} alt="Media" />
                        </div>
                      ) : (
                        <span
                          className="placeholder-empty-media"
                          style={{background: '#FFFFFF'}}
                        />
                      )
                    }
                    erasable
                    onErase={() => {
                      updateBlock({
                        value: `${type};${conceptTestUrl};${fileUrl};${altText}`,
                        file: null,
                        fileId: null,
                      });
                    }}
                  />
                }
                title="Image"
                content={
                  <ConceptThumbnailLink
                    fileUrl={fileUrl}
                    altText={altText}
                    file={file}
                    onChange={({value, file}) => {
                      updateBlock({
                        value: `${type};${conceptTestUrl};${value};${altText}`,
                        file,
                        fileId: file?.uid,
                      });
                    }}
                  />
                }
              />
            </div>
          </div>
          <ConceptStyle style={style} updateStyle={updateBlock} />
        </div>
      </div>
    </div>
  );
};

export const ConceptStyle = ({style, updateStyle}) => {
  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Radius</div>
        <div className="section-item-content">
          <PixelPicker
            value={style.borderRadius}
            min={0}
            max={24}
            onChange={(value) =>
              updateStyle({
                style: {...style, borderRadius: value},
              })
            }
          />
        </div>
      </div>
    </>
  );
};

export default Concept;
