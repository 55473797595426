import Divider from 'components/Divider';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import {fontFamiliesOptions} from '../Title/utils';
import './_Styles.scss';

export const CHECKLIST_HEADER_PROGRESS_BAR = 'bar';
export const CHECKLIST_HEADER_PROGRESS_STEPS = 'steps';

const shapeOptions = [
  {
    label: (
      <>
        <i className="isax isax-settings5" />
        Bar
      </>
    ),
    value: CHECKLIST_HEADER_PROGRESS_BAR,
  },
  {
    label: (
      <>
        <i className="isax isax-fatrows5" />
        Steps
      </>
    ),
    value: CHECKLIST_HEADER_PROGRESS_STEPS,
  },
];

const ChecklistProgress = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    selectedBlockType,
  } = useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(selectedBlockType, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === selectedBlockType);

  if (block == null) {
    return <></>;
  }

  const {value, style} = block;

  return (
    <div className="block-settings checklist-header-progress">
      <div className="section">
        <div className="section-title">General</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title body-3">Type</div>
            <div className="section-item-content">
              <SelectGroup
                isSearchable={false}
                classNamePrefix="type-icon-select"
                options={shapeOptions}
                value={shapeOptions.find((o) => o.value === value)}
                onChange={(option) => {
                  updateBlock({
                    value: option.value,
                  });
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">Style</div>
        <div className="section-content">
          <ChecklistProgressStyle style={style} updateStyle={updateBlock} />
        </div>
      </div>
    </div>
  );
};

export const ChecklistProgressStyle = ({style, updateStyle}) => {
  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Font family</div>
        <div className="section-item-content">
          <SelectGroup
            small
            options={fontFamiliesOptions}
            value={fontFamiliesOptions.find(
              (o) => o.value === style.fontFamily
            )}
            onChange={(option) =>
              updateStyle({
                style: {...style, fontFamily: option.value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font-size</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.fontSize}
            min={8}
            max={42}
            onChange={(value) => {
              updateStyle({
                style: {...style, fontSize: value},
              });
            }}
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Active</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Step"
            value={style.primaryColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, primaryColor: value},
              })
            }
          />
        </div>
      </div>

      <div className="section-item">
        <div className="section-item-title body-3">Inactive</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Step active"
            value={style.secondaryColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, secondaryColor: value},
              })
            }
          />
        </div>
      </div>
    </>
  );
};

export default ChecklistProgress;
