import classNames from 'classnames';
import {hasFlag} from 'helpers/bitwise';
import {BLOCKS, getTypeFromBoostFlags} from 'helpers/poke';
import React, {useContext} from 'react';
import {AudienceContext} from 'scenes/PokeAudience';
import {getIcon} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
  F_BOOST_SLOT_NAVIGATION,
} from 'services/evolution';
import {STEP_TYPE_CONCEPT_TEST} from 'services/steps';
import './_Styles.scss';

const AdoptionStepsList = ({previewedStepId, setPreviewedStepId}) => {
  const {evolution} = useContext(AudienceContext);

  const {tourSteps} = evolution;

  return (
    <div className="steps-list-adoption">
      {tourSteps?.map((tourStep) => {
        const type = getTypeFromBoostFlags(tourStep.boostFlags);
        const icon = getIcon(type);

        if (hasFlag(F_BOOST_SLOT_NAVIGATION, tourStep.boostFlags)) {
          return (
            <div className={classNames('group', 'navigation')}>
              <div
                className={classNames('tour-step', {
                  selected: tourStep.uid === previewedStepId,
                })}>
                {icon}
              </div>
            </div>
          );
        }

        const isGroup =
          tourStep?.steps.filter((s) => s.removed !== true).length > 1;
        const hasAtLeastOneStep =
          tourStep?.steps.filter((s) => s.removed !== true).length > 0;

        if (hasAtLeastOneStep !== true) {
          return <></>;
        }

        return (
          <div
            className={classNames('group', {
              'is-group': isGroup,
            })}>
            <div
              style={{
                display: 'flex',
              }}>
              {tourStep?.steps
                .filter((s) => s.removed !== true)
                .map((step, stepIndex) => {
                  return (
                    <div
                      className={classNames('tour-step', {
                        selected: step.uid === previewedStepId,

                        'has-prev-step': stepIndex > 0,
                        'has-next-step':
                          stepIndex <
                          tourStep.steps.filter((s) => s.removed !== true)
                            .length -
                            1,
                      })}
                      onClick={() => setPreviewedStepId(step.uid)}>
                      {icon}
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const DiscoveryStepsList = ({previewedStepId, setPreviewedStepId}) => {
  const {evolution} = useContext(AudienceContext);

  const {steps} = evolution;

  return (
    <div className="steps-list-discovery">
      {steps
        .filter((s) => s.removed !== true)
        .sort((a, b) => a.indexOrder - b.indexOrder)
        .map((step) => {
          const blockType = BLOCKS.find((b) => b.step === step.type);

          return (
            <div
              className={classNames('block-wrapper', {
                'is-step-concept': step.type === STEP_TYPE_CONCEPT_TEST,
                'is-sub-concept-step-selected':
                  step.prototypes?.[0]?.steps.findIndex(
                    (s) => s.uid === previewedStepId
                  ) > -1,
                'is-parent-concept-step-selected': previewedStepId === step.uid,
              })}>
              <div
                className={classNames('block-selector', {
                  selected: step.uid === previewedStepId,
                })}
                style={{
                  background: blockType?.iconBackgroundColor,
                  border: `2px solid ${blockType?.iconBackgroundColor}`,
                }}
                onClick={() => {
                  setPreviewedStepId(step.uid);
                }}>
                <div className="block-type-icon">{blockType?.icon}</div>
              </div>
              {step.type === STEP_TYPE_CONCEPT_TEST && (
                <div
                  className={classNames('sub-blocks-wrapper')}
                  style={{
                    display: 'flex',
                  }}>
                  {step.prototypes?.[0]?.steps
                    ?.sort((a, b) => a.indexOrder - b.indexOrder)
                    ?.map((prototypeStep, prototypeStepIndex) => {
                      const blockType = BLOCKS.find(
                        (b) => b.step === prototypeStep.type
                      );

                      return (
                        <div className="sub-block-selector-wrapper">
                          <div
                            className={classNames('sub-block-selector', {
                              selected: prototypeStep.uid === previewedStepId,
                            })}
                            style={{
                              background: blockType?.iconBackgroundColor,
                              border: `2px solid ${blockType?.iconBackgroundColor}`,
                            }}
                            onClick={() => {
                              setPreviewedStepId(prototypeStep.uid);
                            }}>
                            <div className="block-type-icon">
                              {blockType?.icon}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

const StepsList = ({previewedStepId, setPreviewedStepId}) => {
  const {evolution} = useContext(AudienceContext);

  const {type} = evolution;

  if (
    [EVOLUTION_TYPE_BANNER, EVOLUTION_TYPE_TOUR, EVOLUTION_TYPE_HINT].includes(
      type
    )
  ) {
    return (
      <AdoptionStepsList
        previewedStepId={previewedStepId}
        setPreviewedStepId={setPreviewedStepId}
      />
    );
  } else if (type === EVOLUTION_TYPE_SURVEY) {
    return (
      <DiscoveryStepsList
        previewedStepId={previewedStepId}
        setPreviewedStepId={setPreviewedStepId}
      />
    );
  }
  return <></>;
};

export default StepsList;
