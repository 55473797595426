import classNames from 'classnames';
import Button from 'components/Button';
import {usePrevious} from 'helpers/utils';
import {bool, node, string} from 'prop-types';
import {useContext, useEffect, useRef, useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {AudienceContext} from 'scenes/PokeAudience';
import './_Styles.scss';

const propTypes = {
  section: string.isRequired,
  title: string.isRequired,
  children: node.isRequired,
  className: string,
  expanded: bool,
  isLastSection: bool,
  icon: node,
  forceChecked: bool,
};

const AudienceSection = ({
  section,
  className = '',
  title,
  subtitle = null,
  children,
  isLastSection = false,
  icon = null,
  titleOnly = false,
  alwaysExpanded = false,
}) => {
  const {
    selectedSection,
    setSelectedSection,
    save,
    evolution,
    disableAnimation,
  } = useContext(AudienceContext);

  const [disableScrollTo, setDisableScrollTo] = useState(false);

  const ref = useRef();

  const expanded = selectedSection === section;

  const previousExpanded = usePrevious(expanded);

  useEffect(() => {
    if (previousExpanded === true && expanded === false) {
      if (evolution.isDraft === true) {
        save();
      }
    }

    if (expanded === true && disableScrollTo === false) {
      if (ref.current) {
        setTimeout(() => {
          ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const isExpanded = expanded === true || alwaysExpanded === true;

  return (
    <div
      ref={ref}
      className={classNames('audience-section', className, {
        expanded: isExpanded,
      })}
      onClick={() => {
        if (alwaysExpanded === true) {
          return;
        }

        if (expanded !== true) {
          setDisableScrollTo(true);
          setSelectedSection(section);

          setTimeout(() => {
            setDisableScrollTo(false);
          }, 300);
        }
      }}>
      <div className="audience-section-header">
        {icon && <div className="icon-wrapper">{icon}</div>}
        <div className="audience-section-title">
          {title}
          {isExpanded !== true && subtitle}
        </div>
        {titleOnly !== true && (
          <>
            {expanded === true && isLastSection !== true ? (
              <Button
                className="done-btn"
                thin
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  setSelectedSection(null);
                }}>
                Done
              </Button>
            ) : (
              <div
                className="audience-section-expand"
                onClick={() => setSelectedSection(section)}>
                <i className="icon-chevron-bottom" />
              </div>
            )}
          </>
        )}
      </div>
      <AnimateHeight
        height={isExpanded === true ? 'auto' : 0}
        duration={disableAnimation === true ? 0 : 300}>
        <div className="audience-section-content">{children}</div>
      </AnimateHeight>
    </div>
  );
};

AudienceSection.propTypes = propTypes;

export default AudienceSection;
