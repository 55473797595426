import Button from 'components/Button';
import dayjs from 'dayjs';
import React, {useEffect, useRef, useState} from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  TIME_FREQUENCY_DAILY,
  TIME_FREQUENCY_MONTHLY,
  TIME_FREQUENCY_WEEKLY,
} from 'scenes/Tracker/components/FeatureUsageChart/components/FrequencySelectionDropdown';
import './_Styles.scss';

const ChartTooltip = ({
  data = {},
  onSeeBreakdown = () => {},
  frequency = TIME_FREQUENCY_DAILY,
  label = 'time',
}) => {
  const {count, timestamp} = data;

  return (
    <div className="chart-tooltip-wrapper">
      <div className="chart-tooltip">
        <div className="title">
          {frequency === TIME_FREQUENCY_DAILY
            ? dayjs(timestamp * 1000).format('ddd, DD MMM')
            : frequency === TIME_FREQUENCY_WEEKLY
            ? dayjs(timestamp * 1000).format('MMM DD - ') +
              dayjs(timestamp * 1000)
                .endOf('week')
                .format('MMM DD')
            : frequency === TIME_FREQUENCY_MONTHLY
            ? dayjs(timestamp * 1000).format('MMM YYYY')
            : ''}
        </div>
        <div className="groups">
          <div className="group">
            <div className="color-indication" />
            <div className="data">
              <div className="group-value">
                {count} {label}
                {count > 1 ? 's' : ''}
              </div>
            </div>
          </div>
        </div>
        <Button
          className="see-breakdown-btn"
          primary
          reverted
          thin
          onClick={() => {
            onSeeBreakdown(timestamp);
          }}>
          See breakdown
        </Button>
      </div>
    </div>
  );
};

const LineChartRechart = ({
  data,
  frequency = TIME_FREQUENCY_DAILY,
  showInPercentage = false,
  tooltipLabel = 'time',
  onViewBreakdown = () => {},
}) => {
  const [tooltipPosition, setTooltipPosition] = useState(null);
  const [tooltipHovered, setTooltipHovered] = useState(false);
  const [areaHovered, setAreaHovered] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);

  const toPercent = (decimal) => {
    return `${(decimal * 100).toFixed(0)}%`;
  };

  const tooltipHoveredRef = useRef();
  const areaHoveredRef = useRef();

  useEffect(() => {
    tooltipHoveredRef.current = tooltipHovered;
  }, [tooltipHovered]);

  useEffect(() => {
    areaHoveredRef.current = areaHovered;
  }, [areaHovered]);

  return (
    <div className="line-chart-rechart">
      {tooltipPosition != null && (
        <div
          style={{
            zIndex: '1',
            position: 'absolute',
            left: tooltipPosition.x - 5,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
          onMouseEnter={() => {
            setTooltipHovered(true);
          }}
          onMouseLeave={() => {
            setTooltipHovered(false);

            setTimeout(() => {
              if (areaHoveredRef.current === true) {
                return;
              }
              setTooltipPosition(null);
            }, 200);
          }}>
          <ChartTooltip
            data={tooltipData}
            onSeeBreakdown={(timestamp) => {
              onViewBreakdown(timestamp);
            }}
            frequency={frequency}
            label={tooltipLabel}
          />
        </div>
      )}
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          onMouseEnter={() => setAreaHovered(true)}
          onMouseMove={(e) => {
            setTooltipPosition(e.activeCoordinate);
            setTooltipData({
              timestamp: e.activePayload?.[0].payload.timestamp,
              count: e.activePayload?.[0].payload.count,
            });
          }}
          onMouseLeave={() => {
            setAreaHovered(false);
            setTimeout(() => {
              if (
                tooltipHoveredRef.current === true ||
                areaHoveredRef.current === true
              ) {
                return;
              }
              setTooltipPosition(null);
            }, 200);
          }}
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}>
          <CartesianGrid horizontal vertical={false} stroke="#0000001a" />
          <XAxis
            dataKey="timestamp"
            domain={['dataMin', 'dataMax']}
            axisLine={false}
            tickLine={false}
            tickCount={3}
            name="Date"
            tickFormatter={(unixTime) => {
              if (frequency === TIME_FREQUENCY_DAILY) {
                return dayjs(unixTime * 1000).format('MMM DD');
              } else if (frequency === TIME_FREQUENCY_WEEKLY) {
                const startDay = dayjs(unixTime * 1000).startOf('week');
                const endDay = dayjs(unixTime * 1000).endOf('week');
                return `${startDay.format('MMM DD')} - ${endDay.format(
                  'MMM DD'
                )}`;
              } else if (frequency === TIME_FREQUENCY_MONTHLY) {
                return dayjs(unixTime * 1000).format('MMM YYYY');
              }
            }}
            type="number"
          />
          {showInPercentage === true ? (
            <YAxis
              axisLine={false}
              tickLine={false}
              domain={[0, 1]}
              ticks={[0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]}
              allowDecimals={false}
              tickFormatter={toPercent}
              width={30}
            />
          ) : (
            <YAxis
              axisLine={false}
              tickLine={false}
              domain={['auto', 'auto']}
              type="number"
              allowDecimals={false}
              width={30}
            />
          )}
          <Tooltip content={() => <></>} />
          <Area
            type="monotone"
            dataKey="count"
            stackId="1"
            stroke="#89B6FF"
            fill="#E5EFFF"
            onMouseEnter={(e) => {
              setTooltipData(e.payload);
              setAreaHovered(true);
              setTooltipPosition(e.tooltipPosition);
            }}
            onMouseOut={() => {
              setAreaHovered(false);
              setTimeout(() => {
                if (
                  tooltipHoveredRef.current === true ||
                  areaHoveredRef.current === true
                ) {
                  return;
                }
                setTooltipPosition(null);
              }, 200);
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChartRechart;
