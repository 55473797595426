import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import {PermissionsEvent} from 'constants/permissions';
import {hasFlag} from 'helpers/bitwise';
import {hasPermissions} from 'helpers/permission';
import {createContext, useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {
  EVENT_CONDITION_TYPE_DELAY,
  EVENT_CONDITION_TYPE_ELEMENT,
  EVENT_CONDITION_TYPE_INPUT,
  EVENT_CONDITION_TYPE_TOUR_SEEN,
  EVENT_CONDITION_TYPE_URL,
} from 'services/event';
import {F_EXTRA_DISABLE_FILLED_INPUT_TRIGGERS} from 'services/project';
import './_Styles.scss';
import Condition from './components/Condition';
import EventConditionsEmptyState from './components/EventConditionsEmptyState';
import {
  addAttributeToLogic,
  addLogicGroupToLogic,
  createCondition,
  deleteAttributeFromLogic,
  deleteLogicGroupFromLogic,
  updateLogicOperator,
} from './utils';

export const EventConditionsEditorContext = createContext();

const DropdownMenuItem = ({iconClassName, icon, title, subtitle, onClick}) => {
  return (
    <div className="dropdown-menu-item" onClick={onClick}>
      <div className={classNames('icon-wrapper', iconClassName)}>{icon}</div>
      <div className="content">
        <div className="title body-3 n-800">{title}</div>
        <div className="subtitle body-4 n-700">{subtitle}</div>
      </div>
    </div>
  );
};

export const AddConditionDropdown = ({
  groupId,
  handleAddCondition,
  handleAddLogicGroup,
  omitTypes,
  extraConditionTypes = [],
  className = '',
  ...rest
}) => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <Dropdown
      open={showDropdown}
      className={classNames('add-event-condition', className)}
      position="bottom left"
      repositionOnResize={true}
      offsetY={-64}
      offsetX={8}
      trigger={
        <Button
          className="add-segment-element-btn"
          onClick={() => setShowDropdown(true)}
          thin>
          Add...
        </Button>
      }
      {...rest}>
      <div className="event-conditions-list">
        {omitTypes.includes(EVENT_CONDITION_TYPE_ELEMENT) === false && (
          <DropdownMenuItem
            iconClassName="element"
            icon={<i className="isax isax-mouse-circle" />}
            title="Element"
            subtitle="Element is present, clicked or hovered"
            onClick={() => {
              handleAddCondition(EVENT_CONDITION_TYPE_ELEMENT, groupId);
              setShowDropdown(false);
            }}
          />
        )}
        {omitTypes.includes(EVENT_CONDITION_TYPE_INPUT) === false &&
          hasFlag(F_EXTRA_DISABLE_FILLED_INPUT_TRIGGERS, project.extraFlags) ===
            false && (
            <DropdownMenuItem
              iconClassName="input"
              icon={<i className="isax isax-keyboard" />}
              title="Fills in input"
              subtitle="Input is being filled"
              onClick={() => {
                handleAddCondition(EVENT_CONDITION_TYPE_INPUT, groupId);
                setShowDropdown(false);
              }}
            />
          )}
        {omitTypes.includes(EVENT_CONDITION_TYPE_URL) === false && (
          <DropdownMenuItem
            iconClassName="url"
            icon={<i className="isax isax-receipt-search" />}
            title="Current page"
            subtitle="User opens a specific page URL"
            onClick={() => {
              handleAddCondition(EVENT_CONDITION_TYPE_URL, groupId);
              setShowDropdown(false);
            }}
          />
        )}
        {omitTypes.includes(EVENT_CONDITION_TYPE_DELAY) === false && (
          <DropdownMenuItem
            iconClassName="delay"
            icon={<i className="isax isax-clock" />}
            title="Delay"
            subtitle="User waits for a specific time"
            onClick={() => {
              handleAddCondition(EVENT_CONDITION_TYPE_DELAY, groupId);
              setShowDropdown(false);
            }}
          />
        )}
        {extraConditionTypes.includes(EVENT_CONDITION_TYPE_TOUR_SEEN) ===
          true && (
          <DropdownMenuItem
            iconClassName="tour-seen"
            icon={<i className="isax isax-routing-2" />}
            title="Tours"
            subtitle="User has seen a specific tour"
            onClick={() => {
              handleAddCondition(EVENT_CONDITION_TYPE_TOUR_SEEN, groupId);
              setShowDropdown(false);
            }}
          />
        )}
        {omitTypes.includes('logicGroup') === false && (
          <>
            <Divider />
            <DropdownMenuItem
              iconClassName="logic-group"
              icon={<i className="icon-merge" />}
              title="Logic group"
              subtitle="Create a new condition group"
              onClick={() => {
                handleAddLogicGroup(groupId);
                setShowDropdown(false);
              }}
            />
          </>
        )}
      </div>
    </Dropdown>
  );
};

const Logic = ({logicGroup, disabled}) => {
  const {
    conditions,
    handleAddCondition,
    handleAddLogicGroup,
    handleUpdateLogicOperator,
    handleDeleteLogicGroupFromLogic,
    omitTypes,
    extraConditionTypes,
  } = useContext(EventConditionsEditorContext);

  if (logicGroup == null) {
    return null;
  }

  const {uid, operator, children} = logicGroup;

  const handleToggleOperator = () => {
    if (disabled) {
      return;
    }
    const newOperator = operator === 'and' ? 'or' : 'and';
    handleUpdateLogicOperator(uid, newOperator);
  };

  return (
    <div className="logic-group">
      <div className="content">
        {children?.length >= 1 && (
          <div className={classNames('logic-trait-wrapper', operator)}>
            <div className="logic-trait" />
            <div className="logic-operator-wrapper">
              <div
                className={classNames('logic-operator', 'and', {
                  selected: operator === 'and',
                })}
                onClick={handleToggleOperator}>
                and
              </div>
              <div
                className={classNames('logic-operator', 'or', {
                  selected: operator === 'or',
                })}
                onClick={handleToggleOperator}>
                or
              </div>
            </div>
          </div>
        )}
        <div className="logic-conditions">
          {children?.map((item) => {
            const {type, attributeId} = item;
            if (type === 'logicGroup') {
              return <Logic logicGroup={item} disabled={disabled} />;
            }
            const attribute = conditions.find(
              (attr) => attr.uid === attributeId
            );
            if (attribute == null) {
              return null;
            }
            return (
              <div className="logic-condition">
                <Condition condition={attribute} disabled={disabled} />
              </div>
            );
          })}
          {!disabled && (
            <div className="logic-actions">
              <AddConditionDropdown
                groupId={uid}
                handleAddCondition={handleAddCondition}
                handleAddLogicGroup={handleAddLogicGroup}
                omitTypes={omitTypes}
                extraConditionTypes={extraConditionTypes}
              />
            </div>
          )}
        </div>
      </div>
      {!disabled && (
        <Button
          className="delete-btn"
          iconLeft="icon-trash"
          iconOnly
          danger
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            handleDeleteLogicGroupFromLogic(uid);
          }}
        />
      )}
    </div>
  );
};

const EventConditions = ({
  conditions,
  setConditions,
  logic,
  setLogic,
  borderless,
  compact,
  omitTypes = [],
  extraConditionTypes = [],
  settingsPositioning,
  CustomEmptyState,
  onSelectElement,
  newlyAddedCondition: newlyAddedConditionProp,
  singleCondition,
}) => {
  const [newlyAddedCondition, setNewlyAddedCondition] = useState(
    newlyAddedConditionProp
  );

  const handleAddCondition = (type, groupId) => {
    const newCondition = createCondition(type);
    const conditionsUpdated = conditions.concat(newCondition);

    setConditions(conditionsUpdated);
    const updatedLogic = addAttributeToLogic(logic, newCondition, groupId);
    setLogic(JSON.parse(JSON.stringify(updatedLogic)));
    setNewlyAddedCondition(newCondition);
  };

  const handleAddLogicGroup = (groupId) => {
    const updatedLogic = addLogicGroupToLogic(logic, groupId);
    setLogic(JSON.parse(JSON.stringify(updatedLogic)));
  };

  const handleDeleteConditionFromLogic = (conditionId) => {
    const updatedLogic = deleteAttributeFromLogic(logic, conditionId);
    setConditions(conditions.filter((c) => c.uid !== conditionId));
    setLogic(JSON.parse(JSON.stringify(updatedLogic)));
  };

  const handleDeleteLogicGroupFromLogic = (groupId) => {
    const {logic: updatedLogic, conditions: updatedConditions} =
      deleteLogicGroupFromLogic(logic, conditions, groupId);
    setConditions(updatedConditions);
    setLogic(JSON.parse(JSON.stringify(updatedLogic)));
  };

  const handleUpdateLogicOperator = (groupId, operator) => {
    const updatedLogic = updateLogicOperator(logic, groupId, operator);
    setLogic(JSON.parse(JSON.stringify(updatedLogic)));
  };

  const canEditEvent = hasPermissions(PermissionsEvent.CREATE_EDIT);

  return (
    <EventConditionsEditorContext.Provider
      value={{
        conditions,
        setConditions,
        logic,
        setLogic,
        handleAddLogicGroup,
        handleUpdateLogicOperator,
        handleDeleteLogicGroupFromLogic,
        handleDeleteConditionFromLogic,
        handleAddCondition,
        newlyAddedCondition,
        setNewlyAddedCondition,
        compact,
        settingsPositioning,
        omitTypes,
        extraConditionTypes,
        onSelectElement,
      }}>
      <div
        className={classNames('event-conditions-wrapper', {
          borderless: borderless,
          empty: conditions.length === 0,
        })}>
        {!borderless && (
          <div className="event-conditions-title">Conditions</div>
        )}

        {conditions.length > 0 ? (
          <>
            {singleCondition === true ? (
              <div className="single-condition-wrapper">
                <Condition
                  condition={conditions[0]}
                  disabled={false}
                  isNotDeletable
                />
              </div>
            ) : (
              <Logic logicGroup={logic} disabled={!canEditEvent} />
            )}
          </>
        ) : CustomEmptyState ? (
          <CustomEmptyState
            groupId={logic?.uid}
            handleAddCondition={handleAddCondition}
            handleAddLogicGroup={handleAddLogicGroup}
            omitTypes={omitTypes}
          />
        ) : (
          <EventConditionsEmptyState />
        )}
      </div>
    </EventConditionsEditorContext.Provider>
  );
};

export default EventConditions;
