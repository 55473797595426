import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import ColorPickerInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ColorPickerInput';
import PixelPicker from 'scenes/PokeBuilder/components/BlockEditor/components/items/PixelPicker';
import Shadow from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Shadow';

const ChecklistStyle = ({style, updateStyle: setStyle}) => {
  const updateStyle = (newStyle) => {
    setStyle({
      style: {
        ...style,
        ...newStyle,
      },
    });
  };

  return (
    <>
      <div className="section-item">
        <div className="section-item-title">Background</div>
        <div className="section-item-content background-picker">
          <ColorPickerInput
            title="Background color"
            value={style.backgroundColor || null}
            onChange={(value) => updateStyle({backgroundColor: value})}
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Border</div>
        <div className="section-item-content">
          <ColorPickerInput
            title="Border color"
            value={style.borderColor || null}
            onChange={(value) => updateStyle({borderColor: value})}
            erasable
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Shadow</div>
        <div className="section-item-content shadow-picker">
          <PopupSetting
            trigger={
              <ClickableInput
                value={style.shadow?.color}
                leftLabel={
                  <span
                    className="preview-shadow-color"
                    style={{background: style.shadow?.color || '#FFFFFF'}}
                  />
                }
                erasable={true}
                onErase={() =>
                  updateStyle({
                    shadow: {color: null, opacity: 0, x: 0, y: 0, blur: 0},
                  })
                }
              />
            }
            title="Shadow"
            content={
              <Shadow
                value={style.shadow}
                onChange={(value) => updateStyle({shadow: value})}
              />
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Overlay</div>
        <div className="section-item-content">
          <ColorPickerInput
            title="Overlay"
            value={style.overlay}
            onChange={(value) => updateStyle({overlay: value})}
            erasable
            inputProps={{
              onClick: () => {
                if (style.overlay == null) {
                  updateStyle({overlay: '#00000029'});
                }
              },
            }}
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Radius</div>
        <div className="section-item-content">
          <PixelPicker
            value={style.borderRadius}
            min={0}
            max={50}
            onChange={(value) => updateStyle({borderRadius: value})}
            radius
          />
        </div>
      </div>
    </>
  );
};

export default ChecklistStyle;
