import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import SelectGroup from 'components/Select';
import Tooltip from 'components/Tooltip';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import React, {useContext} from 'react';
import {F_OPTION_POKE_CARD_WITH_POINTER} from 'services/evolution';
import {BLOCK_TYPE_HINT} from 'services/steps';
import RadioGroup from '../../../../../../components/RadioGroup';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import HintPosition from '../../components/modals/HintPosition';
import IconPicker, {
  HINT_ICON_BUILT_IN,
  hintIconsList,
} from '../../components/modals/IconPicker';
import {fontWeightOptions} from '../Title';
import {fontFamiliesOptions} from '../Title/utils';
import './_Styles.scss';

export const HOTSPOT_SHAPE_DEFAULT = 'default';
export const HOTSPOT_SHAPE_QUESTION_MARK = 'question-mark';
export const HOTSPOT_SHAPE_EXCLAMATION_MARK = 'exclamation-mark';
export const HOTSPOT_ANIMATION_PULSE = 'pulse';
export const HOTSPOT_ANIMATION_NONE = 'none';

export const HINT_TYPE_ICON = 'icon';
export const HINT_TYPE_LABEL = 'label';
export const HINT_TYPE_HIDDEN = 'hidden';
export const HINT_TYPE_PULSATING = 'pulsating'; // only used when adding a new hint for the initial hint type

const pulsatingOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

const pointerOptions = [
  {label: 'Show', value: true},
  {label: 'Hide', value: false},
];

export const triggerOptions = [
  {
    label: (
      <>
        <i className="icon-question-circle" /> Icon
      </>
    ),
    value: HINT_TYPE_ICON,
  },
  {
    label: (
      <>
        <i className="icon-label-icon" /> Label
      </>
    ),
    value: HINT_TYPE_LABEL,
  },
  {
    label: (
      <>
        <i className="icon-target" /> Target Element
      </>
    ),
    value: HINT_TYPE_HIDDEN,
  },
];

const Hint = () => {
  const {
    controlledEvolution: evolution,
    setControlledEvolution: setEvolution,
    selectedStep: step,
    updateBlock: uptBlock,
    isInApp,
    setIsEditingTargetElement,
  } = useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_HINT, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_HINT);

  if (block == null) {
    return <></>;
  }

  const hasPointer = hasFlag(
    F_OPTION_POKE_CARD_WITH_POINTER,
    evolution.optionsFlags
  );

  const {value = '', style = {}, file} = block;

  const selectedIcon = hintIconsList.find((i) => i.value === style.iconName);

  return (
    <div className="block-settings hint">
      <div className="section">
        <div className="section-title">General</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Position</div>
            <div className="section-item-content position-picker">
              {isInApp === true ? (
                <ClickableInput
                  value={
                    evolution.querySelectorManual?.elementText != null ||
                    evolution.querySelectorManual?.cssSelector != null
                      ? 'Manual selector'
                      : evolution.boostedQueryselector || 'Select an element'
                  }
                  leftLabel={
                    <div className="position-icon-wrapper">
                      <i className="icon-target" />
                    </div>
                  }
                  onClick={() => {
                    setIsEditingTargetElement(true);
                  }}
                />
              ) : (
                <PopupSetting
                  large
                  className="hint-position-popup"
                  trigger={
                    <ClickableInput
                      value={
                        evolution.querySelectorManual?.elementText != null ||
                        evolution.querySelectorManual?.cssSelector != null
                          ? 'Manual selector'
                          : evolution.boostedQueryselector ||
                            'Select an element'
                      }
                      leftLabel={
                        <div className="position-icon-wrapper">
                          <i className="icon-target" />
                        </div>
                      }
                    />
                  }
                  title="Position"
                  content={
                    <HintPosition
                      evolution={evolution}
                      setEvolution={setEvolution}
                    />
                  }
                  invalid={!evolution.boostedQueryselector}
                />
              )}
            </div>
          </div>
          <div className="section-item change-icon-type">
            <div className="section-item-title">Type</div>
            <div className="section-item-content">
              <SelectGroup
                isSearchable={false}
                classNamePrefix="type-icon-select"
                options={triggerOptions}
                value={triggerOptions.find((o) => o.value === style.type)}
                onChange={(option) => {
                  updateBlock({
                    style: {
                      ...style,
                      type: option.value,
                    },
                  });
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Pointer</div>
            <div className="section-item-content">
              <RadioGroup
                value={hasPointer}
                options={pointerOptions}
                onSelect={(v) => {
                  if (v === true) {
                    setEvolution({
                      ...evolution,
                      optionsFlags: addFlag(
                        F_OPTION_POKE_CARD_WITH_POINTER,
                        evolution.optionsFlags
                      ),
                    });
                  } else {
                    setEvolution({
                      ...evolution,
                      optionsFlags: removeFlag(
                        F_OPTION_POKE_CARD_WITH_POINTER,
                        evolution.optionsFlags
                      ),
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      {(hasPointer === true ||
        [HINT_TYPE_ICON, HINT_TYPE_LABEL].includes(style.type)) && (
        <div className="section">
          <div className="section-title">Style</div>
          <div className="section-content">
            {hasPointer === true && (
              <div className="section-item">
                <div className="section-item-title">Pointer color</div>
                <div className="section-item-content">
                  <ColorPickerInput
                    title="Pointer color"
                    value={
                      evolution?.style?.pointerColor ||
                      evolution?.style.background?.primaryColor
                    }
                    onChange={(value) => {
                      setEvolution({
                        ...evolution,
                        style: {
                          ...(evolution?.style || {}),
                          pointerColor: value,
                        },
                      });
                    }}
                    erasable={evolution?.style.pointerColor != null}
                  />
                </div>
              </div>
            )}
            {style.type === HINT_TYPE_ICON && (
              <>
                <div className="section-item item-icon-picker">
                  <div className="section-item-title">Icon</div>
                  <div className="section-item-content">
                    <PopupSetting
                      className="picker-icon-popup"
                      trigger={
                        <ClickableInput
                          value={
                            style.iconSource === HINT_ICON_BUILT_IN
                              ? selectedIcon?.label
                              : style.iconUrl != null
                              ? 'Custom Icon'
                              : null
                          }
                          leftLabel={
                            <span className="preview-icon" style={{}}>
                              {style.iconSource === HINT_ICON_BUILT_IN ? (
                                selectedIcon?.icon
                              ) : style.iconUrl ? (
                                <img src={style.iconUrl} alt="" />
                              ) : (
                                <></>
                              )}
                            </span>
                          }
                          inputProps={{
                            placeholder: 'Select an icon',
                          }}
                        />
                      }
                      title="Hint icons"
                      content={
                        <IconPicker
                          style={style}
                          file={file}
                          type="hint"
                          onChange={({style, file}) => {
                            updateBlock({
                              style: {
                                ...style,
                                ...value,
                              },
                              file,
                            });
                          }}
                        />
                      }
                    />
                  </div>
                </div>
                <IconStyleSelection
                  style={style}
                  updateStyle={updateBlock}
                  hasPointer={hasPointer}
                />
              </>
            )}
            {style.type === HINT_TYPE_LABEL && (
              <>
                <div className="section-item">
                  <div className="section-item-title">Text</div>
                  <div className="section-item-content">
                    <InputGroup
                      className="button-content-input"
                      value={value}
                      onChange={({target}) =>
                        updateBlock({
                          value: target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <LabelStyleSelection
                  style={style}
                  updateStyle={updateBlock}
                  hasPointer={hasPointer}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const IconStyleSelection = ({style, updateStyle}) => {
  return (
    <>
      {style.iconSource === HINT_ICON_BUILT_IN && (
        <>
          <div className="section-item">
            <div className="section-item-title">Icon Color</div>
            <div className="section-item-content">
              <ColorPickerInput
                title="Icon Color"
                value={style.iconColor}
                onChange={(value) => {
                  updateStyle({
                    style: {
                      ...style,
                      iconColor: value,
                    },
                  });
                }}
              />
            </div>
          </div>
        </>
      )}
      {style.type === HINT_TYPE_ICON && (
        <div className="section-item pulsating">
          <div className="section-item-title">
            Pulsating
            <Tooltip
              dark
              offsetX={8}
              className="pulse-info-tooltip"
              position="left top"
              trigger={<i className="icon-info-circle-o n-700" />}>
              <div className="tooltip-tag body-4 y-400">
                <i className="isax isax-medal-star" /> Best practice
              </div>
              <div className="tooltip-content-wrapper">
                <div className="tooltip-title subtitle-3">
                  Tip: Limit Pulsating Icons to One per Page
                </div>
                <div className="tooltip-description body-4 n-400">
                  You have more than one pulsating icons in the same page.
                  Consider limiting pulsating icons to one per page to avoid
                  overwhelming users.
                </div>
              </div>
            </Tooltip>
          </div>
          <div className="section-item-content">
            <RadioGroup
              value={style.pulsating}
              options={pulsatingOptions}
              onSelect={(value) => {
                updateStyle({
                  style: {
                    ...style,
                    pulsating: value,
                  },
                });
              }}
            />
          </div>
        </div>
      )}
      <div className="section-item">
        <div className="section-item-title">Size</div>
        <div className="section-item-content">
          <PixelPicker
            value={style.fontSize}
            min={4}
            max={40}
            onChange={(value) =>
              updateStyle({
                style: {
                  ...style,
                  fontSize: value,
                },
              })
            }
          />
        </div>
      </div>
    </>
  );
};

export const LabelStyleSelection = ({style, updateStyle}) => {
  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Font family</div>
        <div className="section-item-content">
          <SelectGroup
            small
            options={fontFamiliesOptions}
            value={fontFamiliesOptions.find(
              (o) => o.value === style.fontFamily
            )}
            onChange={(option) =>
              updateStyle({
                style: {...style, fontFamily: option.value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font-size</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.fontSize}
            min={8}
            max={42}
            onChange={(value) => {
              updateStyle({
                style: {...style, fontSize: value},
              });
            }}
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font weight</div>
        <div className="section-item-content">
          <SelectGroup
            isSearchable={false}
            small
            options={fontWeightOptions}
            value={fontWeightOptions.find(
              (o) => o.value === (style.fontWeight || '400')
            )}
            onChange={(option) =>
              updateStyle({
                style: {...style, fontWeight: option.value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Padding</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.padding}
            min={0}
            max={24}
            onChange={(value) =>
              updateStyle({
                style: {...style, padding: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Radius</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.borderRadius}
            min={0}
            max={24}
            onChange={(value) =>
              updateStyle({
                style: {...style, borderRadius: value},
              })
            }
            radius
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Text color</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Text color"
            value={style.color}
            onChange={(value) =>
              updateStyle({
                style: {...style, color: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Background</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title={'Background'}
            value={style.backgroundColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, backgroundColor: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Border</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Border color"
            value={style.borderColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, borderColor: value},
              })
            }
            erasable
          />
        </div>
      </div>
    </>
  );
};

export default Hint;
