import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import {BLOCKS, getTypeFromBoostFlags} from 'helpers/poke';
import React, {useContext} from 'react';
import {components} from 'react-select';
import {getIcon} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import {BLOCK_TYPE_PRIMARY_CTA, BLOCK_TYPE_SECONDARY_CTA} from 'services/steps';
import './_Styles.scss';

const Option = (props) => {
  const {data} = props;

  return (
    <components.Option {...props}>
      <div className="option-wrapper">
        <div className="icon-wrapper">{data.icon}</div>
        <div className="content">
          <div className="title body-3 n-800">{data.label}</div>
          {data.subtitle && (
            <div className="subtitle body-4 n-700">{data.subtitle}</div>
          )}
        </div>
      </div>
    </components.Option>
  );
};

const SingleValue = ({...props}) => {
  const {data} = props;

  return (
    <components.ValueContainer className="single-value body-3" {...props}>
      <div className="icon-wrapper n-800">{data.icon}</div>
      <div className="title body-3 n-800">
        {data.subtitle ? data.subtitle : data.label}
      </div>
    </components.ValueContainer>
  );
};

const SettingsGoToStep = ({action, setAction}) => {
  const {evolution, selectedStep, selectedBlockType} =
    useContext(BuilderContext);

  const isSurvey = evolution.type === EVOLUTION_TYPE_SURVEY;
  const isBanner = evolution.type === EVOLUTION_TYPE_BANNER;
  const isTour = evolution.type === EVOLUTION_TYPE_TOUR;
  const isHint = evolution.type === EVOLUTION_TYPE_HINT;
  const isChecklist = evolution.type === EVOLUTION_TYPE_CHECKLIST;

  const isPrimaryCta = selectedBlockType === BLOCK_TYPE_PRIMARY_CTA;
  const isSecondaryCta = selectedBlockType === BLOCK_TYPE_SECONDARY_CTA;

  const steps = isSurvey
    ? evolution.steps.filter((s) => s.removed !== true)
    : evolution.tourSteps
        .map((ts) =>
          ts.steps.map((s) => ({
            ...s,
            tourStep: ts,
            tourStepIndex: ts.tourStepInfo.split(';')[0],
          }))
        )
        .flat()
        .filter((s) => s.removed !== true);

  steps.sort((a, b) => {
    // first sort on tourStepIndex and then on indexOrder
    const tourStepIndexA = a.tourStepIndex;
    const tourStepIndexB = b.tourStepIndex;
    const indexOrderA = a.indexOrder;
    const indexOrderB = b.indexOrder;

    if (tourStepIndexA < tourStepIndexB) {
      return -1;
    }
    if (tourStepIndexA > tourStepIndexB) {
      return 1;
    }
    if (indexOrderA < indexOrderB) {
      return -1;
    }
    if (indexOrderA > indexOrderB) {
      return 1;
    }
    return 0;
  });
  const currentStepIndex = steps.findIndex((s) => s.uid === selectedStep.uid);
  const hasPreviousStep = currentStepIndex > 0;

  const nextStep = steps[currentStepIndex + 1];

  let nextStepLabel = 'Complete tour';
  let nextStepIcon = <i className="isax isax-tick-circle" />;

  if ((isTour || isSurvey) && nextStep != null) {
    nextStepLabel = 'Next step';
    nextStepIcon = <i className="isax isax-arrow-right-2" />;
  } else if (isSurvey) {
    nextStepLabel = 'Finish survey';
  } else if (isBanner) {
    nextStepLabel = 'Close banner';
    nextStepIcon = <i className="isax isax-close-square" />;
  } else if (isHint) {
    nextStepLabel = 'Close hint';
    nextStepIcon = <i className="isax isax-close-square" />;
  } else if (isChecklist) {
    nextStepLabel = 'Close checklist';
    nextStepIcon = <i className="isax isax-close-square" />;
  }

  const stepOptions = [
    ...[
      {
        label: nextStepLabel,
        value: 'next-step',
        category: 'relative',
        icon: nextStepIcon,
      },
      ...(isSecondaryCta && hasPreviousStep
        ? [
            {
              label: 'Previous step',
              value: 'previous-step',
              category: 'relative',
              icon: <i className="isax isax-arrow-left-3" />,
            },
          ]
        : []),
    ],
    ...(isTour || isSurvey
      ? steps.map((s, index) => {
          let icon = null;

          if (s.tourStep != null) {
            const type = getTypeFromBoostFlags(s.tourStep.boostFlags);
            icon = getIcon(type);
          } else {
            const block = BLOCKS.find((b) => b.step === s.type);
            icon = block?.icon;
          }

          return {
            label: s.name,
            value: s.uid,
            category: 'steps',
            subtitle: `Step ${index + 1}`,
            icon,
          };
        })
      : []),
  ].filter((s) => s.value !== selectedStep.uid);

  const groupedStepOptions = [
    {
      label: null,
      options: stepOptions.filter((s) => s.category === 'relative'),
    },
    {
      label: 'Steps',
      options: stepOptions.filter((s) => s.category === 'steps'),
    },
  ];

  return (
    <div className="settings-go-to-step">
      <div className="settings-go-to-step-title body-3">Choose step:</div>
      <SelectGroup
        className="go-to-step-select"
        isSearchable={false}
        options={groupedStepOptions}
        value={stepOptions.find((s) => s.value === action.value)}
        onChange={(selected) => {
          setAction({...action, value: selected.value});
        }}
        components={{
          Option,
          SingleValue,
        }}
      />
    </div>
  );
};

export default SettingsGoToStep;
