import classNames from 'classnames';
import LivePreviewNew from 'components/LivePreviewNew';
import {BuilderContext} from 'contextes/builder';
import {removeFlag} from 'helpers/bitwise';
import {useContext} from 'react';
import {useSelector} from 'react-redux';
import {replaceUIDsInObject} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/templates';
import {generalSelector} from 'selectors';
import {
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_HINT,
  F_BOOST_SLOT_POP_IN,
  F_BOOST_SLOT_SNIPPET,
  F_BOOST_SLOT_TOOLTIP,
} from 'services/evolution';
import {BLOCK_TYPE_HINT, F_STEP_IS_SELECTING_PRESET} from 'services/steps';
import {applyThemeToEvolution} from '../ThemeManager/utils';
import './_Styles.scss';
import StartFromScratch from './images/start-from-scratch.svg';
import {presets as presetsGroups} from './presets';

const Preset = ({experience, stepIndex = 0, title, description}) => {
  const {
    selectedStepId,
    setPreviewedPreset,
    controlledEvolution: evolution,
    setControlledEvolution: setEvolution,
  } = useContext(BuilderContext);
  const {steps} = experience;
  const step = JSON.parse(JSON.stringify(steps[stepIndex]));
  replaceUIDsInObject(step);

  return (
    <div
      className="preset"
      onMouseEnter={() =>
        setPreviewedPreset({experience, stepId: steps[stepIndex].uid})
      }
      onMouseLeave={() => setPreviewedPreset(null)}
      onClick={() => {
        setEvolution({
          ...evolution,
          style: experience.style,
          optionsFlags: experience.optionsFlags,
          boostedPositionFlags: experience.boostedPositionFlags,
          steps: evolution.steps.map((s) => {
            if (s.uid === selectedStepId) {
              return {
                ...s,
                name: step.name,
                blocks: step.blocks,
                stepFlags: removeFlag(F_STEP_IS_SELECTING_PRESET, s.stepFlags),
                style: step.style,
              };
            }
            return s;
          }),
        });
        setPreviewedPreset(null);
      }}>
      <div
        className={classNames('preset-preview', {
          'is-hint': evolution.boostFlags === F_BOOST_SLOT_HINT,
        })}>
        <LivePreviewNew
          poke={experience}
          selectedStepId={steps[stepIndex].uid}
          isScaledDownPreview
          scale={1}
        />
      </div>
      <div className="preset-content">
        <div className="preset-title subtitle-4 n-800">
          {title || step.name}
        </div>
        <div className="preset-description body-4 n-700">{description}</div>
      </div>
    </div>
  );
};

const propTypes = {};
const defaultProps = {};

const PresetSelector = () => {
  const {
    controlledEvolution,
    evolution,
    selectedStepId,
    selectedStep,
    updateStep,
    setPreviewedPreset,
  } = useContext(BuilderContext);

  let typeName = '';
  if (controlledEvolution.boostFlags === F_BOOST_SLOT_POP_IN) {
    typeName = 'Modal';
  } else if (controlledEvolution.boostFlags === F_BOOST_SLOT_SNIPPET) {
    typeName = 'Snippet';
  } else if (controlledEvolution.boostFlags === F_BOOST_SLOT_DOT) {
    typeName = 'Hotspot';
  } else if (controlledEvolution.boostFlags === F_BOOST_SLOT_TOOLTIP) {
    typeName = 'Tooltip';
  } else if (controlledEvolution.boostFlags === F_BOOST_SLOT_HINT) {
    typeName = 'Hint';
  }

  const isHint = controlledEvolution.boostFlags === F_BOOST_SLOT_HINT;

  const sameTypeSteps = evolution.tourSteps.reduce((acc, tourStep) => {
    if (tourStep.boostFlags === controlledEvolution.boostFlags) {
      acc.push(...tourStep.steps);
    }
    return acc;
  }, []);
  const previousStepIndex =
    sameTypeSteps.findIndex((step) => step.uid === selectedStepId) - 1;
  const previousStep = sameTypeSteps[previousStepIndex];
  const previousStepId = previousStep?.uid;
  const previousStepTourStep = evolution.tourSteps.find(
    (tourStep) =>
      tourStep.steps.find((step) => step.uid === previousStepId) != null
  );
  const previousStepTourStepIndex = previousStepTourStep?.steps.findIndex(
    (step) => step.uid === previousStepId
  );

  const project = useSelector((state) => generalSelector.getProject(state));

  const presetsExperiences = presetsGroups
    .filter((p) => p.boostFlags === controlledEvolution.boostFlags)
    .filter((p) => {
      if (isHint) {
        const hintBlock = controlledEvolution.steps[0].blocks.find(
          (b) => b.type === BLOCK_TYPE_HINT
        );
        const presetHintBlock = p.steps[0].blocks.find(
          (b) => b.type === BLOCK_TYPE_HINT
        );
        return hintBlock?.style?.type === presetHintBlock?.style?.type;
      }
      return true;
    });

  let styledExperiences = presetsExperiences;

  if (
    isHint !== true &&
    (evolution.theme != null || project.defaultTheme != null)
  ) {
    styledExperiences = presetsExperiences.map((p) =>
      applyThemeToEvolution(p, evolution.theme || project.defaultTheme)
    );
  }

  return (
    <div className="preset-selector">
      <div className="preset-selector-header">
        <i className="icon-duplicate" />
        <div className="title n-800">{typeName} presets</div>
      </div>
      <div className="presets-wrapper">
        <div
          className="start-from-scratch"
          onMouseEnter={() =>
            setPreviewedPreset({
              experience: controlledEvolution,
              stepId: selectedStepId,
            })
          }
          onMouseLeave={() => setPreviewedPreset(null)}
          onClick={() => {
            updateStep(selectedStepId, {
              stepFlags: removeFlag(
                F_STEP_IS_SELECTING_PRESET,
                selectedStep.stepFlags
              ),
            });
            setPreviewedPreset(null);
          }}>
          <img src={StartFromScratch} alt="start-from-scratch" />
          <div className="icon-wrapper">
            <div className="icon-plus-wrapper">
              <i className="icon-plus" />
            </div>
          </div>
          <div className="title subtitle-3 n-800">Start from scratch</div>
        </div>
        {previousStepTourStep != null && (
          <Preset
            key={previousStepTourStep.uid}
            experience={previousStepTourStep}
            stepIndex={previousStepTourStepIndex}
            title={`Copy of ${previousStep.name || 'previous step'}`}
            description={`Replicate ${
              previousStep.name || 'previous step'
            } content and style`}
          />
        )}
        {styledExperiences?.map((experience) => {
          return experience.steps.map((preset, index) => {
            const experienceWithFilteredSteps = {
              ...experience,
              steps: experience.steps.filter((s) => s.uid === preset.uid),
            };

            return (
              <Preset
                key={preset.title}
                experience={experienceWithFilteredSteps}
                description={preset.description}
              />
            );
          });
        })}
      </div>
    </div>
  );
};

PresetSelector.propTypes = propTypes;
PresetSelector.defaultProps = defaultProps;

export default PresetSelector;
