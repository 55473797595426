import classNames from 'classnames';
import {useState} from 'react';
import {AddConditionDropdown} from 'scenes/SuccessTracker/components/Event/components/EventConditions';
import {
  addAttributeToLogic,
  createCondition,
} from 'scenes/SuccessTracker/components/Event/components/EventConditions/utils';
import {EVENT_CONDITION_TYPE_TOUR_SEEN} from 'services/event';
import ClickableInput from '../../../components/items/ClickableInput';
import ChecklistCompletedConditions from '../../../components/modals/ChecklistCompletedConditions';
import PopupSetting from '../../../components/PopupSetting';
import './_Styles.scss';

const ChecklistConditionsWrapper = ({trigger, setTrigger, title}) => {
  const [newlyAddedCondition, setNewlyAddedCondition] = useState(null);

  const dropdownTrigger = (
    <ClickableInput
      className={classNames('triggers-btn', {
        'with-conditions': trigger?.conditions?.length > 0,
      })}
      leftLabel={<i className="isax isax-hierarchy-3" />}
      inputProps={{
        placeholder: 'Add condition...',
      }}
      value={
        trigger?.conditions?.length > 0
          ? `${trigger?.conditions?.length} condition${
              trigger?.conditions?.length > 1 ? 's' : ''
            }`
          : null
      }
    />
  );

  const handleAddCondition = (type) => {
    const newCondition = createCondition(type);
    const conditionsUpdated = [newCondition];

    const updatedLogic = addAttributeToLogic(
      trigger.logic,
      newCondition,
      trigger.logic.uid
    );

    setTrigger({
      ...trigger,
      conditions: conditionsUpdated,
      logic: updatedLogic,
    });

    setNewlyAddedCondition(newCondition);
  };

  if (trigger?.conditions?.length === 0 && newlyAddedCondition == null) {
    return (
      <AddConditionDropdown
        trigger={dropdownTrigger}
        extraConditionTypes={[EVENT_CONDITION_TYPE_TOUR_SEEN]}
        omitTypes={['logicGroup']}
        position="left top"
        offsetY={-4}
        offsetX={8}
        className="add-event-condition-without-animation"
        handleAddCondition={handleAddCondition}
      />
    );
  }

  return (
    <PopupSetting
      className="popup-setting-checklist-completed"
      trigger={dropdownTrigger}
      title={title}
      content={
        <ChecklistCompletedConditions
          trigger={trigger}
          setTrigger={(trigger) => {
            setTrigger(trigger);
          }}
          newlyAddedCondition={newlyAddedCondition}
        />
      }
      large
      dropdownProps={{
        closeOnDocumentClick: false,
        isOpen: newlyAddedCondition != null,
      }}
    />
  );
};

export default ChecklistConditionsWrapper;
