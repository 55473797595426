import classnames from 'classnames';
import commaNumber from 'comma-number';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import {Modal} from 'components/Modal';
import {GlobalContext} from 'contextes/Global';
import dayjs from 'dayjs';
import {useFetchProration} from 'hooks/useFetchProration';
import {useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {dataSelector, generalSelector} from 'selectors';
import {
  ADDON_WHITE_LABEL_ID,
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_STARTUP_ID,
  displayPrice,
  getCurrentAddons,
  hasAddon,
  hasAnnualBilling,
} from 'services/subscription';
import {Swaler} from 'swaler';
import './_Styles.scss';
import imgLogo from './imgs/logo.svg';

const logger = new Swaler('ModalSubscriptionCheckout');
const cardOptions = {
  hidePostalCode: true,
  showIcon: true,
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

export const ModalPlanUpgrade = () => {
  const {modalPlanUpgrade, setModalPlanUpgrade} = useContext(GlobalContext);
  const {planId, mau, onSubmit} = modalPlanUpgrade;

  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );
  const plans = useSelector((state) =>
    dataSelector.getSubscriptionsPlans(state)
  );
  const [isWorking, setIsWorking] = useState(false);

  const {
    isFetching: isFetchingProration,
    fetch: fetchProration,
    proration,
  } = useFetchProration({
    onError: () => {
      setModalPlanUpgrade(null);
    },
  });

  function handleRequestClose() {
    setModalPlanUpgrade(null);
  }
  async function handleCheckout() {
    setIsWorking(true);
    await onSubmit(planId);
    setIsWorking(false);
  }

  useEffect(() => {
    fetchProration({
      plan: planId,
      mau,
      modeAnnual: hasAnnualBilling(),
      addons: getCurrentAddons(),
    });
  }, []);

  const currentPlan = plans.find((p) => p.uid === subscription.plan);
  const newPlan = plans.find((p) => p.uid === planId);
  const currentPlanUnused =
    proration != null
      ? proration.lines.find((line) => line.amount < 0)?.amount ?? 0
      : 0;
  const newPlanPrice =
    proration != null ? proration.lines.find((line) => line.amount > 0) : null;
  const amountSaved = 0;

  return (
    <>
      <Modal
        animationOnOpen=""
        isOpen={true}
        className="modal-plan-upgrade"
        overlayClassName="modal-plan-upgrade-overlay"
        onRequestClose={handleRequestClose}>
        <Button
          className="btn-modal-close"
          iconOnly
          thin
          onClick={handleRequestClose}>
          <i className="icon-close"></i>
        </Button>
        <div className="left-side-wrapper">
          <div className="left-side">
            <img src={imgLogo} alt="Logo Jimo" width={68} />

            <section className="billing-info">
              {/* Payment Information */}
              <div className="payment-information">
                <div className="body-3 n-500">Payment information</div>
                <div className="body-2">
                  <span className="card-brand">
                    {subscription.paymentMethodBrand}
                  </span>{' '}
                  ending in {subscription.paymentMethodLast4}
                </div>
              </div>
              {/* Billing Information (disabled for now, require to fetch data from Stripe) */}
              {/* <div className="billing-information">
              <div className="body-3 n-500">Billing information</div>
              <div className="body-2">
                {subscription.paymentMethodName}, France, 44000
              </div>
            </div> */}
              {/* Current period End */}
              <div className="current-plan">
                <div className="body-3 n-500">End of current billing cycle</div>
                <div className="body-2">
                  {dayjs(subscription.stripeCurrentPeriodEnd * 1000).format(
                    'MMMM DD, YYYY'
                  )}
                </div>
              </div>
              {/* Current plan */}
              <div className="current-plan">
                <div className="body-3 n-500">Billing Period</div>
                <div className="body-2">
                  {hasAnnualBilling() ? 'Yearly' : 'Monthly'}
                </div>
              </div>
            </section>

            {/* Upgrade button */}
            <Button
              className="btn-upgrade"
              primary
              disabled={isFetchingProration}
              loading={isWorking}
              onClick={() => handleCheckout()}>
              Upgrade to {newPlan.title}
            </Button>

            {/* Saved */}
            {amountSaved > 0 && (
              <div className="amount-saved body-3 n-700">
                You've just saved {displayPrice(amountSaved)}! 🎉
              </div>
            )}
          </div>
        </div>

        <div
          className={classnames('right-side-wrapper', {
            loading: proration == null,
          })}>
          {proration == null ? (
            <DefaultLoader width="18px" />
          ) : (
            <div className="right-side">
              {/* Order summary */}
              <div className="title-4">Order Summary</div>
              <div className="card-order-summary">
                {/* Plan */}
                <div className="summary-plan">
                  <div className="plan-interval-wrapper">
                    <div
                      className={classnames('subtitle-3', {
                        'g-500': planId === PLAN_STARTUP_ID,
                        'o-500': planId === PLAN_GROWTH_ID,
                        'b-500': planId === PLAN_SCALE_ID,
                      })}>
                      {newPlan.title} Plan
                    </div>
                    <div className="body-3 n-700">
                      From now to{' '}
                      {dayjs(newPlanPrice.periodEnd * 1000).format(
                        'DD MMM YYYY'
                      )}{' '}
                      &middot; {commaNumber(mau)} MAU
                    </div>
                    {proration.discount != null && (
                      <div className="body-3 g-500">
                        Your {proration.discount.coupon.percent_off}% discount
                        has been applied
                      </div>
                    )}
                  </div>
                  <div className="subtitle-3">
                    {displayPrice(Math.round(newPlanPrice.amount / 100))}
                  </div>
                </div>

                {/* Addons */}
                {hasAddon(ADDON_WHITE_LABEL_ID) && (
                  <div className="summary-addons">
                    <div className="item-addon">
                      <div className="addon-interval-wrapper">
                        <div className="subtitle-3">+ Hide Jimo Label</div>
                        <div className="body-3 n-700">
                          {newPlan.uid === PLAN_SCALE_ID ? (
                            'Included in your plan'
                          ) : (
                            <>
                              Billed {hasAnnualBilling() ? 'yearly' : 'monthly'}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="subtitle-4 n-500">Already paid</div>
                    </div>
                  </div>
                )}

                <div className="summary-divider"></div>

                {/* Unused plan */}
                {currentPlanUnused != null && (
                  <div className="summary-plan-unused">
                    <div className="plan-interval-wrapper">
                      <div
                        className={classnames(
                          'plan-logo-wrapper',
                          'subtitle-3'
                        )}>
                        Unused time on{' '}
                        <span
                          className={classnames('plan-title', {
                            'g-500': currentPlan.uid === PLAN_STARTUP_ID,
                            'o-500': currentPlan.uid === PLAN_GROWTH_ID,
                            'b-500': currentPlan.uid === PLAN_SCALE_ID,
                          })}>
                          {currentPlan.title} Plan
                        </span>
                      </div>
                      <div className="body-3 n-700">
                        From{' '}
                        {dayjs(proration.periodStart * 1000).format(
                          'DD MMM YYYY'
                        )}{' '}
                        to now
                      </div>
                    </div>
                    <div className="subtitle-3 g-400">
                      -
                      {displayPrice(
                        Math.round(Math.abs(currentPlanUnused / 100))
                      )}
                    </div>
                  </div>
                )}

                <div className="summary-divider"></div>

                {/* Taxes */}
                {proration != null && proration.tax != null && (
                  <>
                    <div className="summary-tax">
                      <div className="tax-interval-wrapper">
                        <div className="subtitle-3">Tax</div>
                      </div>
                      <div className="subtitle-3">
                        {displayPrice(Math.round(proration.tax / 100))}
                      </div>
                    </div>
                    <div className="summary-divider"></div>
                  </>
                )}

                {/* Total */}
                <div className="summary-total">
                  <div className="subtitle-3">Total for today</div>
                  <div className="subtitle-2">
                    {proration != null &&
                      displayPrice(Math.round(proration.total / 100))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
