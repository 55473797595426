import {GlobalContext} from 'contextes/Global';
import {useContext} from 'react';

export const usePlanPreview = () => {
  const {setModalPlanPreview} = useContext(GlobalContext);

  function preview({planId, mau, title, description, onClose}) {
    setModalPlanPreview({planId, mau, title, description, onClose});
  }

  return {
    preview,
  };
};
