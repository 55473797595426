import SelectGroup from 'components/Select';
import {errorHelpers} from 'helpers';
import React from 'react';
import {useQuery} from 'react-query';
import {evolutionService} from 'services';
import {EVOLUTION_STATE_LIVE, EVOLUTION_TYPE_TOUR} from 'services/evolution';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('SettingsTourSeen');

const SettingsTourSeen = ({condition, setCondition}) => {
  const {value} = condition;

  const {data: experiences = [], isLoading} = useQuery({
    queryKey: 'experiences',
    queryFn: async () => {
      try {
        const experiences = await evolutionService.getEvolutions({
          relations: [],
          onlyPokes: true,
          type: EVOLUTION_TYPE_TOUR,
          states: [EVOLUTION_STATE_LIVE],
          removeDrafts: true,
        });

        return experiences;
      } catch (err) {
        const {code} = errorHelpers.parseError(err);

        logger.error('Fetch experiences failed with error ', code);
      }
    },
  });

  const experiencesOptions = experiences?.map((e) => ({
    label: e.title,
    value: e.uid,
    evolution: e,
  }));

  return (
    <div className="settings-tour-seen">
      <div className="section-item">
        <div className="settings-launch-experience-title body-3">Tour:</div>
        <SelectGroup
          options={experiencesOptions}
          value={experiencesOptions?.find((e) => e.value === value)}
          onChange={(selected) =>
            setCondition({...condition, value: selected.value})
          }
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default SettingsTourSeen;
