import {
  CheckBox as CheckBoxOutline,
  Star,
  StarOutline,
  Verified as VerifiedOutline,
} from '@styled-icons/material-outlined';
import {
  Check,
  CheckBox,
  CheckCircle,
  CheckCircleOutline,
  DoneAll,
  DoneOutline,
  Favorite,
  FavoriteBorder,
  TaskAlt,
  Verified,
} from '@styled-icons/material-rounded';
import classNames from 'classnames';
import React, {useContext} from 'react';
import ClickableBlockOverlay from '../../../../../Poke/components/ClickableBlockOverlay';
import {BLOCK_CHECKLIST_TASK_CHECKBOX} from '../../../../../Poke/constants/blocks';
import {PokeStateContext} from '../../../../../Poke/context';
import './_Styles.scss';

export const iconsList = [
  {icon: <Check />, label: 'Mark', value: 'simple-check'},
  {icon: <DoneAll />, label: 'Double', value: 'double-check'},
  {icon: <DoneOutline />, label: 'Outline', value: 'outlined-check'},
  {icon: <TaskAlt />, label: 'Task Circle', value: 'task-circle'},
  {icon: <CheckBoxOutline />, label: 'Check Box', value: 'check-box'},
  {icon: <CheckCircleOutline />, label: 'Check Circle', value: 'check-circle'},
  {icon: <VerifiedOutline />, label: 'Badge (outline)', value: 'badge-outline'},
  {icon: <StarOutline />, label: 'Star', value: 'star'},
  {icon: <FavoriteBorder />, label: 'Favorite', value: 'favorite'},
  {icon: <CheckBox />, label: 'Check Box (filled)', value: 'check-box-filled'},
  {icon: <CheckCircle />, label: 'Check Circle', value: 'check-circle'},
  {icon: <Verified />, label: 'Badge (filled)', value: 'badge'},
  {icon: <Star />, label: 'Star (filled)', value: 'star-filled'},
  {icon: <Favorite />, label: 'Favorite (filled)', value: 'favorite-filled'},
];

const ICON_BUILT_IN = 'built-in';

const Checkbox = ({block, completed}) => {
  const {
    onBlockSelected,
    onTaskSelected,
    selectedTaskId,
    selectedBlock,
    inBuilder,
  } = useContext(PokeStateContext);

  const {style} = block;
  const {
    incompleteBackgroundColor,
    incompleteBorderColor,
    completedBackgroundColor,
    completedBorderColor,
    iconColor,
    borderStyle,
    ...restStyles
  } = style;

  const selectedIcon = iconsList.find((i) => i.value === style.iconName);

  return (
    <div
      className={classNames('checkbox', {
        'poke-block-clickable': inBuilder === true,
        completed: completed,
        selected:
          selectedBlock === BLOCK_CHECKLIST_TASK_CHECKBOX &&
          selectedTaskId === block.parentBlockId,
      })}
      style={{
        ...restStyles,
        backgroundColor:
          completed === true
            ? completedBackgroundColor
            : incompleteBackgroundColor,
        borderColor:
          completed === true ? completedBorderColor : incompleteBorderColor,
        borderStyle: completed === true ? 'solid' : borderStyle,
      }}
      onClick={(e) => {
        if (inBuilder === true) {
          e.stopPropagation();
          onBlockSelected(BLOCK_CHECKLIST_TASK_CHECKBOX);
          onTaskSelected(block.parentBlockId);
        }
      }}>
      {completed && (
        <span className="completed-icon" style={{color: iconColor}}>
          {style.iconSource === ICON_BUILT_IN ? (
            selectedIcon?.icon
          ) : style.iconUrl ? (
            <img src={style.iconUrl} alt="" />
          ) : (
            <></>
          )}
        </span>
      )}

      <ClickableBlockOverlay />
    </div>
  );
};

export default Checkbox;
