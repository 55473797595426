import classnames from 'classnames';
import {GlobalContext} from 'contextes/Global';
import {hasFlag} from 'helpers/bitwise';
import {bool, func, object, string} from 'prop-types';
import {useContext, useEffect, useRef, useState} from 'react';
import {Dot} from 'scenes/PokeBuilder/components/PokeRender';
import {BlockGradient} from 'scenes/PokeBuilder/components/PokeRender/components/BlockGradient';
import {getPokeWrapperStyle} from 'scenes/PokeBuilder/components/PokeRender/utils';
import {BLOCK_TYPE_HOTSPOT, STEP_TYPE_CHECKLIST} from 'services/steps';
import Checklist from 'shared/front/components/Checklist';
import {Poke} from 'shared/front/components/Poke';
import ClickableBlockOverlay from 'shared/front/components/Poke/components/ClickableBlockOverlay';
import {
  F_SLOT_DOT,
  F_SLOT_NAVIGATION,
  F_SLOT_SNIPPET,
  F_SLOT_TOP_BAR,
} from 'shared/front/components/Poke/constants/poke';
import {PokeContext} from 'shared/front/components/Poke/context';
import './_Styles.scss';

const propTypes = {
  poke: object,
  setPreviewPoke: func,
  selectedBlockType: string,
  setSelectedBlockType: func,
  disableCardClickableOverlay: bool,
  reRenderOnShapeChange: bool,
  isChecklist: bool,
};

const defaultProps = {
  poke: {},
  setPreviewPoke: () => {},
  selectedBlockType: null,
  setSelectedBlockType: () => {},
  disableCardClickableOverlay: false,
  reRenderOnShapeChange: false,
  isChecklist: false,
};

const ThemePreview = ({
  poke,
  selectedBlockType,
  setSelectedBlockType,
  disableCardClickableOverlay,
  reRenderOnShapeChange,
  isChecklist,
}) => {
  const {addFontFamily} = useContext(GlobalContext);

  const [dimension, setDimension] = useState({
    width: 0,
    height: 0,
  });
  const [style, setStyle] = useState({});

  const pokeRef = useRef();

  const currentStepIndex = 0;

  useEffect(() => {
    if (pokeRef.current != null) {
      const {width, height} = pokeRef.current.getBoundingClientRect();
      setDimension({width, height});
    }
  }, [pokeRef?.current?.scrollHeight, pokeRef?.current?.scrollWidth]);

  const isNavigationStep = hasFlag(F_SLOT_NAVIGATION, poke.boostFlags);

  if (isNavigationStep) {
    return <></>;
  }

  if (
    isChecklist === true &&
    poke.steps.some((s) => s.type === STEP_TYPE_CHECKLIST) !== true
  ) {
    return <></>;
  }

  const isSnippet = hasFlag(F_SLOT_SNIPPET, poke.boostFlags);
  const isBanner = hasFlag(F_SLOT_TOP_BAR, poke.boostFlags);
  const isHotspot = hasFlag(F_SLOT_DOT, poke.boostFlags);

  const pokeWrapperStyle = isChecklist ? {} : getPokeWrapperStyle(poke);

  return (
    <>
      <div className="theme-preview">
        <PokeContext.Provider
          value={{
            poke: {
              ...poke,
              steps: poke?.steps?.sort((a, b) => a.inderOrder - b.indexOrder),
            },
            currentStepIndex,
          }}>
          <div
            className="poke-render-overlay"
            style={{backgroundColor: poke?.style?.overlay}}
          />
          <div
            className={classnames('poke-wrapper', {
              selected: selectedBlockType == null,
              'is-snippet': isSnippet,
              'is-checklist': isChecklist,
            })}
            style={{
              ...(isBanner !== true ? {} : {width: '100%'}),
            }}>
            <div
              className={classnames('poke-render')}
              style={{
                ...style,
                width: isBanner === true ? null : 'auto',
                height: 'auto',
                position: 'relative',
                ...pokeWrapperStyle,
              }}>
              {isChecklist === true ? (
                <Checklist
                  inBuilder
                  onDimensionChange={(data) => {
                    setDimension({
                      width: data[0],
                      height: data[1],
                    });
                  }}
                  addFontFamily={addFontFamily}
                  selectedBlock={selectedBlockType}
                  onBlockSelected={(type) => {
                    setSelectedBlockType(type);
                  }}
                />
              ) : (
                <>
                  <Poke
                    forwardRef={pokeRef}
                    key={reRenderOnShapeChange === true ? poke.boostFlags : ''}
                    inBuilder
                    onDimensionChange={([width, height]) => {
                      if (
                        width !== dimension.width ||
                        height !== dimension.height
                      ) {
                        setDimension({
                          width,
                          height,
                        });
                      }
                    }}
                    onContainerStyleChange={(style) => {
                      setStyle(style);
                    }}
                    onBlockSelected={(type) => {
                      setSelectedBlockType(type);
                    }}
                    selectedBlock={selectedBlockType}
                    addFontFamily={addFontFamily}
                  />
                  <BlockGradient poke={poke} />
                </>
              )}
            </div>
            {disableCardClickableOverlay === false && (
              <ClickableBlockOverlay isCard />
            )}
          </div>
          {isHotspot === true && isChecklist !== true && (
            <Dot
              poke={poke}
              dimension={dimension}
              onClick={() => setSelectedBlockType(BLOCK_TYPE_HOTSPOT)}
            />
          )}
        </PokeContext.Provider>
      </div>
    </>
  );
};

ThemePreview.propTypes = propTypes;
ThemePreview.defaultProps = defaultProps;

export default ThemePreview;
