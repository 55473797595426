import classNames from 'classnames';
import Button from 'components/Button';
import {bool} from 'prop-types';
import React from 'react';
import {
  SECTION_GOAL,
  SECTION_TRIGGER,
  SECTION_WHEN,
  SECTION_WHERE,
  SECTION_WHO,
} from 'scenes/PokeAudience';
import {getGoalTitle} from 'scenes/PokeAudience/sections/Goal';
import {getTriggerTitle} from 'scenes/PokeAudience/sections/Trigger';
import {getWhenTitle} from 'scenes/PokeAudience/sections/When';
import {getWhereTitle} from 'scenes/PokeAudience/sections/Where';
import {getWhoTitle} from 'scenes/PokeAudience/sections/Who';
import {
  SECTION_WHEN as SECTION_WHEN_POST,
  SECTION_WHO as SECTION_WHO_POST,
} from 'scenes/PostAudience';
import {EVOLUTION_TYPE_HINT, TRIGGER_TYPE_URL} from 'services/evolution';
import './_Styles.scss';

const SummaryInfoSection = ({name, icon, value, onEdit}) => {
  return (
    <div className="section">
      <div className="section-name body-3 n-800">{name}</div>
      <div
        className={classNames('section-value-wrapper', {
          clickable: onEdit != null,
        })}
        onClick={onEdit != null ? () => onEdit() : null}>
        <div className="section-value-icon n-600">
          <i className={icon} />
        </div>
        <div className="section-value">{value}</div>
        {onEdit != null && (
          <Button className="edit-btn" iconLeft="isax isax-edit-2">
            Edit
          </Button>
        )}
      </div>
    </div>
  );
};

const propTypes = {
  isPost: bool,
};

const defaultProps = {
  isPost: false,
};

const SummaryInfos = ({
  isPost,
  evolution,
  setSelectedSection,
  jimersCount,
  sections,
  setDisableAnimation = () => {},
}) => {
  const isHint = evolution?.type === EVOLUTION_TYPE_HINT;
  const isDraft = evolution?.isDraft === true;

  const availableSections = sections.map((s) => s.section);

  const temporarilyDisableAnimation = () => {
    setDisableAnimation(true);
    setTimeout(() => setDisableAnimation(false), 100);
  };

  return (
    <div
      className={classNames('summary-infos', {
        'is-post': isPost === true,
      })}>
      {isPost !== true && (
        <>
          {availableSections.includes(SECTION_TRIGGER) && (
            <SummaryInfoSection
              name="Show on"
              icon="isax isax-eye"
              value={getTriggerTitle(evolution)}
              onEdit={
                setSelectedSection != null
                  ? () => {
                      setSelectedSection(SECTION_TRIGGER);
                      temporarilyDisableAnimation();
                    }
                  : null
              }
            />
          )}
          {availableSections.includes(SECTION_WHERE) && (
            <SummaryInfoSection
              name="Where"
              icon="isax isax-global4"
              value={getWhereTitle(evolution)}
              onEdit={
                setSelectedSection != null
                  ? () => {
                      setSelectedSection(SECTION_WHERE);
                      temporarilyDisableAnimation();
                    }
                  : null
              }
            />
          )}
        </>
      )}
      {evolution.triggerType !== TRIGGER_TYPE_URL && (
        <>
          {availableSections.includes(
            isPost ? SECTION_WHO_POST : SECTION_WHO
          ) && (
            <SummaryInfoSection
              name="Who"
              icon="isax isax-profile-circle"
              value={getWhoTitle(evolution, jimersCount)}
              onEdit={
                setSelectedSection != null
                  ? () => {
                      setSelectedSection(
                        isPost === true ? SECTION_WHO_POST : SECTION_WHO
                      );
                      temporarilyDisableAnimation();
                    }
                  : null
              }
            />
          )}
          {availableSections.includes(
            isPost ? SECTION_WHEN_POST : SECTION_WHEN
          ) && (
            <SummaryInfoSection
              name="When"
              icon="isax isax-calendar-1"
              value={getWhenTitle(evolution, {isPost, isHint, isDraft})}
              onEdit={
                setSelectedSection != null
                  ? () => {
                      setSelectedSection(
                        isPost === true ? SECTION_WHEN_POST : SECTION_WHEN
                      );
                      temporarilyDisableAnimation();
                    }
                  : null
              }
            />
          )}
        </>
      )}
      {availableSections.includes(SECTION_GOAL) && (
        <SummaryInfoSection
          name="Goal"
          icon="isax isax-medal"
          value={getGoalTitle(evolution)}
          onEdit={
            setSelectedSection != null
              ? () => {
                  setSelectedSection(SECTION_GOAL);
                  temporarilyDisableAnimation();
                }
              : null
          }
        />
      )}
    </div>
  );
};

SummaryInfos.propTypes = propTypes;
SummaryInfos.defaultProps = defaultProps;

export default SummaryInfos;
