import {number} from 'prop-types';
import React, {useContext} from 'react';
import {AudienceContext} from 'scenes/PokeAudience';
import AudienceSection from 'scenes/PokeAudience/components/AudienceSection';
import SummaryInfos from '../Summary/components/SummaryInfos';
import './_Styles.scss';

const propTypes = {
  index: number.isRequired,
};

const defaultProps = {};

const PostSummary = ({index}) => {
  const {evolution, setSelectedSection, jimersCount, sections} =
    useContext(AudienceContext);

  return (
    <AudienceSection
      className="post-summary"
      index={index}
      title="Summary"
      titleOnly
      alwaysExpanded>
      <SummaryInfos
        isPost
        evolution={evolution}
        setSelectedSection={setSelectedSection}
        jimersCount={jimersCount}
        sections={sections}
      />
    </AudienceSection>
  );
};

PostSummary.propTypes = propTypes;
PostSummary.defaultProps = defaultProps;

export default PostSummary;
