import classnames from 'classnames';
import Alert from 'components/Alert';
import Block from 'components/Block';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import {bool, func, object, string} from 'prop-types';
import {useContext, useRef, useState} from 'react';
import {
  CATEGORY_ACTIVITY,
  CATEGORY_ATTRIBUTES,
  CATEGORY_CIO_EVENTS,
  CATEGORY_CUSTOM_ATTRIBUTES,
  CATEGORY_SEGMENTIO_EVENTS,
  CATEGORY_SEGMENTIO_FIELDS,
  CATEGORY_TRACKED_EVENTS,
} from 'services/segment';
import {SegmentAttributesEditorContext} from '.';

export const ATTRIBUTES = {
  ATTR_NAME: 'NAME',
  ATTR_EMAIL: 'EMAIL',
  ATTR_ACTIVE: 'ACTIVE',
  ATTR_LAST_ACTIVITY: 'LAST_ACTIVITY_AT',
  ATTR_CREATED: 'CREATED',
  ATTR_CUSTOM: 'CUSTOM',
  ATTR_SESSIONS: 'SESSIONS',
};
export const JIMER_ACTIVITY_ATTRIBUTES = {
  ENGAGEMENT_RATE: 'ENGAGEMENT_RATE',
  NUMBER_INTERACTED_PUSH: 'NUMBER_INTERACTED_PUSH',
  INTERACTED_WITH: 'INTERACTED_WITH',
  NINTERACTED_WITH: 'NINTERACTED_WITH',
  VOTED: 'VOTED',
  NVOTED: 'NVOTED',
  COMMENTED: 'COMMENTED',
  NCOMMENTED: 'NCOMMENTED',
  SAW: 'SAW',
  NSAW: 'NSAW',
  SURVEY_EXITED: 'SURVEY_EXITED',
  SURVEY_COMPLETED: 'SURVEY_COMPLETED',
  SURVEY_NCOMPLETED: 'SURVEY_NCOMPLETED',
};

export const CATEGORY_EXISTING_SEGMENT = 'Your segments';

const propTypes = {
  onChange: func.isRequired,
  value: string,
  dropdownProps: object,
  onSelectExistingSegment: func,
  hideAttributes: bool,
  addButtonLabel: string,
};

const defaultProps = {
  value: null,
  dropdownProps: {},
  onSelectExistingSegment: () => {},
  hideAttributes: false,
  addButtonLabel: null,
};

const SelectAttribute = ({
  onChange,
  onSelectExistingSegment,
  value,
  dropdownProps,
  hideAttributes,
  addButtonLabel,
}) => {
  const {BLOCKS} = useContext(SegmentAttributesEditorContext);

  const [search, setSearch] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const refDropdownAdd = useRef();

  const closeDropdown = () => setDropdownOpen(false);

  const handleChange = (value, opts = null) => {
    onChange(value, opts);
  };

  const {
    className: dropdownPropClassName,
    segmentOnTheFlyDisabled,
    existingSegmentDisabled,
    ...dropdownPropsRest
  } = dropdownProps;

  const getFilteredBlocks = (search = '') => {
    return search.length === 0
      ? BLOCKS
      : BLOCKS.filter((b) =>
          b.title?.toLowerCase()?.includes(search.toLowerCase())
        );
  };

  const selectedBlock = BLOCKS.find((b) => b.value === value);

  if (selectedBlock != null) {
    return (
      <div className="segment-filter-type">
        <div
          className="icon-wrapper"
          style={
            selectedBlock.iconBackgroundColor != null
              ? {backgroundColor: selectedBlock.iconBackgroundColor}
              : null
          }>
          {selectedBlock.icon}
        </div>
        <div className="content">
          <div className="title">{selectedBlock.title}</div>
        </div>
      </div>
    );
  }

  const blocksCategoryAttributes = getFilteredBlocks(search).filter(
    (b) => b.category === CATEGORY_ATTRIBUTES
  );
  const blocksCategoryActivity = getFilteredBlocks(search).filter(
    (b) => b.category === CATEGORY_ACTIVITY
  );
  const blocksCategorySegmentioFields = getFilteredBlocks(search).filter(
    (b) => b.category === CATEGORY_SEGMENTIO_FIELDS
  );
  const blocksCategorySegmentioEvents = getFilteredBlocks(search).filter(
    (b) => b.category === CATEGORY_SEGMENTIO_EVENTS
  );
  const blocksCategoryTrackedEvents = getFilteredBlocks(search).filter(
    (b) => b.category === CATEGORY_TRACKED_EVENTS
  );
  const blocksExistingSegments = getFilteredBlocks(search).filter(
    (b) => b.category === CATEGORY_EXISTING_SEGMENT
  );
  const blocksCategoryCustomAttributes = getFilteredBlocks(search).filter(
    (b) => b.category === CATEGORY_CUSTOM_ATTRIBUTES
  );
  const blocksCategoryCustomerio = getFilteredBlocks(search).filter(
    (b) => b.category === CATEGORY_CIO_EVENTS
  );

  return (
    <Dropdown
      open={dropdownOpen}
      onClose={() => {
        closeDropdown();
        setSearch('');
      }}
      innerRef={refDropdownAdd}
      className={classnames(
        'dropdown-add-segment-filter',
        dropdownPropClassName
      )}
      modal
      {...dropdownPropsRest}>
      <div className="search-wrapper">
        <Input
          labelTextLeft={<i className="icon-search"></i>}
          placeholder="Search for a filter"
          muted
          value={search}
          onChange={({target}) => setSearch(target.value)}></Input>
      </div>
      <div className="block-categories">
        {blocksExistingSegments.length > 0 && (
          <>
            {existingSegmentDisabled === true && (
              <Alert warning>
                If you want to use existing segments, you have to remove all
                attributes used in your segment on the fly.
              </Alert>
            )}
            <div className="category-title">{CATEGORY_EXISTING_SEGMENT}</div>
            <div className="items">
              {blocksExistingSegments.map((s) => (
                <Block
                  disabled={existingSegmentDisabled}
                  muted
                  iconBackgroundColor="#F0F2F4"
                  title={s.name}
                  icon={<i className="icon-duplicate" />}
                  onClick={() => {
                    if (existingSegmentDisabled === true) {
                      return;
                    }
                    onSelectExistingSegment(s);
                    closeDropdown();
                  }}
                />
              ))}
            </div>
            {hideAttributes !== true && <Divider />}
          </>
        )}
        {hideAttributes !== true && (
          <>
            {segmentOnTheFlyDisabled === true && (
              <Alert warning>
                If you want to create a segment on the fly, you have to unselect
                the existing segments used.
              </Alert>
            )}
            {blocksCategoryAttributes.length > 0 && (
              <>
                <div className="category-title">Attributes</div>
                <div className="items">
                  {blocksCategoryAttributes.map((b, i) => (
                    <Block
                      key={i}
                      disabled={segmentOnTheFlyDisabled}
                      muted
                      iconBackgroundColor={b.iconBackgroundColor}
                      title={b.title}
                      description={b.description}
                      icon={b.icon}
                      onClick={() => {
                        if (segmentOnTheFlyDisabled === true) {
                          return;
                        }
                        handleChange(b.value);
                        closeDropdown();
                      }}
                    />
                  ))}
                </div>
              </>
            )}
            {blocksCategoryCustomAttributes.length > 0 && (
              <>
                <div className="category-title">Custom Attributes</div>
                <div className="items">
                  {blocksCategoryCustomAttributes.map((b, i) => (
                    <Block
                      key={i}
                      disabled={segmentOnTheFlyDisabled}
                      muted
                      iconBackgroundColor={b.iconBackgroundColor}
                      title={b.title}
                      description={b.description}
                      icon={b.icon}
                      onClick={() => {
                        if (segmentOnTheFlyDisabled === true) {
                          return;
                        }
                        handleChange(b.value, {
                          customAttributeId: b.customAttributeId,
                        });
                        closeDropdown();
                      }}
                    />
                  ))}
                </div>
              </>
            )}
            {blocksCategoryActivity.length > 0 && (
              <>
                <div className="category-title">Experiences</div>
                <div className="items">
                  {blocksCategoryActivity.map((b, i) => (
                    <Block
                      key={i}
                      disabled={segmentOnTheFlyDisabled}
                      muted
                      iconBackgroundColor={b.iconBackgroundColor}
                      title={b.title}
                      description={b.description}
                      icon={b.icon}
                      onClick={() => {
                        if (segmentOnTheFlyDisabled === true) {
                          return;
                        }
                        handleChange(b.value);
                        closeDropdown();
                      }}
                    />
                  ))}
                </div>
              </>
            )}
            {blocksCategoryTrackedEvents.length > 0 && (
              <>
                <div className="category-title">Events</div>
                <div className="items">
                  {blocksCategoryTrackedEvents.map((b) => (
                    <Block
                      disabled={segmentOnTheFlyDisabled}
                      muted
                      iconBackgroundColor={b.iconBackgroundColor}
                      title={b.title}
                      description={b.description}
                      icon={b.icon}
                      onClick={() => {
                        if (segmentOnTheFlyDisabled === true) {
                          return;
                        }
                        handleChange(b.value, {eventId: b.eventId});
                        closeDropdown();
                      }}
                    />
                  ))}
                </div>
              </>
            )}
            {blocksCategorySegmentioFields.length > 0 && (
              <>
                <div className="category-title">Segment.io Traits</div>
                <div className="items">
                  {blocksCategorySegmentioFields.map((b, i) => (
                    <Block
                      key={i}
                      disabled={segmentOnTheFlyDisabled}
                      muted
                      iconBackgroundColor={b.iconBackgroundColor}
                      title={b.title}
                      description={b.description}
                      icon={b.icon}
                      onClick={() => {
                        if (segmentOnTheFlyDisabled === true) {
                          return;
                        }
                        handleChange(b.value);
                        closeDropdown();
                      }}
                    />
                  ))}
                </div>
              </>
            )}
            {blocksCategorySegmentioEvents.length > 0 && (
              <>
                <div className="category-title">Segment.io Events</div>
                <div className="items">
                  {blocksCategorySegmentioEvents.map((b, i) => (
                    <Block
                      key={i}
                      disabled={segmentOnTheFlyDisabled}
                      muted
                      iconBackgroundColor={b.iconBackgroundColor}
                      title={b.title}
                      description={b.description}
                      icon={b.icon}
                      onClick={() => {
                        if (segmentOnTheFlyDisabled === true) {
                          return;
                        }
                        handleChange(b.value);
                        closeDropdown();
                      }}
                    />
                  ))}
                </div>
              </>
            )}
            {blocksCategoryCustomerio.length > 0 && (
              <>
                <div className="category-title">Customer.io events</div>
                <div className="items">
                  {blocksCategoryCustomerio.map((b, i) => (
                    <Block
                      key={i}
                      disabled={segmentOnTheFlyDisabled}
                      muted
                      iconBackgroundColor={b.iconBackgroundColor}
                      title={b.title}
                      description={b.description}
                      icon={b.icon}
                      onClick={() => {
                        if (segmentOnTheFlyDisabled === true) {
                          return;
                        }
                        handleChange(b.value);
                        closeDropdown();
                      }}
                    />
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Dropdown>
  );
};

SelectAttribute.propTypes = propTypes;
SelectAttribute.defaultProps = defaultProps;

export default SelectAttribute;
