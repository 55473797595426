import {
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_SURVEY,
} from 'services/evolution';
import {
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHECKLIST_DISMISS,
  BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_NPS,
  BLOCK_TYPE_OPEN_QUESTION,
  BLOCK_TYPE_OPINION,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_SLIDER,
  BLOCK_TYPE_TITLE,
} from 'services/steps';
import {slateToMarkdown} from '../../../../components/MarkdownEditor/utils';

export const getTextToTranslate = (evolution) => {
  const isSurvey = evolution.type === EVOLUTION_TYPE_SURVEY;
  const isChecklist = evolution.type === EVOLUTION_TYPE_CHECKLIST;

  const textToTranslate = {};

  if (isSurvey || isChecklist) {
    for (const step of evolution.steps) {
      for (const block of step.blocks) {
        const text = getTextToTranslateFromBlock(block);
        if (text != null) {
          if (textToTranslate[step.uid] == null) {
            textToTranslate[step.uid] = {};
          }
          textToTranslate[step.uid][block.uid] = text;
        }
      }
    }
  } else {
    for (const tourStep of evolution.tourSteps) {
      for (const step of tourStep.steps) {
        textToTranslate[step.uid] = null;
        for (const block of step.blocks) {
          const text = getTextToTranslateFromBlock(block);
          if (text != null) {
            if (textToTranslate[step.uid] == null) {
              textToTranslate[step.uid] = {};
            }
            textToTranslate[step.uid][block.uid] = text;
          }
        }
      }
    }
  }

  return textToTranslate;
};

const getTextToTranslateFromBlock = (block) => {
  if (block.type === BLOCK_TYPE_TITLE) {
    const {value, rawValue} = block;
    const [text] = value.split('|-|');

    if (
      rawValue &&
      value.replaceAll('<br>', '').replaceAll('\n', '').trim().length !== 0
    ) {
      const rawValueText = getTextPropertiesFromSlateObject(rawValue);
      return `${rawValueText}`;
    } else if (text) {
      return text;
    }
  }
  if (block.type === BLOCK_TYPE_BODY) {
    const {value, rawValue} = block;

    if (value.replaceAll('<br>', '').replaceAll('\n', '').trim().length !== 0) {
      const rawValueText = getTextPropertiesFromSlateObject(rawValue);
      return `${rawValueText}`;
    }
  }
  if ([BLOCK_TYPE_PRIMARY_CTA, BLOCK_TYPE_SECONDARY_CTA].includes(block.type)) {
    const [text] = block.value.split(';');
    if (text) {
      return text;
    }
  }
  if (block.type === BLOCK_TYPE_LABEL) {
    const text = block.value;
    if (text) {
      return text;
    }
  }
  if (block.type === BLOCK_TYPE_CHOICE) {
    const {options} = block;
    const text = options
      .map((option) => option.content)
      .filter((t) => t)
      .join('|-|');
    if (text) {
      return text;
    }
  }
  if (block.type === BLOCK_TYPE_SLIDER) {
    const {value} = block;
    const [type, customEmoji, labelLeft, labelRight] = value.split(';');
    const text = [labelLeft, labelRight].filter((t) => t).join('|-|');
    if (text) {
      return text;
    }
  }
  if (block.type === BLOCK_TYPE_NPS) {
    const {value} = block;
    const [labelLeft, labelRight] = value.split(';');
    const text = [labelLeft, labelRight].filter((t) => t).join('|-|');
    if (text) {
      return text;
    }
  }
  if (block.type === BLOCK_TYPE_OPINION) {
    const {value} = block;
    const [type, number, labelLeft, labelRight] = value.split(';');
    const text = [labelLeft, labelRight].filter((t) => t).join('|-|');
    if (text) {
      return text;
    }
  }
  if (block.type === BLOCK_TYPE_OPEN_QUESTION) {
    const text = block.value;
    if (text) {
      return text;
    }
  }

  if (
    [
      BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
      BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
      BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
      BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
    ].includes(block.type)
  ) {
    const {value, rawValue} = block;

    if (value.replaceAll('<br>', '').replaceAll('\n', '').trim().length !== 0) {
      const rawValueText = getTextPropertiesFromSlateObject(rawValue);
      return `${rawValueText}`;
    }
  }
  if (
    [
      BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
      BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
    ].includes(block.type)
  ) {
    const [text] = block.value.split(';');
    if (text) {
      return text;
    }
  }
  if (block.type === BLOCK_TYPE_CHECKLIST_DISMISS) {
    const text = block.value;
    if (text) {
      return text;
    }
  }
  return null;
};

const getTextPropertiesFromSlateObject = (slateObjects) => {
  const texts = [];
  for (const slateObject of slateObjects) {
    const {text, children} = slateObject;
    if (text && text.length > 0) {
      texts.push(text);
    }
    if (children) {
      texts.push(getTextPropertiesFromSlateObject(children));
    }
  }
  return texts.join('|-|');
};

export const setTranslatedTextToEvolution = (
  language,
  evolution,
  translations
) => {
  const isSurvey = evolution.type === EVOLUTION_TYPE_SURVEY;
  const isChecklist = evolution.type === EVOLUTION_TYPE_CHECKLIST;

  const translatedEvolution = {...evolution};

  if (isSurvey || isChecklist) {
    for (const step of translatedEvolution.steps) {
      for (const block of step.blocks) {
        const text = translations[step.uid][block.uid]?.split('|-|');
        try {
          setTranslatedTextToBlock(language, block, text);
        } catch (err) {
          console.log(err);
        }
      }
    }
  } else {
    for (const tourStep of translatedEvolution.tourSteps) {
      for (const step of tourStep.steps) {
        for (const block of step.blocks) {
          const text = translations[step.uid][block.uid]?.split('|-|');
          try {
            setTranslatedTextToBlock(language, block, text);
          } catch (err) {
            console.log(err);
          }
        }
      }
    }
  }

  return translatedEvolution;
};

const setTranslatedTextToBlock = (language, block, translations) => {
  const hasLanguage =
    block.translations?.find((t) => t.language === language) != null;
  if (block.type === BLOCK_TYPE_TITLE) {
    const {value, rawValue} = block;
    const [text] = value.split('|-|');

    if (
      rawValue &&
      text.replaceAll('<br>', '').replaceAll('\n', '').trim().length !== 0
    ) {
      const translatedRawValue = setTranslatedPropertiesToSlateObject(
        rawValue,
        translations
      );
      const markdown = slateToMarkdown(translatedRawValue);
      block.translations = hasLanguage
        ? block.translations.map((t) => {
            if (t.language === language) {
              return {
                ...t,
                value: markdown,
                rawValue: translatedRawValue,
              };
            } else {
              return t;
            }
          })
        : [
            ...(block.translations || []),
            {language: language, value: markdown, rawValue: translatedRawValue},
          ];
    } else if (text) {
      block.translations = hasLanguage
        ? block.translations.map((t) => {
            if (t.language === language) {
              return {
                ...t,
                value: translations.shift(),
              };
            } else {
              return t;
            }
          })
        : [
            ...(block.translations || []),
            {language: language, value: translations.shift()},
          ];
    }
  }
  if (block.type === BLOCK_TYPE_BODY) {
    const {value, rawValue} = block;

    if (value.replaceAll('<br>', '').replaceAll('\n', '').trim().length !== 0) {
      const translatedRawValue = setTranslatedPropertiesToSlateObject(
        rawValue,
        translations
      );
      const markdown = slateToMarkdown(translatedRawValue);
      block.translations = hasLanguage
        ? block.translations.map((t) => {
            if (t.language === language) {
              return {
                ...t,
                value: markdown,
                rawValue: translatedRawValue,
              };
            } else {
              return t;
            }
          })
        : [
            ...(block.translations || []),
            {language: language, value: markdown, rawValue: translatedRawValue},
          ];
    }
  }
  if ([BLOCK_TYPE_PRIMARY_CTA, BLOCK_TYPE_SECONDARY_CTA].includes(block.type)) {
    const [text] = block.value.split(';');
    if (text) {
      block.translations = hasLanguage
        ? block.translations.map((t) => {
            if (t.language === language) {
              return {
                ...t,
                value: translations.shift(),
              };
            } else {
              return t;
            }
          })
        : [
            ...(block.translations || []),
            {language: language, value: translations.shift()},
          ];
    }
  }
  if (block.type === BLOCK_TYPE_LABEL) {
    const text = block.value;
    if (text) {
      block.translations = hasLanguage
        ? block.translations.map((t) => {
            if (t.language === language) {
              return {
                ...t,
                value: translations.shift(),
              };
            } else {
              return t;
            }
          })
        : [
            ...(block.translations || []),
            {language: language, value: translations.shift()},
          ];
    }
  }
  if (block.type === BLOCK_TYPE_CHOICE) {
    const {options} = block;
    for (const option of options) {
      if (option.content) {
        const hasLanguage =
          option.labels?.find((t) => t.language === language) != null;
        option.labels = hasLanguage
          ? option.labels.map((t) => {
              if (t.language === language) {
                return {
                  ...t,
                  content: translations.shift(),
                };
              } else {
                return t;
              }
            })
          : [
              ...(option.labels || []),
              {language: language, content: translations.shift()},
            ];
      }
    }
  }
  if (block.type === BLOCK_TYPE_SLIDER) {
    const {value} = block;
    const [type, customEmoji, labelLeft, labelRight] = value.split(';');
    const text = [labelLeft, labelRight].filter((t) => t).join('|-|');
    if (text) {
      block.translations = hasLanguage
        ? block.translations.map((t) => {
            if (t.language === language) {
              return {
                ...t,
                value: `${labelLeft ? translations.shift() : ''};${
                  labelRight ? translations.shift() : ''
                }`,
              };
            } else {
              return t;
            }
          })
        : [
            ...(block.translations || []),
            {
              language: language,
              value: `${labelLeft ? translations.shift() : ''};${
                labelRight ? translations.shift() : ''
              }`,
            },
          ];
    }
  }
  if (block.type === BLOCK_TYPE_NPS) {
    const {value} = block;
    const [labelLeft, labelRight] = value.split(';');
    const text = [labelLeft, labelRight].filter((t) => t).join('|-|');
    if (text) {
      block.translations = hasLanguage
        ? block.translations.map((t) => {
            if (t.language === language) {
              return {
                ...t,
                value: `${labelLeft ? translations.shift() : ''};${
                  labelRight ? translations.shift() : ''
                }`,
              };
            } else {
              return t;
            }
          })
        : [
            ...(block.translations || []),
            {
              language: language,
              value: `${labelLeft ? translations.shift() : ''};${
                labelRight ? translations.shift() : ''
              }`,
            },
          ];
    }
  }
  if (block.type === BLOCK_TYPE_OPINION) {
    const {value} = block;
    const [type, number, labelLeft, labelRight] = value.split(';');
    const text = [labelLeft, labelRight].filter((t) => t).join('|-|');
    if (text) {
      block.translations = hasLanguage
        ? block.translations.map((t) => {
            if (t.language === language) {
              return {
                ...t,
                value: `${labelLeft ? translations.shift() : ''};${
                  labelRight ? translations.shift() : ''
                }`,
              };
            } else {
              return t;
            }
          })
        : [
            ...(block.translations || []),
            {
              language: language,
              value: `${labelLeft ? translations.shift() : ''};${
                labelRight ? translations.shift() : ''
              }`,
            },
          ];
    }
  }
  if (block.type === BLOCK_TYPE_OPEN_QUESTION) {
    const text = block.value;
    if (text) {
      block.translations = hasLanguage
        ? block.translations.map((t) => {
            if (t.language === language) {
              return {
                ...t,
                value: translations.shift(),
              };
            } else {
              return t;
            }
          })
        : [
            ...(block.translations || []),
            {language: language, value: translations.shift()},
          ];
    }
  }
  if (
    [
      BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
      BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
      BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
      BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
    ].includes(block.type)
  ) {
    const {value, rawValue} = block;

    if (value.replaceAll('<br>', '').replaceAll('\n', '').trim().length !== 0) {
      const translatedRawValue = setTranslatedPropertiesToSlateObject(
        rawValue,
        translations
      );
      const markdown = slateToMarkdown(translatedRawValue);
      block.translations = hasLanguage
        ? block.translations.map((t) => {
            if (t.language === language) {
              return {
                ...t,
                value: markdown,
                rawValue: translatedRawValue,
              };
            } else {
              return t;
            }
          })
        : [
            ...(block.translations || []),
            {language: language, value: markdown, rawValue: translatedRawValue},
          ];
    }
  }
  if (
    [
      BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
      BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
    ].includes(block.type)
  ) {
    const [text] = block.value.split(';');
    if (text) {
      block.translations = hasLanguage
        ? block.translations.map((t) => {
            if (t.language === language) {
              return {
                ...t,
                value: translations.shift(),
              };
            } else {
              return t;
            }
          })
        : [
            ...(block.translations || []),
            {language: language, value: translations.shift()},
          ];
    }
  }
  if (block.type === BLOCK_TYPE_CHECKLIST_DISMISS) {
    const text = block.value;
    if (text) {
      block.translations = hasLanguage
        ? block.translations.map((t) => {
            if (t.language === language) {
              return {
                ...t,
                value: translations.shift(),
              };
            } else {
              return t;
            }
          })
        : [
            ...(block.translations || []),
            {language: language, value: translations.shift()},
          ];
    }
  }
};

const setTranslatedPropertiesToSlateObject = (slateObjects, translations) => {
  const translatedSlateObjects = [];
  slateObjects = JSON.parse(JSON.stringify(slateObjects));
  for (const slateObject of slateObjects) {
    const {text, children} = slateObject;
    if (text != null) {
      let translatedText = '';
      if (text.length > 0) {
        translatedText = translations.shift();
      } else {
        if (translations[0]?.length > 0) {
          translatedText = '';
        } else {
          translatedText = translations.shift();
        }
      }

      slateObject.text = translatedText || '';
    }
    if (children) {
      slateObject.children = setTranslatedPropertiesToSlateObject(
        children,
        translations
      );
    }
    translatedSlateObjects.push(slateObject);
  }
  return translatedSlateObjects;
};
