import Button from 'components/Button';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_SETTINGS_INTEGRATIONS_SLACK} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {slackService} from 'services';
import {PLAN_GROWTH_ID, PLAN_SCALE_ID} from 'services/subscription';
import {Swaler} from 'swaler';
import SlackLogo from '../imgs/slack.svg';
import './_Styles.scss';

const logger = new Swaler('SettingsIntegrationsSlack');

const Slack = () => {
  const history = useHistory();
  const {update} = useUpdateSubscription();

  const project = useSelector((state) => generalSelector.getProject(state));
  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );

  const {slack} = project;

  const isSlackActive = !!slack?.access_token;

  const [isLoading, setIsLoading] = useState(false);

  const handleConnectSlack = async () => {
    if (
      [PLAN_GROWTH_ID, PLAN_SCALE_ID].includes(subscription.planId) === false
    ) {
      return update({
        planId: PLAN_GROWTH_ID,
        title: 'Connect Slack',
        description: 'Unlock Slack integration with our Growth plan',
      });
    }

    setIsLoading(true);
    try {
      const url = await slackService.generateUrl({}, project.uid);
      window.open(url, '_self', 'noopener,noreferrer');
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Failed to generate slack url, ${code}`);
      toastDanger([title, message], {actions});
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="settings-card integration-slack">
      <div className="logo-action-wrapper">
        <img className="logo slack-logo" src={SlackLogo} alt="slack-logo" />
        {!isSlackActive ? (
          <Button
            primary
            thin
            iconRight="icon-chevron-right"
            loading={isLoading}
            onClick={handleConnectSlack}>
            Connect
          </Button>
        ) : (
          <Button
            thin
            iconLeft="isax isax-setting-2"
            onClick={() => history.push(ROUTE_SETTINGS_INTEGRATIONS_SLACK)}>
            Configure
          </Button>
        )}
      </div>
      <div className="integration-details-wrapper">
        <div className="subtitle-3 integration-title">
          Slack
          {isSlackActive === true && (
            <div className="label-connected body-4">
              <i className="isax isax-tick-circle"></i> Connected
            </div>
          )}
        </div>
        <div className="body-3">
          Automatically send survey responses to Slack channels
        </div>
      </div>
    </div>
  );
};

export default Slack;
