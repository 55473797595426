import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import {BuilderContext} from 'contextes/builder';
import {func, string} from 'prop-types';
import React, {useContext} from 'react';
import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import ProfileLink from 'scenes/PokeBuilder/components/BlockEditor/components/modals/ProfileLink';
import {BLOCK_TYPE_USER} from 'services/steps';
import './_Styles.scss';

const propTypes = {
  type: string,
  urlOrPostId: string,
  onChange: func,
  setCloseOnDocumentClick: func,
};

const defaultProps = {
  type: null,
  urlOrPostId: '',
  onChange: () => {},
  setCloseOnDocumentClick: () => {},
};

const PostBlockProfile = () => {
  const {evolution, setEvolution} = useContext(BuilderContext);

  const step = evolution.steps[0] || {};
  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_USER);

  const {value, file} = block;
  const [name, title, fileUrl, altText] = value.split(';');

  return (
    <div className="post-block-profile">
      <PopupSetting
        className="post-block-profile-popup"
        trigger={
          <ClickableInput
            key={fileUrl}
            value={`${name} ${title}`}
            leftLabel={
              fileUrl ? (
                <div className="preview-profile-media">
                  <img src={fileUrl} alt="Media" />
                </div>
              ) : (
                <span
                  className="placeholder-empty-media"
                  style={{background: '#FFFFFF'}}
                />
              )
            }
            erasable
            onErase={() => {
              setEvolution({
                ...evolution,
                steps: [
                  {
                    ...evolution.steps[0],
                    blocks: evolution.steps[0].blocks.filter(
                      (b) => b.type !== BLOCK_TYPE_USER
                    ),
                  },
                ],
              });
            }}
          />
        }
        title="Profile"
        content={
          <>
            <div className="section-title">Text</div>
            <div className="section-item">
              <InputGroup
                className="profile-info-input"
                value={name}
                placeholder="Name"
                onChange={({target}) => {
                  setEvolution({
                    ...evolution,
                    steps: [
                      {
                        ...evolution.steps[0],
                        blocks: evolution.steps[0].blocks.map((b) => {
                          if (b.type === BLOCK_TYPE_USER) {
                            return {
                              ...b,
                              value: `${target.value};${title};${fileUrl};${altText}`,
                            };
                          }
                          return b;
                        }),
                      },
                    ],
                  });
                }}
              />
            </div>

            <div className="section-item">
              <InputGroup
                className="profile-info-input"
                value={title}
                placeholder="Title"
                onChange={({target}) => {
                  setEvolution({
                    ...evolution,
                    steps: [
                      {
                        ...evolution.steps[0],
                        blocks: evolution.steps[0].blocks.map((b) => {
                          if (b.type === BLOCK_TYPE_USER) {
                            return {
                              ...b,
                              value: `${name};${target.value};${fileUrl};${altText}`,
                            };
                          }
                          return b;
                        }),
                      },
                    ],
                  });
                }}
              />
            </div>
            <Divider />
            <div className="section-title">Image</div>
            <ProfileLink
              fileUrl={fileUrl}
              altText={altText}
              file={file}
              onChange={({value, file}) => {
                setEvolution({
                  ...evolution,
                  steps: [
                    {
                      ...evolution.steps[0],
                      blocks: evolution.steps[0].blocks.map((b) => {
                        if (b.type === BLOCK_TYPE_USER) {
                          return {
                            ...b,
                            value: `${name};${title};${value}`,
                            file,
                            fileId: file?.uid,
                          };
                        }
                        return b;
                      }),
                    },
                  ],
                });
              }}
            />
          </>
        }
        dropdownProps={{
          repositionOnResize: true,
          position: 'bottom left',
          offsetY: 10,
        }}
      />
    </div>
  );
};

PostBlockProfile.propTypes = propTypes;
PostBlockProfile.defaultProps = defaultProps;

export default PostBlockProfile;
