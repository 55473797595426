import LivePreviewNew from 'components/LivePreviewNew';
import React, {useContext, useEffect, useState} from 'react';
import {AudienceContext} from 'scenes/PokeAudience';
import './_Styles.scss';
import StepsList from './components/StepsList';

const SummaryPreview = () => {
  const {evolution} = useContext(AudienceContext);

  const {tourSteps, steps} = evolution;

  const [previewedStepId, setPreviewedStepId] = useState(null);

  tourSteps?.forEach((ts) => {
    const [tourIndexOrder] = (ts.tourStepInfo || '0;0;0').split(';');
    ts.tourIndexOrder = tourIndexOrder;
    ts.steps.forEach((s) => {
      s.boostFlags = ts.boostFlags;
    });
  });
  tourSteps?.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder);

  const allStepsWithPoke =
    tourSteps?.length > 0
      ? tourSteps.reduce((steps, tourStep) => {
          if (tourStep.steps?.length > 0) {
            tourStep.steps.sort((a, b) => a.indexOrder - b.indexOrder);
            steps = steps.concat(
              tourStep.steps
                .filter((s) => s.removed !== true)
                .map((step) => ({step, poke: tourStep}))
            );
          }
          return steps;
        }, [])
      : steps
          .filter((s) => s.removed !== true)
          .map((step) => ({step, poke: evolution}));

  useEffect(() => {
    setPreviewedStepId(allStepsWithPoke[0]?.step?.uid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const poke = allStepsWithPoke.find(
    (s) => s.step.uid === previewedStepId
  )?.poke;

  return (
    <div className="summary-preview">
      <div className="steps-list-wrapper">
        <StepsList
          previewedStepId={previewedStepId}
          setPreviewedStepId={setPreviewedStepId}
        />
      </div>
      <div className="poke-preview-wrapper">
        {poke && (
          <LivePreviewNew
            evolution={evolution}
            poke={poke}
            selectedStepId={previewedStepId}
            isScaledDownPreview
            scale={1}
          />
        )}
      </div>
    </div>
  );
};

export default SummaryPreview;
