import InputGroup from 'components/Input';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import Slider from 'rc-slider';
import {useContext} from 'react';
import {BLOCK_TYPE_OPINION} from 'services/steps';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import {fontFamiliesOptions} from '../Title/utils';
import './_Styles.scss';

const typeOptions = [
  {label: 'Smiley', value: 'smiley'},
  {label: 'Stars', value: 'star'},
];

const OpinionScale = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_OPINION, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_OPINION);

  if (block == null) {
    return <></>;
  }

  const {value, style, flags} = block;
  const [type, number, labelLeft, labelRight] = value.split(';');

  return (
    <div className="block-settings opinion-scale">
      <div className="section">
        <div className="section-title">Style</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Steps</div>
            <div className="section-item-content">
              <StepsPicker
                value={number}
                onChange={(value) =>
                  updateBlock({
                    value: `${type};${value};${labelLeft};${labelRight}`,
                  })
                }
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Type</div>
            <div className="section-item-content">
              <SelectGroup
                className="font-option-select"
                options={typeOptions}
                value={typeOptions.find((o) => o.value === type)}
                onChange={(option) =>
                  updateBlock({
                    value: `${option.value};${number};${labelLeft};${labelRight}`,
                  })
                }
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Left label</div>
            <div className="section-item-content">
              <InputGroup
                className="opinion-scale-left-label-input"
                value={labelLeft}
                onChange={({target}) =>
                  updateBlock({
                    value: `${type};${number};${target.value};${labelRight}`,
                  })
                }
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Right label</div>
            <div className="section-item-content">
              <InputGroup
                className="opinion-scale-right-label-input"
                value={labelRight}
                onChange={({target}) =>
                  updateBlock({
                    value: `${type};${number};${labelLeft};${target.value}`,
                  })
                }
              />
            </div>
          </div>
          <OpinionScaleStyle style={style} updateStyle={updateBlock} />
        </div>
      </div>
    </div>
  );
};

const StepsPicker = ({value, onChange}) => {
  return (
    <div className="steps-picker">
      <div className="steps-picker-input">
        <InputGroup
          value={value}
          type="number"
          min={3}
          max={7}
          onChange={(e) => {
            const correctedValue = e.target.value || 3;
            onChange(correctedValue > 7 ? 7 : Math.max(3, correctedValue));
          }}
        />
      </div>
      <div className="steps-picker-slider">
        <Slider min={3} max={7} onChange={onChange} value={value || 5} />
      </div>
    </div>
  );
};

export const OpinionScaleStyle = ({style, updateStyle}) => {
  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Size</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style?.fontSize}
            min={8}
            max={42}
            onChange={(value) =>
              updateStyle({
                style: {...style, fontSize: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Padding</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style?.padding}
            min={0}
            max={100}
            onChange={(value) =>
              updateStyle({
                style: {...style, padding: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Labels font</div>
        <div className="section-item-content">
          <SelectGroup
            small
            options={fontFamiliesOptions}
            value={
              fontFamiliesOptions.find((o) => o.value === style.fontFamily) ??
              fontFamiliesOptions.find((font) => font.value === 'Inter')
            }
            onChange={(option) =>
              updateStyle({
                style: {...style, fontFamily: option.value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Labels color</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Color"
            value={style.labelsColor ?? null}
            onChange={(value) =>
              updateStyle({
                style: {...style, labelsColor: value},
              })
            }
            erasable
          />
        </div>
      </div>
    </>
  );
};

export default OpinionScale;
