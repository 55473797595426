import {default as classNames} from 'classnames';
import React, {useContext, useEffect} from 'react';
import ClickableBlockOverlay from '../../../Poke/components/ClickableBlockOverlay';
import {PokeContext, PokeStateContext} from '../../../Poke/context';
import './_styles.scss';
import {BLOCK_CHECKLIST_TASK_ITEM_PRIMARY_CTA} from '../../../Poke/constants/blocks';

export const NAVIGATION_NONE = 'none';
export const NAVIGATION_STEP_NEXT = 'step-next';
export const NAVIGATION_STEP_PREV = 'step-prev';
export const NAVIGATION_CLOSE = 'close';
export const ACTION_URL_OPEN = 'url-open';
export const ACTION_POST_OPEN = 'post-open';
export const ACTION_BOOK_INTERVIEW = 'open-booking-url';
export const ACTION_LAUNCH_EXPERIENCE = 'launch-experience';

const ChecklistCta = ({block = null, parentBlock = null}) => {
  const {executeActions} = useContext(PokeContext);
  const {
    onBlockSelected,
    onTaskSelected,
    selectedBlock,
    selectedTaskId,
    inBuilder,
    language,
    addFontFamily,
    onTaskClicked,
    onTaskSkipped,
  } = useContext(PokeStateContext);

  const {value, style} = block;
  const [text] = value.split(';');
  const {fontColor, fontFamily, primaryColor, padding, ...restStyles} = style;

  const isPrimaryCta = block.type === BLOCK_CHECKLIST_TASK_ITEM_PRIMARY_CTA;

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  const handleClick = () => {
    const callbackActions =
      isPrimaryCta === true ? parentBlock?.actions : block?.actions;

    if (isPrimaryCta === true) {
      onTaskClicked(block.parentBlockId);
    }

    executeActions(callbackActions, {
      onSkipTask: () => {
        onTaskSkipped(block.parentBlockId);
      },
    });
  };

  const translation = block.translations?.find((t) => t.language === language);

  return (
    <div
      className={classNames('checklist-cta', {
        'poke-block-clickable': inBuilder === true,
        selected:
          selectedBlock === block.type &&
          selectedTaskId === block.parentBlockId,
      })}
      style={{
        ...restStyles,
        fontFamily: `${fontFamily}, ui-sans-serif, system-ui, sans-serif`,
        color: fontColor,
        backgroundColor: primaryColor,
        padding: `${padding}px ${padding + 4}px`,
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (inBuilder === true) {
          onBlockSelected(block.type);
          onTaskSelected(block.parentBlockId);
        } else {
          handleClick();
        }
      }}>
      {translation != null ? translation.value : text}
      <ClickableBlockOverlay />
    </div>
  );
};

export default ChecklistCta;
