import Axios from 'axios';
import {generalSelector} from 'selectors';

export const FEEDBACK_TYPE_AI_GENERATED_RULES = 'AI_GENERATED_RULES';

// Endpoint
const EP_FEEDBACK_POST = '/feedback';

// Method
export const createFeedback = (data) => {
  const {data: feedbackData, type} = data;
  const projectId = generalSelector.getProject().uid;

  return Axios.post(
    EP_FEEDBACK_POST,
    {
      data: feedbackData,
      type,
      projectId,
    },
    {params: {projectId}}
  ).then((response) => response.data);
};
