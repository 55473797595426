import InputGroup from 'components/Input';
import {InteractiveOption} from 'components/InteractiveOption';
import Label from 'components/Label';
import Tooltip from 'components/Tooltip';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {number} from 'prop-types';
import React, {useContext} from 'react';
import {
  ACTIVE_OPERATOR_CUSTOM,
  ACTIVE_OPERATOR_EVERYWHERE,
  ACTIVE_OPERATOR_SINGLE_URL,
} from 'scenes/EmbeddedElementSelectorBuilder';
import {AudienceContext, SECTION_WHERE} from 'scenes/PokeAudience';
import AudienceSection from 'scenes/PokeAudience/components/AudienceSection';
import {PathSelector} from 'scenes/PokeAudience/components/PathSelector';
import {EVENT_TYPE_HOVER} from 'services/event';
import {
  F_DEVICE_DESKTOP,
  F_DEVICE_MOBILE,
  F_DEVICE_TABLET,
  TRIGGER_TYPE_EVENT,
  TRIGGER_TYPE_LEAVE_PAGE,
} from 'services/evolution';
import './_Styles.scss';

export const getWhereTitle = (evolution) => {
  const deviceFlags = evolution?.deviceFlags || 0;
  const boostedActiveOperator = evolution?.boostedActiveOperator;

  const activeDevices = [];
  let activePages = 'Everywhere';

  if (hasFlag(F_DEVICE_DESKTOP, deviceFlags)) {
    activeDevices.push('Desktop');
  }
  if (hasFlag(F_DEVICE_TABLET, deviceFlags)) {
    activeDevices.push('Tablet');
  }
  if (hasFlag(F_DEVICE_MOBILE, deviceFlags)) {
    activeDevices.push('Mobile');
  }

  if (boostedActiveOperator === ACTIVE_OPERATOR_EVERYWHERE) {
    activePages = 'Every page';
  } else if (boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL) {
    activePages = evolution.boostedActiveUrl || 'Missing url';
  } else if (boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM) {
    activePages = 'On filtered URLs';
  }

  return (
    <>
      On {activeDevices.join(', ')} &bull; {activePages}
    </>
  );
};

const propTypes = {
  index: number.isRequired,
};

const Where = () => {
  const {evolution, setEvolution} = useContext(AudienceContext);

  const isOnPageLeaving = evolution?.triggerType === TRIGGER_TYPE_LEAVE_PAGE;
  const isHoverEvent =
    evolution?.triggerType === TRIGGER_TYPE_EVENT &&
    (evolution?.event?.type === EVENT_TYPE_HOVER ||
      evolution?.onTheFlyEvent?.type === EVENT_TYPE_HOVER);

  return (
    <AudienceSection
      className="where"
      section={SECTION_WHERE}
      title="Where"
      subtitle={
        <Label secondary size="small" type="neutral">
          {getWhereTitle(evolution)}
        </Label>
      }
      icon={<i className="isax isax-global4" />}>
      <div className="sub-section-wrapper">
        <div className="sub-section-title body-3 n-700">
          Select devices to show this experience on
        </div>
        <div className="device-selection-wrapper">
          <InteractiveOption
            withCheckBox
            active={hasFlag(F_DEVICE_DESKTOP, evolution.deviceFlags)}
            icon="isax isax-monitor"
            title="Desktop"
            onClick={() => {
              if (evolution.deviceFlags === F_DEVICE_DESKTOP) {
                return;
              }
              setEvolution({
                ...evolution,
                deviceFlags: hasFlag(F_DEVICE_DESKTOP, evolution.deviceFlags)
                  ? removeFlag(F_DEVICE_DESKTOP, evolution.deviceFlags)
                  : addFlag(F_DEVICE_DESKTOP, evolution.deviceFlags),
              });
            }}
          />
          <Tooltip
            className="tooltip-warning-device"
            offsetY={4}
            disabled={isOnPageLeaving !== true && isHoverEvent !== true}
            trigger={
              <div>
                <InteractiveOption
                  withCheckBox
                  active={
                    hasFlag(F_DEVICE_TABLET, evolution.deviceFlags) &&
                    !isOnPageLeaving &&
                    !isHoverEvent
                  }
                  disabled={isOnPageLeaving || isHoverEvent}
                  icon="icon-tablet"
                  title="Tablet"
                  onClick={() => {
                    if (evolution.deviceFlags === F_DEVICE_TABLET) {
                      return;
                    }
                    setEvolution({
                      ...evolution,
                      deviceFlags: hasFlag(
                        F_DEVICE_TABLET,
                        evolution.deviceFlags
                      )
                        ? removeFlag(F_DEVICE_TABLET, evolution.deviceFlags)
                        : addFlag(F_DEVICE_TABLET, evolution.deviceFlags),
                    });
                  }}
                />
              </div>
            }>
            Tablet and mobile devices are not supported with the selected
            trigger
          </Tooltip>
          <Tooltip
            className="tooltip-warning-device"
            offsetY={4}
            disabled={isOnPageLeaving !== true && isHoverEvent !== true}
            trigger={
              <div>
                <InteractiveOption
                  withCheckBox
                  active={
                    hasFlag(F_DEVICE_MOBILE, evolution.deviceFlags) &&
                    !isOnPageLeaving &&
                    !isHoverEvent
                  }
                  disabled={isOnPageLeaving || isHoverEvent}
                  icon="isax isax-mobile"
                  title="Mobile"
                  onClick={() => {
                    if (evolution.deviceFlags === F_DEVICE_MOBILE) {
                      return;
                    }
                    setEvolution({
                      ...evolution,
                      deviceFlags: hasFlag(
                        F_DEVICE_MOBILE,
                        evolution.deviceFlags
                      )
                        ? removeFlag(F_DEVICE_MOBILE, evolution.deviceFlags)
                        : addFlag(F_DEVICE_MOBILE, evolution.deviceFlags),
                    });
                  }}
                />
              </div>
            }>
            Tablet and mobile devices are not supported with the selected
            trigger
          </Tooltip>
        </div>
      </div>
      <div className="sub-section-wrapper">
        <div className="sub-section-title body-3 n-700">
          Page to show this experience
        </div>
        <div className="url-selection-wrapper">
          <InteractiveOption
            withRadioBox
            active={
              evolution.boostedActiveOperator === ACTIVE_OPERATOR_EVERYWHERE
            }
            icon="isax isax-global"
            title="On all pages"
            onClick={() =>
              setEvolution({
                ...evolution,
                boostedActiveOperator: ACTIVE_OPERATOR_EVERYWHERE,
              })
            }
          />
          <InteractiveOption
            withRadioBox
            active={
              evolution.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL
            }
            icon="isax isax-link-2"
            title="On a specific page"
            onClick={() =>
              setEvolution({
                ...evolution,
                boostedActiveOperator: ACTIVE_OPERATOR_SINGLE_URL,
              })
            }
          />
          <InteractiveOption
            withRadioBox
            active={evolution.boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM}
            icon="isax isax-filter-search"
            title="On filtered URLs"
            onClick={() =>
              setEvolution({
                ...evolution,
                boostedActiveOperator: ACTIVE_OPERATOR_CUSTOM,
              })
            }
          />
        </div>
      </div>
      {evolution.boostedActiveOperator === ACTIVE_OPERATOR_SINGLE_URL && (
        <InputGroup
          required
          className="active-url-input"
          name="value"
          type="string"
          placeholder="https://www.yourawesomecompany.com/"
          value={evolution.boostedActiveUrl}
          onChange={(e) => {
            setEvolution({...evolution, boostedActiveUrl: e.target.value});
          }}
        />
      )}
      {(evolution.boostedActiveOperator === ACTIVE_OPERATOR_CUSTOM ||
        evolution.boostedActiveOperator == null) && (
        <PathSelector evolution={evolution} setEvolution={setEvolution} />
      )}
    </AudienceSection>
  );
};

Where.propTypes = propTypes;

export default Where;
