export const replaceUidsInObject = (originalObj, uidMapping) => {
  function isObjectOrArray(value) {
    return typeof value === 'object' && value !== null;
  }

  function replaceUids(obj) {
    if (Array.isArray(obj)) {
      return obj.map(replaceUids);
    } else if (isObjectOrArray(obj)) {
      const newObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (typeof value === 'string' && uidMapping.hasOwnProperty(value)) {
            newObj[key] = uidMapping[value];
          } else {
            newObj[key] = replaceUids(value);
          }
        }
      }
      return newObj;
    } else {
      return obj;
    }
  }

  const res = replaceUids(originalObj);
  return res;
};
