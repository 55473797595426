import classNames from 'classnames';
import React, {forwardRef, useContext, useState} from 'react';
import ReactDatePicker from 'react-datepicker';
import {AudienceContext} from 'scenes/PokeAudience';
import './_Styles.scss';

const TimingSelection = () => {
  const {evolution, setEvolution} = useContext(AudienceContext);

  const [isPublishOpen, setPublishIsOpen] = useState(false);
  const [isExpiresOpen, setExpiresIsOpen] = useState(false);

  const DatepickerCustomInput = forwardRef(
    ({onClick, label, active, defaultActive, onReset}, ref) => {
      return (
        <Option
          innerRef={ref}
          title={label}
          icon="icon-calendar"
          active={active}
          defaultActive={defaultActive}
          onClick={onClick}
          onReset={onReset}
        />
      );
    }
  );

  const curDate = new Date();
  let publishAtLabel = 'Now';
  let expiresAtLabel = 'Set an expiration date';

  if (evolution.lastStepChangeAt != null) {
    if (new Date(evolution.lastStepChangeAt).getTime() > curDate.getTime()) {
      publishAtLabel = `Goes live on the ${dateToString(
        evolution.lastStepChangeAt
      )}`;
    }
    if (
      new Date(evolution.lastStepChangeAt).getTime() < curDate.getTime() &&
      evolution.isDraft !== true
    ) {
      publishAtLabel = `Has been live since ${dateToString(
        evolution.lastStepChangeAt
      )}`;
    }
  }

  if (evolution.expiresAt != null) {
    expiresAtLabel = `Expires the ${dateToString(evolution.expiresAt)}`;
  }

  return (
    <div className="live-timing-options">
      <ReactDatePicker
        wrapperClassName="date-picker-wrapper launch-date"
        onInputClick={() => setPublishIsOpen(true)}
        onClickOutside={() => setPublishIsOpen(false)}
        open={isPublishOpen}
        selected={
          evolution.lastStepChangeAt == null
            ? new Date()
            : new Date(evolution.lastStepChangeAt)
        }
        onChange={(lastStepChangeAt) => {
          setEvolution({...evolution, lastStepChangeAt});
        }}
        dateFormat="MM/dd/yyyy h:mm"
        showTimeInput
        customInput={
          <DatepickerCustomInput
            label={publishAtLabel}
            active={
              evolution.lastStepChangeAt != null &&
              new Date(evolution.lastStepChangeAt).getTime() !==
                curDate.getTime()
            }
            defaultActive={true}
            onReset={() =>
              setEvolution({
                ...evolution,
                lastStepChangeAt: null,
              })
            }
          />
        }
        popperPlacement="bottom-end"
      />
      <ReactDatePicker
        wrapperClassName="date-picker-wrapper"
        onInputClick={() => setExpiresIsOpen(true)}
        onClickOutside={() => setExpiresIsOpen(false)}
        open={isExpiresOpen}
        selected={
          evolution.expiresAt != null ? new Date(evolution.expiresAt) : null
        }
        onChange={(expiresAt) => setEvolution({...evolution, expiresAt})}
        dateFormat="MM/dd/yyyy h:mm"
        showTimeInput
        minDate={
          evolution.lastStepChangeAt != null
            ? new Date(evolution.lastStepChangeAt)
            : null
        }
        customInput={
          <DatepickerCustomInput
            label={expiresAtLabel}
            active={evolution.expiresAt != null}
            onReset={() =>
              setEvolution({
                ...evolution,
                expiresAt: null,
              })
            }
          />
        }
        popperPlacement="bottom-end"
      />
    </div>
  );
};

const Option = ({title, icon, active, defaultActive, onClick, onReset}) => {
  return (
    <div
      className={classNames('date-options-item', {
        'is-active': defaultActive === true || active === true,
      })}
      onClick={(e) => {
        if (e.target.classList.contains('icon-close')) {
          return;
        }
        onClick();
      }}>
      <i className={active !== true ? icon : 'icon-close'} onClick={onReset} />
      {title}
    </div>
  );
};

export const dateToString = (dateStr) => {
  const date = new Date(dateStr);
  return `${date.toLocaleDateString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })} at ${date.toLocaleTimeString([], {hour: 'numeric', minute: '2-digit'})}`;
};

export default TimingSelection;
