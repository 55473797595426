import classnames from 'classnames';
import commaNumber from 'comma-number';
import Button from 'components/Button';
import {Modal} from 'components/Modal';
import {GlobalContext} from 'contextes/Global';
import dayjs from 'dayjs';
import {useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {dataSelector, generalSelector} from 'selectors';
import {
  displayPrice,
  getMauPrice,
  getPlanMonthlyPrice,
  getPlanYearlyPrice,
  hasAnnualBilling,
} from 'services/subscription';
import './_styles.scss';

export const ModalPlanDowngrade = () => {
  const {modalPlanDowngrade} = useContext(GlobalContext);
  const {planId, onSubmit, onCancel} = modalPlanDowngrade;

  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );
  const plans = useSelector((state) =>
    dataSelector.getSubscriptionsPlans(state)
  );

  const [isDowngrading, setIsDowngrading] = useState(false);

  function handleDowngrade() {
    setIsDowngrading(true);
    onSubmit();
  }

  const currentPlan = plans.find((p) => p.uid === subscription.plan);
  const newPlan = plans.find((p) => p.uid === planId);
  const mauPrice = getMauPrice(subscription.extraJimers, hasAnnualBilling());

  return (
    <Modal
      isOpen={true}
      onRequestClose={onCancel}
      className={classnames('modal-plan-downgrade')}>
      <div className="modal-main">
        <div className="downgrade-title title-4">Confirm Downgrade</div>

        {/* Current plan */}
        <div
          className={classnames(
            'plan-item',
            `plan-${subscription.plan.toLowerCase()}`
          )}>
          <div className="plan-label body-3 n-700">Current Plan</div>
          <div className="plan-details">
            <div className="left-side">
              <div className="plan-details-wrapper">
                <span className="subtitle-3">{currentPlan.title} Plan</span>
                <div className="body-3 n-600">
                  {hasAnnualBilling() ? 'Billed Yearly' : 'Billed Monthly'}{' '}
                  &middot; {commaNumber(subscription.extraJimers ?? 0)} MAU
                </div>
              </div>
            </div>
            <div className="right-side subtitle-3">
              {hasAnnualBilling() &&
                `${displayPrice(
                  getPlanYearlyPrice(currentPlan) + mauPrice
                )} / year`}
              {hasAnnualBilling() === false &&
                `${displayPrice(
                  getPlanMonthlyPrice(currentPlan) + mauPrice
                )} / month`}
            </div>
          </div>
        </div>
        {/* New plan */}
        <div
          className={classnames('plan-item', `plan-${planId.toLowerCase()}`)}>
          <div className="plan-label body-3 n-700">New Plan</div>
          <div className="plan-details">
            <div className="left-side">
              <div className="plan-details-wrapper">
                <span className="subtitle-3">{newPlan.title} Plan</span>
                <div className="body-3 n-600">
                  {hasAnnualBilling() ? 'Billed Yearly' : 'Billed Monthly'}{' '}
                  &middot; {commaNumber(subscription.extraJimers ?? 0)} MAU
                </div>
              </div>
            </div>
            <div className="right-side subtitle-3">
              {hasAnnualBilling() &&
                `${displayPrice(
                  getPlanYearlyPrice(newPlan) + mauPrice
                )} / year`}
              {hasAnnualBilling() === false &&
                `${displayPrice(
                  getPlanMonthlyPrice(newPlan) + mauPrice
                )} / month`}
            </div>
          </div>
        </div>

        {/* Description */}
        <div className="downgrade-description body-3 n-700">
          You will lose access to your current plan features today, and you
          won't be charged until your next billing cycle, which is on{' '}
          <b>
            {dayjs(subscription.stripeCurrentPeriodEnd * 1000).format(
              'MMMM DD, YYYY'
            )}
          </b>
          .
        </div>
        <div className="actions-wrapper">
          <Button onClick={onCancel} disabled={isDowngrading}>
            Cancel
          </Button>
          <Button primary onClick={handleDowngrade} loading={isDowngrading}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};
