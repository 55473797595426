import classNames from 'classnames';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import {BuilderContext} from 'contextes/builder';
import {errorHelpers} from 'helpers';
import React, {useContext, useRef, useState} from 'react';
import {
  F_BLOCK_ZOOM_ON_MEDIA_CLICK,
  getDefaultBlockFromType,
} from 'scenes/PokeBuilder/components/BlockManager/utils';
import {fileService} from 'services';
import {BLOCK_TYPE_MEDIA} from 'services/steps';
import {Swaler} from 'swaler';
import './_Styles.scss';

const DEFAULT_ALT_TEXT = 'post-thumbnail';
const MAX_FILE_NAME_LENGTH = 36;
const KILO_BYTE = 1024;

const logger = new Swaler('PostThumbnail');

const PostThumbnail = () => {
  const {evolution, setEvolution} = useContext(BuilderContext);

  const step = evolution.steps[0] || {};
  const block =
    step?.blocks?.find((b) => b?.type === BLOCK_TYPE_MEDIA) ||
    getDefaultBlockFromType(BLOCK_TYPE_MEDIA);

  const {value, file} = block;
  const [_, fileUrl] = value.split(';');

  const [isUploading, setIsUploading] = useState(false);

  const refInputFile = useRef();

  const handleFileChange = async ({target}) => {
    if (!target.files || target.files.length === 0) {
      return;
    }
    setIsUploading(true);
    const file = await uploadFile(target.files[0]);
    setIsUploading(false);

    if (file != null) {
      onChange({
        value: `${file.publicUrl};${DEFAULT_ALT_TEXT}`,
        file,
      });
    }
  };

  const handleDeleteFile = async () => {
    onChange({
      value: `;${DEFAULT_ALT_TEXT}`,
      file: null,
    });
  };

  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }
    try {
      const uploadedFile = await fileService.uploadPublicFile({
        file,
        blockType: BLOCK_TYPE_MEDIA,
      });

      return uploadedFile;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to upload file', code);
      toastDanger([title, message], {
        actions,
      });
      return null;
    }
  };
  const onChange = ({value, file}) => {
    const mediaBlock = step?.blocks?.find((b) => b?.type === BLOCK_TYPE_MEDIA);

    const newMediaBlock = {
      value: `image;${value};fill`,
      file,
      fileId: file?.uid,
      style: {
        padding: 0,
      },
      flags: F_BLOCK_ZOOM_ON_MEDIA_CLICK,
    };
    if (mediaBlock == null || mediaBlock === undefined) {
      setEvolution({
        ...evolution,
        steps: [
          {
            ...step,
            blocks: [
              ...(step?.blocks || []),
              {
                ...getDefaultBlockFromType(BLOCK_TYPE_MEDIA),
                ...newMediaBlock,
              },
            ],
          },
        ],
      });
    } else {
      setEvolution({
        ...evolution,
        steps: [
          {
            ...step,
            blocks: step.blocks.map((b) => {
              if (b.type === BLOCK_TYPE_MEDIA) {
                return {
                  ...b,
                  ...newMediaBlock,
                };
              }
              return b;
            }),
          },
        ],
      });
    }
  };

  const hasUploaded = fileUrl != null && file != null;

  return (
    <div className="post-thumbnail" placeholder="Post Thumbnail">
      <div className="logo-section">
        {hasUploaded ? (
          <img src={fileUrl} alt="post-thumbnail" />
        ) : (
          <i className="icon-image-o" />
        )}
      </div>
      <div className="text-section">
        <div className="title">
          {hasUploaded ? (
            <text>
              {file.originalName.length > MAX_FILE_NAME_LENGTH
                ? file.originalName.slice(0, MAX_FILE_NAME_LENGTH) + '...'
                : file.originalName}
            </text>
          ) : (
            <>
              <text>Post Thumbnail (Max. 1MB)</text>
              <span>• Optional</span>
            </>
          )}
        </div>
        {hasUploaded
          ? `${(file?.size / KILO_BYTE)?.toFixed(1) || 'unknown'} KB`
          : 'Recommended size: 1432 x 800px (.jpg, .png, .gif)'}
      </div>
      <div className="actions">
        <input
          ref={refInputFile}
          type="file"
          style={{display: 'none'}}
          onChange={handleFileChange}
        />
        <div
          className={classNames('btn-file', {
            'is-uploading': isUploading === true,
          })}
          onClick={() => refInputFile.current.click()}>
          {isUploading === true ? (
            <>
              <DefaultLoader width={14} /> uploading your image...
            </>
          ) : (
            <Button iconLeft="icon-upload-file" className="btn-upload" thin>
              {hasUploaded ? 'Replace' : 'Upload'}
            </Button>
          )}
        </div>
        {hasUploaded && (
          <Button
            iconLeft="icon-trash"
            className="btn-delete"
            onClick={handleDeleteFile}
            danger
            thin>
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

export default PostThumbnail;
