import classnames from 'classnames';
import {ElementPickerModal} from 'components/ElementPicker';
import {BuilderContext} from 'contextes/builder';
import {hasFlag, hasFlags} from 'helpers/bitwise';
import {useContext, useEffect, useRef, useState} from 'react';
import TargetNotSelectedWarning from 'scenes/EmbeddedPokeBuilder/components/TargetNotSelectedWarning';
import {evolutionService} from 'services';
import {
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
} from 'services/evolution';
import {F_STEP_IS_SELECTING_PRESET} from 'services/steps';
import {
  F_SLOT_DOT,
  F_SLOT_HINT,
  F_SLOT_TOOLTIP,
  F_SLOT_TOP_BAR,
} from 'shared/front/components/Poke/constants/poke';
import {Swaler} from 'swaler';
import './_Styles.scss';
import BlockEditor from './components/BlockEditor';
import ChecklistRender from './components/ChecklistRender';
import LightweightPokeBuilder from './components/LightweightPokeBuilder';
import LiveEditWarningModal from './components/LiveEditWarningModal';
import PokeBuilderSidebar from './components/PokeBuilderSidebar';
import {PokeRender} from './components/PokeRender';
import PresetSelector from './components/PresetSelector';

const logger = new Swaler('PokeBuilder');

const PokeBuilder = ({hideStepEditor = false}) => {
  const {
    selectedStepId,
    controlledEvolution: poke,
    evolution: parentEvolution,
    setEvolution: setParentEvolution,
    setOriginalEvolution,
    isInApp,
    messenger,
    setLastAutoSaveAt,
    selectedStep,
  } = useContext(BuilderContext);

  const [showElementPickerModal, setShowElementPickerModal] = useState(false);
  const [showLiveEditWarningModal, setShowLiveEditWarningModal] =
    useState(false);
  const [savedDraft, setSavedDraft] = useState(parentEvolution);

  const evolutionRef = useRef(parentEvolution);
  const savedDraftRef = useRef();
  const hasSubmittedRef = useRef();

  const hasSubmittedRefCurrent = hasSubmittedRef.current;
  const evolutionRefCurrent = evolutionRef.current;

  const isHint = parentEvolution?.type === EVOLUTION_TYPE_HINT;
  const isChecklist = parentEvolution?.type === EVOLUTION_TYPE_CHECKLIST;

  useEffect(() => {
    evolutionRef.current = parentEvolution;
  }, [parentEvolution]);

  useEffect(() => {
    savedDraftRef.current = savedDraft;
  }, [savedDraft]);

  useEffect(() => {
    if (parentEvolution?.isDraft !== true && isHint !== true) {
      setShowLiveEditWarningModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Poke autosave
  useEffect(() => {
    const interval = setInterval(() => {
      if (evolutionRefCurrent?.isDraft === true) {
        saveDraft();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
      if (
        hasSubmittedRefCurrent === false &&
        evolutionRefCurrent?.isDraft === true
      ) {
        saveDraft();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveDraft = async () => {
    // check that draft needs to be saved
    if (
      JSON.stringify(evolutionRef.current) ===
      JSON.stringify(savedDraftRef.current)
    ) {
      return;
    }

    try {
      await evolutionService.updateEvolution(evolutionRef.current.uid, {
        ...evolutionRef.current,
      });

      setLastAutoSaveAt(new Date().getTime());
      setSavedDraft(evolutionRef.current);
      setOriginalEvolution({...evolutionRef.current});
    } catch (err) {
      logger.error('Update evolution draft failed with error ', err);
    }
  };

  const isTargetNotSelected =
    hasFlags(
      [F_SLOT_DOT, F_SLOT_TOOLTIP, F_SLOT_HINT],
      poke.boostFlags,
      true
    ) &&
    !poke.boostedQueryselector &&
    !poke.querySelectorManual?.elementText &&
    !poke.querySelectorManual?.cssSelector;
  const isSelectingPreset = hasFlag(
    F_STEP_IS_SELECTING_PRESET,
    selectedStep?.stepFlags
  );
  const isStepHidden = selectedStep?.removed === true;

  if (isInApp === true) {
    if (hideStepEditor === true) {
      return <></>;
    }

    return <LightweightPokeBuilder />;
  }
  return (
    <div className={classnames('poke-builder', 'grid-a-b-a')}>
      {selectedStepId != null ? <PokeBuilderSidebar /> : <div />}
      {selectedStepId != null ? (
        <div
          className={classnames('poke-render-wrapper', {
            'poke-is-banner': hasFlag(F_SLOT_TOP_BAR, poke.boostFlags),
          })}>
          {isStepHidden && (
            <div className="hidden-step-warning">
              <i className="isax isax-eye-slash" />
              <div className="content">
                <div className="title subtitle-4 n-800">
                  This {isHint ? 'hint' : 'step'} is hidden
                </div>
                <div className="description body-4 n-700">
                  This {isHint ? 'hint' : 'step'} will not be shown to the users
                  once changes are published.
                </div>
              </div>
            </div>
          )}
          {isChecklist ? <ChecklistRender /> : <PokeRender />}
        </div>
      ) : (
        <div />
      )}
      {isSelectingPreset ? <PresetSelector /> : <BlockEditor />}
      {isTargetNotSelected && isSelectingPreset !== true && (
        <TargetNotSelectedWarning
          type={
            poke.boostFlags === F_SLOT_DOT
              ? 'hotspot'
              : poke.boostFlags === F_SLOT_TOOLTIP
              ? 'tooltip'
              : poke.boostFlags === F_SLOT_HINT
              ? 'hint'
              : 'element'
          }
          onSelectElementClick={() => {
            if (isInApp) {
              messenger.sendSelectTargetElement();
            } else {
              setShowElementPickerModal(true);
            }
          }}
        />
      )}
      {showElementPickerModal && (
        <ElementPickerModal
          evolution={parentEvolution}
          stepId={selectedStepId}
          isOpen={showElementPickerModal}
          onRequestClose={() => setShowElementPickerModal(false)}
          setEvolution={(evolution) => {
            if (evolution.uid === evolutionRef.current?.uid) {
              setParentEvolution(evolution);
            } else {
              setParentEvolution({
                ...evolutionRef.current,
                tourSteps: evolutionRef.current.tourSteps.map((tourStep) => {
                  if (tourStep.uid === evolution.uid) {
                    return evolution;
                  }
                  return tourStep;
                }),
              });
            }
          }}
        />
      )}

      {showLiveEditWarningModal && (
        <LiveEditWarningModal
          isOpen={showLiveEditWarningModal}
          onRequestClose={() => setShowLiveEditWarningModal(false)}
        />
      )}
    </div>
  );
};

export default PokeBuilder;
