import {getDefaultBlockFromType} from 'scenes/PokeBuilder/components/BlockManager/utils';
import {evolutionService} from 'services';
import {EVOLUTION_TYPE_CHECKLIST, F_BOOST_SLOT_DOT} from 'services/evolution';
import {
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHECKLIST,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_TITLE,
  getDefaultStep,
} from 'services/steps';
import {defaultHotspotStyle} from './components/ThemeEditor';

export const getPreviewPoke = () => {
  const evolution = evolutionService.getDefaultEvolution({
    boostFlags: F_BOOST_SLOT_DOT,
    steps: [
      getDefaultStep({
        name: 'Tour welcome',
        blocks: [
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_TITLE),
            value: `Let's get started!|-|none|-|`,
          },
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_BODY),
            value: `We'll guide you through maximizing the benefits of this product.`,
          },
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
          },
        ],
      }),
      getDefaultStep({
        name: 'Tour welcome',
        blocks: [
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_TITLE),
            value: `Let's get started!|-|none|-|`,
          },
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_BODY),
            value: `We'll guide you through maximizing the benefits of this product.`,
          },
          {
            ...getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA),
          },
        ],
      }),
    ],
  });

  return evolution;
};

export const stylePreviewPoke = (theme, evolution = {}) => {
  const {style = {}} = theme || {};

  // delete width and height from step style to prevent overwriting
  delete style.stepStyle?.width;
  delete style.stepStyle?.height;

  const styledEvolution = {
    ...evolution,
    style: {
      ...evolution.style,
      ...style.stepStyle,
    },
    tourSteps: evolution.tourSteps?.map((t) => {
      t.style = {
        ...t.style,
        ...style.stepStyle,
      };
      t.steps = t.steps.map((s) => {
        s.blocks = s.blocks.map((b) => {
          return {
            ...b,
            style:
              style.blocksStyle?.[b.type]?.style ||
              getDefaultBlockFromType(b.type).style,
          };
        });
        return s;
      });
      return t;
    }),
    steps: evolution.steps?.map((s) => {
      if (s.type === EVOLUTION_TYPE_CHECKLIST) {
        s.style = {
          ...s.style,
          ...style.blocksStyle?.[BLOCK_TYPE_CHECKLIST]?.style,
        };
      }
      s.blocks = s.blocks
        .map((b) => {
          if (b != null) {
            return {
              ...b,
              style:
                style.blocksStyle?.[b?.type]?.style ||
                getDefaultBlockFromType(b.type).style,
            };
          }
          return b;
        })
        .filter((b) => b);

      return s;
    }),
    boostedDotStyle:
      style.blocksStyle?.['HOTSPOT']?.style || defaultHotspotStyle,
  };

  return styledEvolution;
};
