export const AVAILABLE_LANGUAGES = [
  {code: 'FR', label: 'french', icon: 'flag-icon-fr'},
  {code: 'EN', label: 'english', icon: 'flag-icon-gb'},
  {code: 'ES', label: 'spanish', icon: 'flag-icon-es'},
  {code: 'PT', label: 'portuguese', icon: 'flag-icon-pt'},
  {code: 'IT', label: 'italian', icon: 'flag-icon-it'},
  {code: 'DE', label: 'german', icon: 'flag-icon-de'},
  {code: 'JP', label: 'japanese', icon: 'flag-icon-jp'},
  {code: 'ZH', label: 'chinese', icon: 'flag-icon-cn'},
  {code: 'SV', label: 'swedish', icon: 'flag-icon-se'},
  {code: 'NL', label: 'dutch', icon: 'flag-icon-nl'},
  {code: 'FI', label: 'Finnish', icon: 'flag-icon-fi'},
  {code: 'RU', label: 'Russian', icon: 'flag-icon-ru'},
  {code: 'CS', label: 'Czech', icon: 'flag-icon-cz'},
  {code: 'HU', label: 'Hungarian', icon: 'flag-icon-hu'},
  {code: 'PL', label: 'Polish', icon: 'flag-icon-pl'},
  {code: 'DA', label: 'Danish', icon: 'flag-icon-dk'},
  {code: 'NO', label: 'Norwegian', icon: 'flag-icon-no'},
  {code: 'RO', label: 'Romanian', icon: 'flag-icon-ro'},
];
