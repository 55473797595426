import InputGroup from 'components/Input';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import {BLOCK_TYPE_ANIMATION} from 'services/steps';
import ClickableInput from '../../components/items/ClickableInput';
import Animations, {
  successAnimations,
} from '../../components/modals/Animations';
import PopupSetting from '../../components/PopupSetting';
import './_Styles.scss';

const Animation = () => {
  const {selectedStep: step, updateBlock: uptBlock} =
    useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_ANIMATION, updateObj);
  };

  const block = step?.blocks.find((b) => b.type === BLOCK_TYPE_ANIMATION);

  if (block == null) {
    return <></>;
  }

  const {value} = block;

  const animationIn =
    value != null ? successAnimations.find((a) => a.value === value) : null;

  return (
    <div className="block-settings animation">
      <div className="section">
        <div className="section-title">Animation</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Animations</div>
            <div className="section-item-content">
              <PopupSetting
                trigger={
                  <ClickableInput
                    value={animationIn?.name}
                    leftLabel={
                      <div className="animation-icon-wrapper">
                        {animationIn?.icon != null ? (
                          <i className={animationIn.icon} />
                        ) : (
                          <span
                            className="animation-icon"
                            style={{background: '#FFFFFF'}}
                          />
                        )}
                      </div>
                    }
                  />
                }
                title="Animations"
                content={
                  <Animations
                    value={value}
                    onChange={(value) => {
                      updateBlock({
                        value,
                      });
                    }}
                  />
                }
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Duration</div>
            <div className="section-item-content">
              <InputGroup className="animation-duration-input" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Animation;
