/* eslint-disable import/no-anonymous-default-export */
import {
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';

// Startup
export const FEATURE_EXPERIENCES_UNLIMITED = 'FEATURE_EXPERIENCES_UNLIMITED';
export const FEATURE_SURVEYS_LIMITED = 'FEATURE_SURVEYS_LIMITED';
export const FEATURE_CHECKLIST_LIMITED = 'FEATURE_CHECKLIST_LIMITED';
export const FEATURE_MULTIPLE_LANGUAGES = 'FEATURE_MULTIPLE_LANGUAGES';
export const FEATURE_SUPPORT = 'FEATURE_SUPPORT';
// Growth
export const FEATURE_EXPERIENCES_UNLIMITED_GROWTH =
  'FEATURE_EXPERIENCES_UNLIMITED_GROWTH';
export const FEATURE_SUCCESS_TRACKER = 'FEATURE_SUCCESS_TRACKER';
export const FEATURE_LINK_SHARING = 'FEATURE_LINK_SHARING';
export const FEATURE_DEDICATED_CSM = 'FEATURE_DEDICATED_CSM';
export const FEATURE_WHITE_LABEL = 'FEATURE_WHITE_LABEL';
export const FEATURE_INTEGRATION_SLACK = 'FEATURE_INTEGRATION_SLACK';
export const FEATURE_INTEGRATION_HUBSPOT = 'FEATURE_INTEGRATION_HUBSPOT';
// Scale
export const FEATURE_CUSTOM_INVOICING = 'FEATURE_CUSTOM_INVOICING';

export default {
  [PLAN_STARTUP_ID]: [
    {
      uid: FEATURE_EXPERIENCES_UNLIMITED,
      title: 'Unlimited Tours, Modals, Banners and Hints',
    },
    {
      uid: FEATURE_SURVEYS_LIMITED,
      title: 'Up to 2 Surveys & NPS',
    },
    {
      uid: FEATURE_CHECKLIST_LIMITED,
      title: 'Up to 1 Checklist',
    },
    {
      uid: FEATURE_MULTIPLE_LANGUAGES,
      title: 'Multiple languages',
    },
    {
      uid: FEATURE_SUPPORT,
      title: 'Email and chat support',
    },
  ],
  [PLAN_GROWTH_ID]: [
    {
      uid: FEATURE_EXPERIENCES_UNLIMITED_GROWTH,
      title: 'Unlimited Surveys & Checklists',
    },
    {
      uid: FEATURE_SUCCESS_TRACKER,
      title: 'Access to Success Tracker',
    },
    {
      uid: FEATURE_LINK_SHARING,
      title: 'Link sharing',
    },
    {
      uid: FEATURE_WHITE_LABEL,
      title: 'Hide Jimo label',
    },
    {
      uid: FEATURE_INTEGRATION_SLACK,
      title: 'Slack Integration',
    },
    {
      uid: FEATURE_INTEGRATION_HUBSPOT,
      title: 'Hubspot Integration',
    },
    {
      uid: FEATURE_DEDICATED_CSM,
      title: 'Dedicated Customer Support Management',
    },
  ],
  [PLAN_SCALE_ID]: [
    {
      uid: FEATURE_CUSTOM_INVOICING,
      title: 'Custom invoicing',
    },
  ],
};
