import {useElements, useStripe} from '@stripe/react-stripe-js';
import {toastDanger} from 'components/Toaster';
import {GlobalContext} from 'contextes/Global';
import {crispHelpers} from 'helpers';
import {useJimoIdentify} from 'helpers/jimoOnJimo';
import {useContext, useState} from 'react';
import {subscriptionService} from 'services';
import {Swaler} from 'swaler';
import {useUpdatePlan} from './useUpdatePlan';

const logger = new Swaler('useSubscriptionCheckout');

export const useSubscriptionCheckout = ({
  onSuccess = () => {},
  onError,
} = {}) => {
  const {setModalWelcomePlan} = useContext(GlobalContext);

  const {pushAttributes} = useJimoIdentify({forceIsIdentified: true});

  const stripe = useStripe();
  const elements = useElements();
  const {update} = useUpdatePlan();

  const [isWorking, setIsWorking] = useState(false);

  const handleSuccess = ({planId, mau}) => {
    update({planId, mau});
    setModalWelcomePlan({planId, mau, refreshProjectAfterClose: true});
    onSuccess();
  };

  async function checkout({
    cardEmpty,
    zip,
    countryCode,
    cardholderName,
    vat,
    plan,
    mau,
    addons,
    modeAnnualBilling,
    coupon,
    CardElement,
  }) {
    let result = null;
    let stripeResponse = null;

    setIsWorking(true);
    if (!stripe || !elements) {
      logger.error('Stripe or Stripe Elements not initialized!');
      throw new Error('STRIPE_NOT_READY');
    }
    if (cardEmpty || zip.length === 0 || countryCode.length === 0) {
      return;
    }
    await subscriptionService.updateStripeCustomer({
      postalCode: zip,
      countryCode: countryCode,
      name: cardholderName,
      ...(vat.length > 0 ? {vatNumber: vat} : {}),
    });
    result = await subscriptionService.createOrUpdateStripeSubscription(
      {
        plan: plan.uid,
        mau,
        addons: addons.map((addon) => addon.uid),
        modeAnnual: modeAnnualBilling,
      },
      {
        stripePromotionCodeId:
          coupon != null ? coupon.stripePromotionCodeId : undefined,
      }
    );
    if (result == null) {
      setIsWorking(false);
      toastDanger(
        [
          'Subscription update failed!',
          `An unknown error occurred when trying to update your subscription.`,
        ],
        {
          autoClose: false,
          toastId: 'subscription-update-failed',
          actions: [
            {
              text: 'Chat with us',
              props: {
                onClick: () =>
                  crispHelpers.startCrispThread(
                    `I just ran into an error during checkout with my subscription update. Can you help me ? :-)`
                  ),
                iconLeft: 'icon-chat',
              },
            },
          ],
        }
      );
    }
    if (result.clientSecret == null) {
      setIsWorking(false);
      return handleSuccess({
        planId: plan.uid,
        mau,
      });
    }
    stripeResponse = await stripe.confirmCardPayment(result.clientSecret, {
      payment_method: {card: elements.getElement(CardElement)},
    });
    if (stripeResponse.error == null) {
      setIsWorking(false);
      pushAttributes({
        isPaying: true,
      });
      return handleSuccess({
        planId: plan.uid,
        mau,
      });
    }
    if (stripeResponse.error) {
      const errorCode =
        stripeResponse.error.decline_code || stripeResponse.error.code;

      setIsWorking(false);
      logger.error('Stripe answered with error code ', errorCode);
      toastDanger(
        [
          `Checkout error`,
          `Something went wrong with your checkout. Please try again, and if the issue persists, contact our supports for assistance (${errorCode})`,
        ],
        {
          autoClose: false,
          toastId: 'checkout-failed',
          className: 'toast-subscription-checkout-error',
          actions: [
            {
              text: 'Contact support',
              props: {
                onClick: () =>
                  crispHelpers.startCrispThread(
                    `I just ran into an error during checkout (${errorCode}). Can you help me ? :-)`
                  ),
                iconLeft: 'icon-chat',
              },
            },
          ],
        }
      );
    }
  }

  return {
    isWorking,
    checkout,
  };
};
