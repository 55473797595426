import classNames from 'classnames';
import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import {BLOCK_TYPE_CHECKLIST_TRIGGER} from 'services/steps';
import RadioGroup from '../../../../../../components/RadioGroup';
import ClickableInput from '../../components/items/ClickableInput';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import {positionsArr} from '../../components/modals/Position';
import Shadow from '../../components/modals/Shadow';
import Width from '../../components/modals/Width';
import PopupSetting from '../../components/PopupSetting';
import './_Styles.scss';

export const CHECKLIST_TRIGGER_TYPE_MINIMIZED = 'minimized';
export const CHECKLIST_TRIGGER_TYPE_FLOAT = 'float';
export const CHECKLIST_TRIGGER_PROGRESS_TYPE_CIRCULAR = 'circular';
export const CHECKLIST_TRIGGER_PROGRESS_TYPE_COUNT = 'count';

const progressOptions = [
  {label: 'Circular', value: CHECKLIST_TRIGGER_PROGRESS_TYPE_CIRCULAR},
  {label: 'Count', value: CHECKLIST_TRIGGER_PROGRESS_TYPE_COUNT},
];

const shapeOptions = [
  {
    label: (
      <>
        <i className="icon-slot-dot" />
        Minimized
      </>
    ),
    value: CHECKLIST_TRIGGER_TYPE_MINIMIZED,
  },
  {
    label: (
      <>
        <i className="icon-hotspot-shape-1" />
        Float
      </>
    ),
    value: CHECKLIST_TRIGGER_TYPE_FLOAT,
  },
];

const ChecklistTrigger = () => {
  const {
    controlledEvolution: evolution,
    setControlledEvolution: setEvolution,
    updateBlock: uptBlock,
    selectedStep: step,
  } = useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_CHECKLIST_TRIGGER, updateObj);
  };

  const block = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_TRIGGER
  );

  if (block == null) {
    return <></>;
  }

  const {value = '', style = {}} = block;

  const selectedAlignment = positionsArr.find(
    (o) => o.value === evolution?.boostedPositionFlags
  );

  return (
    <div className="block-settings checklist-trigger">
      {/* <div className="section">
        <div className="section-title">General</div>
        <div className="section-content">
          <div className="section-item change-icon-type">
            <div className="section-item-title">Type</div>
            <div className="section-item-content">
              <SelectGroup
                isSearchable={false}
                classNamePrefix="type-icon-select"
                options={shapeOptions}
                value={shapeOptions.find((o) => o.value === style.type)}
                onChange={(option) => {
                  updateBlock({
                    style: {
                      ...style,
                      type: option.value,
                    },
                  });
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
          {style.type === CHECKLIST_TRIGGER_TYPE_FLOAT && (
            <div className="section-item">
              <div className="section-item-title">CTA label</div>
              <div className="section-item-content">
                <InputGroup
                  className="button-content-input"
                  value={value}
                  onChange={({target}) =>
                    updateBlock({
                      value: target.value,
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Divider /> */}
      {style.type === CHECKLIST_TRIGGER_TYPE_FLOAT && (
        <>
          <div className="section">
            <div className="section-title">Progress</div>
            <div className="section-content">
              <div className="section-item">
                <div className="section-item-title">Type</div>
                <div className="section-item-content">
                  <RadioGroup
                    value={style.progressType}
                    options={progressOptions}
                    onSelect={(value) => {
                      updateBlock({
                        style: {
                          ...style,
                          progressType: value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <Divider />
        </>
      )}

      <div className="section">
        <div className="section-title">Position</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Placement</div>
            <div className="section-item-content snippet-position-picker">
              <div className="alignment-figure-wrapper">
                <div className="alignment-figure">
                  <div className="alignment-label">
                    {selectedAlignment != null ? (
                      <>Always show from {selectedAlignment.position}</>
                    ) : (
                      <>Select a position</>
                    )}
                  </div>
                  {positionsArr.map((o) => {
                    if (o.value >= 128 || o.value === 8) {
                      return <></>;
                    }
                    return (
                      <div
                        className={classNames('position-dot', o.position, {
                          selected: evolution?.boostedPositionFlags === o.value,
                        })}
                        onClick={() =>
                          setEvolution({
                            ...evolution,
                            boostedPositionFlags: o.value,
                          })
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {style.type === CHECKLIST_TRIGGER_TYPE_MINIMIZED && (
            <div className="section-item">
              <div className="section-item-title">Width</div>
              <div className="section-item-content">
                <PopupSetting
                  trigger={
                    <ClickableInput
                      value={
                        style.width != null ? `${style.width}px` : 'Default'
                      }
                    />
                  }
                  title="Width"
                  content={
                    <Width
                      value={style.width}
                      onChange={(value) =>
                        updateBlock({
                          style: {
                            ...style,
                            width: value,
                          },
                        })
                      }
                    />
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Divider />
      {style.type === CHECKLIST_TRIGGER_TYPE_FLOAT && (
        <>
          <div className="section">
            <div className="section-title">Style</div>
            <div className="section-content">
              <div className="section-item">
                <div className="section-item-title">Background</div>
                <div className="section-item-content background-picker">
                  <ColorPickerInput
                    title="Background color"
                    value={style.backgroundColor || null}
                    onChange={(value) =>
                      updateBlock({
                        style: {
                          ...style,
                          backgroundColor: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className="section-item">
                <div className="section-item-title">Shadow</div>
                <div className="section-item-content shadow-picker">
                  <PopupSetting
                    trigger={
                      <ClickableInput
                        value={style.shadow?.color}
                        leftLabel={
                          <span
                            className="preview-shadow-color"
                            style={{
                              background: style.shadow?.color || '#FFFFFF',
                            }}
                          />
                        }
                        erasable={true}
                        onErase={() =>
                          updateBlock({
                            style: {
                              ...style,
                              shadow: {
                                color: null,
                                opacity: 0,
                                x: 0,
                                y: 0,
                                blur: 0,
                              },
                            },
                          })
                        }
                      />
                    }
                    title="Shadow"
                    content={
                      <Shadow
                        value={style.shadow}
                        onChange={(value) =>
                          updateBlock({
                            style: {
                              ...style,
                              shadow: value,
                            },
                          })
                        }
                      />
                    }
                  />
                </div>
              </div>
              <div className="section-item">
                <div className="section-item-title">Radius</div>
                <div className="section-item-content">
                  <PixelPicker
                    value={style.borderRadius}
                    min={0}
                    max={50}
                    onChange={(value) =>
                      updateBlock({
                        style: {
                          ...style,
                          borderRadius: value,
                        },
                      })
                    }
                    radius
                  />
                </div>
              </div>
            </div>
          </div>
          <Divider />
        </>
      )}
    </div>
  );
};

export default ChecklistTrigger;
