import classNames from 'classnames';
import Markdown from 'markdown-to-jsx';
import React, {useContext, useEffect} from 'react';
import sanitizeHtml from 'sanitize-html';
import {decodeHtml} from '../../../../../Poke/components/BlockBody';
import {VariableToSpan} from '../../../../../Poke/components/BlockTitle';
import ClickableBlockOverlay from '../../../../../Poke/components/ClickableBlockOverlay';
import {BLOCK_CHECKLIST_TASK_ITEM_DESCRIPTION} from '../../../../../Poke/constants/blocks';
import {PokeContext, PokeStateContext} from '../../../../../Poke/context';
import './_Styles.scss';

const TaskParagraph = ({block}) => {
  const {user} = useContext(PokeContext);
  const {
    onBlockSelected,
    onTaskSelected,
    selectedBlock,
    selectedTaskId,
    inBuilder,
    language,
    onUrlClick,
    addFontFamily,
  } = useContext(PokeStateContext);

  const {fontColor, fontFamily, ...restStyle} = block.style;

  useEffect(() => {
    addFontFamily(fontFamily);
  }, [fontFamily]);

  const translation = block.translations?.find((t) => t.language === language);

  const sanitizedContent = sanitizeHtml(
    translation != null
      ? decodeHtml(translation.value)
      : decodeHtml(block.value),
    {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([
        'img',
        'iframe',
        'ins',
        'del',
        'variable',
      ]),
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        a: ['href', 'target'],
        variable: ['class', 'data-attribute', 'data-fallback', 'data-source'],
      },
    }
  );

  document.documentElement.style.setProperty(
    '--checklist-block-body-font-size',
    `${restStyle.fontSize}px`
  );

  const ATagtoDiv = ({children, ...props}) => {
    const {href} = props;
    return (
      <span className="a-div" onClick={() => onUrlClick(href)}>
        {children}
      </span>
    );
  };

  return (
    <div
      className={classNames('task-paragraph', {
        'poke-block-clickable': inBuilder === true,
        selected:
          selectedBlock === BLOCK_CHECKLIST_TASK_ITEM_DESCRIPTION &&
          selectedTaskId === block.parentBlockId,
      })}
      style={{
        ...restStyle,
        fontFamily,
        color: fontColor,
      }}
      onClick={(e) => {
        if (inBuilder === true) {
          e.stopPropagation();
          onBlockSelected(BLOCK_CHECKLIST_TASK_ITEM_DESCRIPTION);
          onTaskSelected(block.parentBlockId);
        }
      }}>
      <Markdown
        className="body-markdown"
        options={{
          overrides: {
            a: {
              component: ATagtoDiv,
            },
            variable: {
              component: (props) => (
                <VariableToSpan {...props} user={user} inBuilder={inBuilder} />
              ),
            },
          },
        }}>
        {sanitizedContent}
      </Markdown>
      <ClickableBlockOverlay />
    </div>
  );
};

export default TaskParagraph;
