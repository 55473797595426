import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import {BuilderContext} from 'contextes/builder';
import {useContext, useState} from 'react';
import {DropdownAddAction} from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/TriggerActions';
import {
  BLOCK_TYPE_CHECKLIST_HEADER,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM,
  STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
  STEP_CONDITION_ACTION_TYPE_DISMISS,
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
  STEP_CONDITION_ACTION_TYPE_OPEN_POST,
  STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
  STEP_CONDITION_ACTION_TYPE_SKIP_TASK,
} from 'services/steps';
import {
  BLOCK_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
  BLOCK_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
} from 'shared/front/components/Poke/constants/blocks';
import {v4 as uuidv4} from 'uuid';
import ClickableInput from '../../components/items/ClickableInput';
import {ButtonStyle} from '../Button';
import ButtonAction from '../Button/components/ButtonAction';
import './_Styles.scss';

const ChecklistItemButton = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    updateStep,
    selectedBlockType,
    selectedChecklistItemId,
  } = useContext(BuilderContext);

  const [newlyAddedAction, setNewlyAddedAction] = useState(null);

  const checklistHeaderBlock = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_CHECKLIST_HEADER
  );

  const block = step?.blocks.find(
    (b) =>
      b.type === selectedBlockType &&
      ((selectedChecklistItemId == null &&
        [null, checklistHeaderBlock?.uid].includes(b.parentBlockId)) ||
        selectedChecklistItemId === b.parentBlockId)
  );
  const taskBlock = step?.blocks.find(
    (b) =>
      b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM && b.uid === block.parentBlockId
  );

  const isPrimaryCta =
    selectedBlockType === BLOCK_CHECKLIST_TASK_ITEM_PRIMARY_CTA;
  const isSecondaryCta =
    selectedBlockType === BLOCK_CHECKLIST_TASK_ITEM_SECONDARY_CTA;

  const actionsToEdit = isPrimaryCta
    ? taskBlock?.actions || []
    : block?.actions || [];

  const updateBlock = (updateObj, all = false) => {
    if (all !== true && block?.parentBlockId != null) {
      uptBlock(selectedBlockType, updateObj, {
        parentBlockId: block.parentBlockId,
      });
    } else {
      uptBlock(selectedBlockType, updateObj);
    }
  };

  const handleAddAction = (type) => {
    const newAction = {
      uid: uuidv4(),
      type,
    };

    if (isPrimaryCta) {
      updateStep(step.uid, {
        blocks: step.blocks.map((block) =>
          block.uid === taskBlock.uid
            ? {
                ...block,
                actions: [...(taskBlock.actions || []), newAction],
              }
            : block
        ),
      });
    } else {
      updateBlock({
        actions: [...actions, newAction],
      });
    }
    setNewlyAddedAction(newAction);
  };

  if (block == null) {
    return <></>;
  }

  const {value, style, actions = []} = block;

  let authorizedActions = [
    STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
    STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
    STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
    ...(isSecondaryCta ? [STEP_CONDITION_ACTION_TYPE_SKIP_TASK] : []),
  ].filter(
    (a) => actionsToEdit.map((action) => action.type).includes(a) !== true
  );

  if (
    actionsToEdit
      .map((action) => action.type)
      .some((t) =>
        [
          STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
          STEP_CONDITION_ACTION_TYPE_OPEN_POST,
          STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
          STEP_CONDITION_ACTION_TYPE_DISMISS,
        ].includes(t)
      ) === true
  ) {
    authorizedActions = authorizedActions.filter(
      (a) =>
        [
          STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
          STEP_CONDITION_ACTION_TYPE_OPEN_POST,
          STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
          STEP_CONDITION_ACTION_TYPE_DISMISS,
        ].includes(a) !== true
    );
  }
  if (
    actionsToEdit
      .map((action) => action.type)
      .some((t) => [STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW].includes(t)) ===
    true
  ) {
    authorizedActions = authorizedActions.filter(
      (a) => a !== STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO
    );
  }

  return (
    <div className="block-settings checklist-item-button">
      <div className="section">
        <div className="section-title">Content</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">CTA label</div>
            <div className="section-item-content">
              <InputGroup
                className="button-content-input"
                value={value}
                onChange={({target}) =>
                  updateBlock({
                    value: target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title actions">
          Actions
          {isPrimaryCta && (
            <div className="section-description body-4 n-700">
              Primary CTA action will match the Task action
            </div>
          )}
        </div>

        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">
              {isPrimaryCta ? 'Task actions' : 'CTA actions'}
            </div>
            <div className="section-item-content action-picker">
              <div className="actions-wrapper">
                {actionsToEdit.map((action) => {
                  return (
                    <ButtonAction
                      key={action.uid}
                      action={action}
                      setAction={(updatedAction) => {
                        if (isPrimaryCta) {
                          updateStep(step.uid, {
                            blocks: step.blocks.map((block) =>
                              block.uid === taskBlock.uid
                                ? {
                                    ...block,
                                    actions: taskBlock.actions.map((_action) =>
                                      _action.uid === action.uid
                                        ? updatedAction
                                        : _action
                                    ),
                                  }
                                : block
                            ),
                          });
                        } else {
                          updateBlock({
                            actions: actions.map((_action) =>
                              _action.uid === action.uid
                                ? updatedAction
                                : _action
                            ),
                          });
                        }
                      }}
                      onDelete={() => {
                        if (isPrimaryCta) {
                          updateStep(step.uid, {
                            blocks: step.blocks.map((block) =>
                              block.uid === taskBlock.uid
                                ? {
                                    ...block,
                                    actions: taskBlock.actions.filter(
                                      (_action) => _action.uid !== action.uid
                                    ),
                                  }
                                : block
                            ),
                          });
                        } else {
                          updateBlock({
                            actions: actions.filter(
                              (_action) => _action.uid !== action.uid
                            ),
                          });
                        }
                      }}
                      defaultOpen={newlyAddedAction?.uid === action.uid}
                    />
                  );
                })}
                <DropdownAddAction
                  className="add-action-element-button"
                  authorizedActions={authorizedActions}
                  usedActionTypes={actionsToEdit.map((action) => action.type)}
                  onAddAction={handleAddAction}
                  position="left top"
                  offsetY={-4}
                  offsetX={8}
                  trigger={
                    <ClickableInput
                      className="add-action-element-placeholder-btn"
                      inputProps={{placeholder: 'Add action...'}}
                      leftLabel={
                        <div className="action-icon-wrapper">
                          <i className="isax isax-flash-15" />
                        </div>
                      }
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">
          <i className="isax isax-main-component5" />
          Style
          <div className="section-title-description">
            <div className="body-4 n-700">For every</div>
            <div className="block-group subtitle-4 n-800">
              <div className="icon-wrapper">
                <i className="icon-pointer" />
              </div>
              {isPrimaryCta ? 'Primary CTA' : 'Secondary CTA'}
            </div>
          </div>
        </div>
        <div className="section-content">
          <ButtonStyle
            style={style}
            updateStyle={(value) => updateBlock(value, true)}
            hideAlign
          />
        </div>
      </div>
    </div>
  );
};

export default ChecklistItemButton;
