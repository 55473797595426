export const ROUTE_REGISTER = '/register';
export const ROUTE_REGISTER_EMAIL_SENT = '/register/sent';
export const ROUTE_REGISTER_SETUP_PASSWORD = '/register/setup';
export const ROUTE_LOGIN = '/enter';
export const ROUTE_ONBOARDING_DETAILS_STEP_1_1 = '/details/1-1';
export const ROUTE_ONBOARDING_DETAILS_STEP_1_2 = '/details/1-2';
export const ROUTE_ONBOARDING_DETAILS_STEP_2_1 = '/details/2-1';
export const ROUTE_ONBOARDING_DETAILS_STEP_2_2 = '/details/2-2';
export const ROUTE_ONBOARDING_DETAILS_STEP_2_3 = '/details/2-3';
export const ROUTE_ONBOARDING_DETAILS_STEP_3_1 = '/details/3-1';
export const ROUTE_ONBOARDING_DETAILS_JOIN = '/details/join';
export const ROUTE_LOGIN_WITH_JOINING = (invitationId, projectId) =>
  `/enter?invitationId=${invitationId}&goToProject=${projectId}`;
export const ROUTE_LOGIN_WITH_CODE = '/logincode';
export const ROUTE_LOGOUT = '/cya';
export const ROUTE_RECOVERY_PASSWORD = '/forgotpass';
export const ROUTE_JOIN = (invitationCode) => `/join/${invitationCode}`;
export const ROUTE_JOIN_REGISTER = (invitationCode) =>
  `/join/${invitationCode}/register`;
export const ROUTE_ONBOARDING_PROJECT = '/newproject';
export const ROUTE_ONBOARDING_WAITING = '/waiting';

export const ROUTE_SETTINGS = '/settings';
export const ROUTE_SETTINGS_THEMES = '/settings/themes';
export const ROUTE_SETTINGS_THEMES_ID = (themeId = ':themeId') =>
  `/settings/themes/${themeId}`;
export const ROUTE_SETTINGS_CHANGELOG = '/settings/changelog';
export const ROUTE_SETTINGS_INSTALLATION = '/settings/install';
export const ROUTE_SETTINGS_INSTALLATION_IDENTIFICATION =
  '/settings/install/identify';
export const ROUTE_SETTINGS_INTEGRATIONS = '/settings/integrations';
export const ROUTE_SETTINGS_POKE_CONTROL = '/settings/control';
export const ROUTE_SETTINGS_INTEGRATIONS_TYPEFORM =
  '/settings/integrations/typeform';
export const ROUTE_SETTINGS_INTEGRATIONS_INTERCOM =
  '/settings/integrations/intercom';
export const ROUTE_SETTINGS_INTEGRATIONS_SEGMENT =
  '/settings/integrations/segmentio';
export const ROUTE_SETTINGS_INTEGRATIONS_SLACK = '/settings/integrations/slack';
export const ROUTE_SETTINGS_INTEGRATIONS_HUBSPOT =
  '/settings/integrations/hubspot';
export const ROUTE_SETTINGS_INTEGRATIONS_CIO = '/settings/integrations/cio';
export const ROUTE_SETTINGS_INTEGRATIONS_ZAPIER =
  '/settings/integrations/zapier';
export const ROUTE_SETTINGS_NOTIFICATION = '/settings/notification';
export const ROUTE_SETTINGS_TEAM = '/settings/team';
export const ROUTE_SETTINGS_PROFILE = '/settings/profile';
export const ROUTE_SETTINGS_PLAN = '/settings/plan';
export const ROUTE_SETTINGS_BILLING = '/settings/billing';
export const ROUTE_SETTINGS_SUBSCRIPTION_PLAN = '/settings/subscription/plan';
export const ROUTE_SETTINGS_SUBSCRIPTION_INVOICES =
  '/settings/subscription/invoices';
export const ROUTE_SETTINGS_SUBSCRIPTION_CARDS = '/settings/subscription/cards';
export const ROUTE_SETTINGS_SUBSCRIPTION_COUPON =
  '/settings/subscription/coupon';
export const ROUTE_SETTINGS_GENERAL = '/settings';

export const ROUTE_REDIRECT_INTERCOM = '/redirect/intercom';

export const ROUTE_GET_STARTED = '/getstarted';
export const ROUTE_GET_STARTED_TRY = '/getstarted/try';
export const ROUTE_GET_STARTED_INSTALL = '/getstarted/install';
export const ROUTE_GET_STARTED_TRIAL = '/getstarted/trial';
export const ROUTE_TEMPLATES = '/templates';

export const ROUTE_DRAFT = '/requests';
export const ROUTE_DRAFT_WITH_ID = (requestId = ':requestId') =>
  `/requests/${requestId}`;

export const ROUTE_IDEAS = '/ideas';
export const ROUTE_HOME = '/';
export const ROUTE_FEED = '/feed';
export const ROUTE_POKES = '/pokes';
export const ROUTE_TOURS = '/tours';
export const ROUTE_SURVEYS = '/surveys';
export const ROUTE_BANNERS = '/banners';
export const ROUTE_HINTS = '/hints';
export const ROUTE_CHECKLISTS = '/checklists';
export const ROUTE_FEED_EVOLUTION = (evolutionId = ':evolutionId') =>
  `/feed/${evolutionId}`;
export const ROUTE_ROADMAP_EVOLUTION = (evolutionId = ':evolutionId') =>
  `/roadmap/${evolutionId}`;

export const ROUTE_POKE_WITH_ID_AND_TYPE = (
  type = ':type',
  pokeId = ':pokeId'
) => {
  return `/${type.toLowerCase() + 's'}/${pokeId}`;
};

export const ROUTE_TOUR_WITH_ID = (
  tourId = `:evolutionId`,
  {showSuccess = false, showSuccessWithPost = false} = {}
) =>
  `/tours/${tourId}`
    .concat(showSuccess ? '?showSuccess=true' : '')
    .concat(showSuccessWithPost ? '?showSuccessWithPost=true' : '');

export const ROUTE_SURVEY_WITH_ID = (
  surveyId = `:evolutionId`,
  {showSuccess = false, showSuccessWithPost = false} = {}
) =>
  `/surveys/${surveyId}`
    .concat(showSuccess ? '?showSuccess=true' : '')
    .concat(showSuccessWithPost ? '?showSuccessWithPost=true' : '');

export const ROUTE_BANNER_WITH_ID = (
  bannerId = `:evolutionId`,
  {showSuccess = false, showSuccessWithPost = false} = {}
) =>
  `/banners/${bannerId}`
    .concat(showSuccess ? '?showSuccess=true' : '')
    .concat(showSuccessWithPost ? '?showSuccessWithPost=true' : '');
export const ROUTE_CHECKLIST_WITH_ID = (
  checklistId = `:evolutionId`,
  {showSuccess = false} = {}
) =>
  `/checklists/${checklistId}`.concat(showSuccess ? '?showSuccess=true' : '');

export const ROUTE_HINT_WITH_ID = (
  hintId = `:evolutionId`,
  {showSuccess = false, showSuccessWithPost = false} = {}
) =>
  `/hints/${hintId}`
    .concat(showSuccess ? '?showSuccess=true' : '')
    .concat(showSuccessWithPost ? '?showSuccessWithPost=true' : '');

export const ROUTE_TOUR_SETTINGS_WITH_ID = (tourId = `:evolutionId`) =>
  `/tours/${tourId}/settings`;

export const ROUTE_SURVEY_SETTINGS_WITH_ID = (surveyId = `:evolutionId`) =>
  `/surveys/${surveyId}/settings`;

export const ROUTE_BANNER_SETTINGS_WITH_ID = (bannerId = `:evolutionId`) =>
  `/banners/${bannerId}/settings`;

export const ROUTE_HINT_SETTINGS_WITH_ID = (hintId = `:evolutionId`) =>
  `/hints/${hintId}/settings`;
export const ROUTE_CHECKLIST_SETTINGS_WITH_ID = (hintId = `:evolutionId`) =>
  `/checklists/${hintId}/settings`;

export const ROUTE_BOOSTED_WITH_ID = (evolutionId = `:evolutionId`) =>
  `/boosted/${evolutionId}`;
export const ROUTE_POSTS_ALL = '/all';
export const ROUTE_POSTS_ALL_WITH_ID = (evolutionId = ':evolutionId') =>
  `/all/${evolutionId}`;

export const ROUTE_LIVE_FOR_VOTE = '/liveforvote';
export const ROUTE_LIVE_FOR_VOTE_WITH_ID = (evolutionId = ':evolutionId') =>
  `/liveforvote/${evolutionId}`;

export const ROUTE_FEATURE_PREVIEW = '/featurepreview';
export const ROUTE_FEATURE_PREVIEW_WITH_ID = (evolutionId = ':evolutionId') =>
  `/featurepreview/${evolutionId}`;
export const ROUTE_MOVE_FEATURE_PREVIEW = (evolutionId = 'new') =>
  `/move/featurepreview/${evolutionId}`;

export const ROUTE_RELEASE = '/release';
export const ROUTE_RELEASE_WITH_ID = (evolutionId = ':evolutionId') =>
  `/release/${evolutionId}`;
export const ROUTE_MOVE_RELEASE = (evolutionId = 'new') =>
  `/move/release/${evolutionId}`;

export const ROUTE_SWITCH_PROJECT = (projectId) => `/switch/${projectId}`;

export const ROUTE_USERS = '/users';
export const ROUTE_USERS_IMPORTS = '/users/imports';
export const ROUTE_USERS_IMPORT_CSV = '/users/import/csv';
export const ROUTE_USERS_IMPORT_CSV_WITH_ID = (importId = ':importId') =>
  `/users/import/csv/${importId}`;
export const ROUTE_SEGMENTS = '/segments';
export const ROUTE_USERS_SEGMENT_WITH_ID = (segmentId = ':segmentId') =>
  `/segments/${segmentId}`;

export const ROUTE_EVENTS = '/events';
export const ROUTE_EVENTS_WITH_ID = (eventId = ':eventId') =>
  `/events/${eventId}`;

export const ROUTE_ROADMAP = '/roadmap';

export const ROUTE_SURVEY = '/survey';
export const ROUTE_CREATE_SURVEY = (evolutionId = 'new') =>
  `/move/survey/${evolutionId}`;

export const ROUTE_BUILDER_CREATE = (
  {evolutionId, template, fromPoke, fromFeed, fromRoadmap, fromIdeas, tagId} = {
    evolutionId: ':evolutionId',
    template: null,
    fromPoke: false,
    fromFeed: false,
    fromRoadmap: false,
    fromIdeas: false,
    tagId: null,
  }
) =>
  `/builder/${evolutionId}`
    .concat(
      template != null ||
        fromPoke === true ||
        fromFeed === true ||
        fromRoadmap === true ||
        fromIdeas === true ||
        tagId != null
        ? '?'
        : ''
    )
    .concat(template != null ? `template=${template}&` : '')
    .concat(fromPoke === true ? 'fromPoke=true&' : '')
    .concat(fromFeed === true ? 'fromFeed=true&' : '')
    .concat(fromRoadmap === true ? 'fromRoadmap=true&' : '')
    .concat(fromIdeas === true ? 'fromIdeas=true&' : '')
    .concat(tagId != null ? `tag=${tagId}&` : '');
export const ROUTE_BUILDER_CHANNEL = (evolutionId = ':evolutionId') =>
  `/builder/${evolutionId}/channel`;
export const ROUTE_BUILDER_AUDIENCE = (evolutionId = ':evolutionId') =>
  `/builder/${evolutionId}/audience`;
export const ROUTE_BUILDER_EDIT = (evolutionId = ':evolutionId') =>
  `/builder/${evolutionId}/edit`;
export const ROUTE_POKE_BUILDER_FROM_TYPE = (
  evolutionId = ':evolutionId',
  type = ':type',
  opts
) => {
  let route = `/poke-builder/${evolutionId}/${type.toLowerCase()}`;

  if (opts?.stepId != null) {
    route += `?stepId=${opts.stepId}`;
  }
  return route;
};
export const ROUTE_POKE_SETTINGS_FROM_TYPE = (
  evolutionId = ':evolutionId',
  type = ':type'
) => {
  return `/${type.toLowerCase()}s/${evolutionId}/settings`;
};
export const ROUTE_CHANGELOG_BUILDER = `/changelog-builder`;
export const ROUTE_CHANGELOG_BUILDER_WITH_ID = (evolutionId = ':evolutionId') =>
  `/changelog-builder${evolutionId != null ? `/${evolutionId}` : ''}`;
export const ROUTE_POST_BUILDER_WRITER = (evolutionId = ':evolutionId') =>
  `/post-builder/${evolutionId}/post`;
export const ROUTE_POST_BUILDER_AUDIENCE = (evolutionId = ':evolutionId') =>
  `/post-builder/${evolutionId}/audience`;

/** Tricky routes */
export const ROUTE_MOVE_LIVE_FOR_VOTE = (
  evolutionId = 'new',
  fromRequest = null // Pass a request id to create an evolution from the request
) => {
  let route = `/move/liveforvote/${evolutionId}`;

  if (fromRequest != null) {
    route += `?fromRequest=${fromRequest}`;
  }
  return route;
};

/** Only used in local env */
export const ROUTE_DEV = '/_dev';

export const ROUTE_LIGHTWEIGHT_ELEMENT_SELECTOR_BUILDER =
  '/lightweight-builder/element-selector';
export const ROUTE_LIGHTWEIGHT_POKE_BUILDER = '/lightweight-builder/poke';
export const ROUTE_LIGHTWEIGHT_POKE_BUILDER_WITH_ID = (
  evolutionId = ':evolutionId'
) => `/lightweight-builder/poke/${evolutionId}`;
export const ROUTE_LIGHTWEIGHT_EXTENSION_BUILDER =
  '/lightweight-builder/extension';

export const ROUTE_LIGHTWEIGHT_TRACKER_BUILDER_WITH_ID = (
  trackerId = ':trackerId'
) => `/lightweight-builder/tracker/${trackerId}`;

export const ROUTE_SUCCESS_TRACKER = '/success-tracker';
export const ROUTE_BUILDER_TRACKER = (
  trackerId = ':trackerId',
  {eventId} = {}
) => {
  let route = `/tracker-builder/${trackerId}`;

  if (eventId != null) {
    route += `?eventId=${eventId}`;
  }
  return route;
};
export const ROUTE_TRACKER_REPORT = (trackerId = ':trackerId') =>
  `/success-tracker/${trackerId}`;
