import Axios from 'axios';
import {generalSelector} from 'selectors';

export const QUERY_TYPE_ALL = 'ALL';
export const QUERY_TYPE_NEW = 'NEW';
export const QUERY_TYPE_ACTIVE = 'ACTIVE';
export const QUERY_TYPE_RECENTLY_ACTIVE = 'RECENTLY_ACTIVE';

export const JimerActivityType = {
  VIEWS: 'views',
  CLICKS: 'clicks',
  COMMENTS: 'comments',
  VOTES: 'votes',
};

// Endpoint
const EP_JIMERS_GET = '/jimer';
const EP_JIMER_GET_BY_ID = (jimerId) => `/jimer/${jimerId}`;
const EP_JIMER_GET_ACTIVITY = (jimerId) => `/jimer/${jimerId}/activity`;
const EP_JIMER_DELETE = (jimerId) => `/jimer/${jimerId}`;
const EP_JIMER_PUT_TAGS = (jimerId) => `/jimer/${jimerId}/tags`;

// Method
export const getJimers = (data, {signal = null}) => {
  const {
    attributes,
    logic,
    segmentIds,
    evolutionId,
    trackerId,
    stepId,
    blockId,
    take,
    skip,
    exportCsv,
    startDate,
    endDate,
    isChangelogLike,
    eventType,
  } = data;
  const projectId = generalSelector.getProject().uid;

  // Use POST here since the list of attributes can make the URL too long
  return Axios.post(
    EP_JIMERS_GET,
    {
      ...(attributes != null
        ? {
            attributes: JSON.parse(JSON.stringify(attributes)).map((a) => {
              delete a.evolution;
              delete a.step;
              return a;
            }),
          }
        : {}), // deep clone
      logic,
      segmentIds,
      evolutionId,
      trackerId,
      stepId,
      blockId,
      take,
      skip,
      projectId,
      exportCsv,
      startDate,
      endDate,
      isChangelogLike,
      eventType,
    },
    {
      signal: signal,
    }
  ).then((response) => response.data);
};

export const getJimer = (jimerId, data) => {
  const {relations} = data;
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_JIMER_GET_BY_ID(jimerId), {
    params: {projectId, relations},
  }).then((response) => response.data);
};

export const getJimerActivity = (jimerId, data = {}) => {
  const {take, skip} = data;
  const projectId = generalSelector.getProject().uid;

  return Axios.get(EP_JIMER_GET_ACTIVITY(jimerId), {
    params: {take, skip, projectId},
  }).then((response) => response.data);
};

export const deleteJimer = (jimerId) => {
  return Axios.delete(EP_JIMER_DELETE(jimerId)).then(
    (response) => response.data
  );
};

export const updateJimerTags = (jimerId, tags) => {
  return Axios.put(EP_JIMER_PUT_TAGS(jimerId), {tags}).then(
    (response) => response.data
  );
};
