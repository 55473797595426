import {default as Axios, default as axios} from 'axios';
import {hasFlag} from 'helpers/bitwise';
import {dataSelector, generalSelector} from 'selectors';
import {COUPON_TYPE_SAAS_MANTRA_OFFER_1} from './coupon';

export const PLAN_BASIC_ID = 'BASIC';
export const PLAN_STARTUP_ID = 'STARTUP';
export const PLAN_GROWTH_ID = 'GROWTH';
export const PLAN_SCALE_ID = 'SCALE';
export const ADDON_WHITE_LABEL_ID = 'WHITE_LABEL';
export const ADDON_MAU = 'MAU';
export const ADDON_CUSTOM_MAU_ID = 'ADDON_CUSTOM_MAU'; // For customers that have a custom mau
export const ADDON_EXTRA_MAU_ID = 'ADDON_EXTRA_MAU'; // Deprecated
export const UNLIMITED_JIMERS = -1;
export const UNLIMITED_SEATS = -1;
export const UNLIMITED_VISITORS = -1;
export const CURRENCY_USD = 'USD';
export const CURRENCY_EUR = 'EUR';
export const F_STARTUP_TRIAL_USED = 1;
export const F_GROWTH_TRIAL_USED = 2;
export const F_IS_TRIALING = 4;
export const F_IS_PAYING = 8;
export const F_MODE_ANNUAL_BILLING = 64;
export const F_IS_OFFERED_BY_JIMO = 128;
export const F_HAS_ADDON_EXTRA_MAU = 256;
export const F_HAS_ADDON_CUSTOM_MAU = 512;
export const F_PRICING_V0 = 1024;

// Endpoints
const EP_SUBSCRIPTION_CREATE_UPDATE_STRIPE_SUB =
  '/subscription/stripe/subscription';
const EP_SUBSCRIPTION_GET_PLAN = '/subscription/plans';
const EP_SUBSCRIPTION_GET_ADDONS = '/subscription/addons';
const EP_SUBSCRIPTION_GET_STRIPE_INVOICES = '/subscription/stripe/invoices';
const EP_SUBSCRIPTION_GET_BY_ID = (subscriptionId) =>
  `/subscription/${subscriptionId}`;
const EP_SUBSCRIPTION_INIT = '/subscription/init';
const EP_SUBSCRIPTION_UPT_BY_ID = (subscriptionId) =>
  `/subscription/${subscriptionId}`;
const EP_SUBSCRIPTION_STRIPE_CUSTOMER_UPT = `/subscription/stripe/customer`;
const EP_SUBSCRIPTION_STRIPE_PORTAL_SESSION_CREATE = `/subscription/stripe/portal-session`;
const EP_SUBSCRIPTION_STRIPE_GET_TAX = '/subscription/stripe/tax';
const EP_SUBSCRIPTION_STRIPE_GET_PRORATION = '/subscription/stripe/proration';
const EP_SUBSCRIPTION_STRIPE_GET_UPCOMING_INVOICE =
  '/subscription/stripe/upcoming-invoice';
const EP_SUBSCRIPTION_STRIPE_PURCHASE_ADDON_MAU =
  '/subscription/stripe/purchase-addon-mau';
const EP_SUBSCRIPTION_STRIPE_PRORATION_ADDON_EXTRA_MAU =
  '/subscription/stripe/purchase-addon-mau';
const EP_SUBSCRIPTION_SET_CURRENCY = (subscriptionId) =>
  `/subscription/${subscriptionId}/currency`;

// Utils
/**
 * Determine if the passed subscription will upgrade the existing one
 * @param {nextPlanId} nextSubscription
 * @returns
 */
export const isUpgrade = (nextPlanId, nextMau) => {
  const projectSubscription = generalSelector.getProjectSubscription();
  const plans = dataSelector.getSubscriptionsPlans();
  const currentPlan = plans.find((p) => p.uid === projectSubscription.plan);
  const nextPlan = plans.find((p) => p.uid === nextPlanId);

  if (projectSubscription.plan === PLAN_BASIC_ID) {
    return true;
  }
  if (currentPlan?.uid !== nextPlan.uid) {
    return currentPlan?.index < nextPlan.index;
  } else {
    return nextMau > projectSubscription.extraJimers;
  }
};

export const hasSaasMantraPlan = (project = generalSelector.getProject()) => {
  const hasSaasMantraCoupon = project.coupons.some(
    (c) => c.type === COUPON_TYPE_SAAS_MANTRA_OFFER_1
  );

  return hasSaasMantraCoupon;
};

export const getCurrentAddons = () => {
  const project = generalSelector.getProject();
  const addons = [];

  if (project.whiteLabeling === true) {
    addons.push(ADDON_WHITE_LABEL_ID);
  }
  return addons;
};

export const hasAddon = (addonId) => {
  const project = generalSelector.getProject();
  const subscription = generalSelector.getProjectSubscription();

  if (addonId === ADDON_WHITE_LABEL_ID) return project.whiteLabeling === true;
  if (addonId === ADDON_EXTRA_MAU_ID)
    return hasFlag(F_HAS_ADDON_EXTRA_MAU, subscription.extraFlags);
  return false;
};

export const hasAnnualBilling = () => {
  const subscription = generalSelector.getProjectSubscription();

  return hasFlag(F_MODE_ANNUAL_BILLING, subscription.extraFlags);
};

export const getPlanMonthlyPrice = (plan) => {
  return plan == null
    ? 0
    : plan.prices.find((price) => price.recurrence === 'month')?.unitAmount /
        100;
};

export const getPlanYearlyPrice = (plan) => {
  return plan == null
    ? 0
    : plan.prices.find((price) => price.recurrence === 'year')?.unitAmount /
        100;
};

export const getAddonMonthlyPrice = (addon) => {
  return (
    addon.prices.find((price) => price.recurrence === 'month')?.unitAmount / 100
  );
};

export const getAddonYearlyPrice = (addon) => {
  return (
    addon.prices.find((price) => price.recurrence === 'year')?.unitAmount /
    12 /
    100
  );
};

export const getMauPrice = (
  upTo,
  modeAnnual = false,
  addonMau = dataSelector
    .getSubscriptionsAddons()
    .find((addon) => addon.uid === ADDON_MAU)
) => {
  if (addonMau == null) {
    return;
  }

  const recurrence = modeAnnual === true ? 'year' : 'month';
  return Math.round(
    addonMau.prices
      .find((price) => price.recurrence === recurrence)
      .tiers.sort((a, b) => a.upTo - b.upTo)
      .reduce((tier, nextTier) => {
        if (tier == null) return nextTier;
        if (upTo <= nextTier.upTo && upTo > tier.upTo) return nextTier;
        if (nextTier.upTo == null && upTo > tier.upTo) return nextTier;
        return tier;
      }, null)?.unitAmount * upTo
  );
};

export const getSubscriptionPrices = ({
  plan,
  mau,
  addons = [],
  modeAnnualBilling,
  taxes = [],
  coupon = null,
}) => {
  // Price of Plan
  const planPrice =
    modeAnnualBilling === true
      ? getPlanYearlyPrice(plan)
      : getPlanMonthlyPrice(plan);

  // Price of MAU
  const mauPrice = getMauPrice(mau, modeAnnualBilling);

  // Price of Addons
  const addonsPrice = addons.reduce((sum, addon) => {
    let addonPrice =
      modeAnnualBilling === true
        ? getAddonYearlyPrice(addon)
        : getAddonMonthlyPrice(addon);

    if (
      addon.uid === ADDON_WHITE_LABEL_ID &&
      [PLAN_GROWTH_ID, PLAN_SCALE_ID].includes(plan.uid)
    ) {
      addonPrice = 0;
    }
    return sum + addonPrice;
  }, 0);

  // Total per month : Plan + MAU + Addons
  const totalPerMonth = planPrice + mauPrice + addonsPrice;

  // Coupon reduction
  const couponReduction =
    coupon == null
      ? 0
      : Math.round((coupon.stripeData.percent_off / 100) * totalPerMonth);

  // Subtotal : Total per month - Coupon reduction
  const subTotal = totalPerMonth - couponReduction;

  // Amount of money saved because of Yearly + coupon reduction
  const amountSaved =
    (modeAnnualBilling === true
      ? getSubscriptionPrices({
          plan,
          mau,
          addons,
          modeAnnualBilling: false,
          coupon,
        }).subtotal - subTotal
      : 0) + (coupon != null ? couponReduction : 0);

  // Total : Subtotal + taxes
  const total = Math.round(
    taxes.reduce(
      (sum, tax) => sum + subTotal * (tax.percentage / 100),
      subTotal
    )
  );

  return {
    planPrice,
    mauPrice,
    addonsPrice,
    couponReduction,
    subTotal,
    total,
    amountSaved,
  };
};

// Methods
export const createOrUpdateStripeSubscription = (data, extra = {}) => {
  const {plan, mau, addons, modeAnnual} = data;
  const {stripePromotionCodeId, isTrial} = extra;
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_SUBSCRIPTION_CREATE_UPDATE_STRIPE_SUB, {
    projectId,
    plan,
    mau,
    addons,
    modeAnnual,
    stripePromotionCodeId,
    isTrial,
  }).then((response) => response.data);
};
export const getSubscriptionPlans = () => {
  return Axios.get(EP_SUBSCRIPTION_GET_PLAN).then((response) => response.data);
};
export const getSubscriptionAddons = () => {
  return Axios.get(EP_SUBSCRIPTION_GET_ADDONS).then(
    (response) => response.data
  );
};
export const getInvoices = async () => {
  const projectId = generalSelector.getProject().uid;
  const stripeInvoices = await Axios.get(EP_SUBSCRIPTION_GET_STRIPE_INVOICES, {
    params: {projectId},
  }).then((response) => response.data);

  return stripeInvoices;
};
export const getSubscriptionById = async (subscriptionId, opts = {}) => {
  const {relations} = opts;

  return Axios.get(EP_SUBSCRIPTION_GET_BY_ID(subscriptionId), {
    params: {relations},
  }).then((response) => response.data);
};
export const initSubscription = async (projectId) => {
  projectId = projectId || generalSelector.getProject().uid;

  return Axios.post(EP_SUBSCRIPTION_INIT, {projectId}).then(
    (response) => response.data
  );
};
export const updateSubscription = async (subscriptionId, data) => {
  const {paymentMethodName} = data;

  return Axios.put(EP_SUBSCRIPTION_UPT_BY_ID(subscriptionId), {
    paymentMethodName,
  }).then((response) => response.data);
};
export const updateStripeCustomer = async (data) => {
  const {postalCode, countryCode, vatNumber, name} = data;
  const projectId = generalSelector.getProject().uid;

  return Axios.put(
    EP_SUBSCRIPTION_STRIPE_CUSTOMER_UPT,
    {
      postalCode,
      countryCode,
      vatNumber,
      name,
    },
    {params: {projectId}}
  ).then((response) => response.data);
};
export const createStripePortalSession = () => {
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_SUBSCRIPTION_STRIPE_PORTAL_SESSION_CREATE, {
    projectId,
  }).then((response) => response.data);
};

export const hasReachedPokeThreshold = () => {
  const subscription = generalSelector.getProjectSubscription();
  const project = generalSelector.getProject();

  return subscription.plan === PLAN_SCALE_ID
    ? false
    : project.countMonthlyPokePublished >= project.thresholdPokes;
};

export const getTaxData = (data) => {
  const {plan, modeAnnual, country, zip} = data;

  return Axios.get(EP_SUBSCRIPTION_STRIPE_GET_TAX, {
    params: {
      plan,
      modeAnnual,
      country,
      zip,
    },
  }).then((response) => response.data);
};

export const getProration = (data) => {
  const {plan, mau, addons, modeAnnual} = data;
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_SUBSCRIPTION_STRIPE_GET_PRORATION, {
    projectId,
    plan,
    mau,
    addons,
    modeAnnual,
  }).then((response) => response.data);
};

export const getUpcomingInvoice = () => {
  const projectId = generalSelector.getProject().uid;

  return Axios.post(EP_SUBSCRIPTION_STRIPE_GET_UPCOMING_INVOICE, {
    projectId,
  }).then((response) => response.data);
};

export const purchaseAddonMau = (projectId) => {
  return axios
    .post(EP_SUBSCRIPTION_STRIPE_PURCHASE_ADDON_MAU, {projectId})
    .then((response) => response.data);
};

export const getProrationAddonExtraMau = () => {
  const projectId = generalSelector.getProject().uid;

  return Axios.post(
    EP_SUBSCRIPTION_STRIPE_PRORATION_ADDON_EXTRA_MAU,
    {
      projectId,
    },
    {params: {proration: true}}
  ).then((response) => response.data);
};

export const setSubscriptionCurrency = async (subscriptionId) => {
  await axios
    .post(EP_SUBSCRIPTION_SET_CURRENCY(subscriptionId))
    .then((response) => response.data);
};

export const displayPrice = (price) => {
  const subscription = generalSelector.getProjectSubscription();

  return subscription.currency === CURRENCY_EUR ? `${price}€` : `$${price}`;
};
