import classnames from 'classnames';
import Button from 'components/Button';
import ColorPicker from 'components/ColorPicker';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import {TagButton} from 'components/TagButton';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {bool, func, string} from 'prop-types';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {tagService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import TagEditor from './tag-editor';

const logger = new Swaler('ModalTagManager');

const MODE_ADD = 'ADD';
const MODE_EDIT = 'EDIT';
const MODE_LIST = 'LIST';
const TEXTS = {
  textTitle: {
    [MODE_LIST]: 'Manage status',
    [MODE_ADD]: 'New status',
    [MODE_EDIT]: 'Edit status',
  },
  textEmptyList: "You don't have status yet!",
  textBtnAdd: 'new status',
  textInputNamePlaceholder: 'status',
  textPreview: 'Status example',
};

const propTypes = {
  isOpen: bool.isRequired,
  enableAdd: bool,
  enableEditColorPicker: bool,
  enableEditTagPreview: bool,
  onRequestClose: func,
  context: string.isRequired,
};

const defaultProps = {
  enableAdd: false,
  enableEditColorPicker: false,
  enableEditTagPreview: false,
  onRequestClose: () => {},
};

const ModalTagManager = ({
  isOpen,
  enableAdd,
  enableEditColorPicker,
  enableEditTagPreview,
  onRequestClose,
  context,
  ...rest
}) => {
  const [mode, setMode] = useState(MODE_LIST);
  const [inputName, setInputName] = useState('');
  const [inputColor, setInputColor] = useState('#1557DB');
  const [tagToEditUid, setTagToEditUid] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [languages, setLanguages] = useState([]);

  const {data: tags = [], refetch} = useQuery({
    queryKey: ['tags'],
    queryFn: () =>
      tagService.getTags({contexts: [context], relations: ['texts']}),
    enabled: isOpen,
  });

  useEffect(() => {
    if (isOpen === false) {
      setMode(MODE_LIST);
    }
  }, [isOpen]);

  useEffect(() => {
    setSelectedLanguage(null);
  }, [mode]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    if (mode === MODE_ADD) {
      try {
        await tagService.createTag({
          name: inputName,
          color: inputColor,
          texts: languages,
          context,
        });

        refetch();
        setMode(MODE_LIST);
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        logger.error('Creating tag failed with error ', code);
        return toastDanger([title, message], {actions});
      } finally {
        setIsSubmitting(false);
      }
    } else {
      try {
        await tagService.updateTag(tagToEditUid, {
          name: inputName,
          color: inputColor,
          texts: languages,
        });

        refetch();
        setMode(MODE_LIST);
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        logger.error('Updating tag failed with error ', code);
        return toastDanger([title, message], {
          actions,
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleAddLanguage = (languageCode) => {
    setLanguages([
      ...(languages || []),
      {
        language: languageCode,
        name: '',
      },
    ]);
  };
  const handleRemoveLanguage = (languageCode) => {
    setLanguages(languages.filter((t) => t.language !== languageCode));
  };
  const handleNameChange = (e) => {
    if (selectedLanguage != null) {
      setLanguages(
        languages.map((l) => {
          if (l.language === selectedLanguage) {
            l.name = e.target.value;
          }
          return l;
        })
      );
    } else {
      setInputName(e.target.value);
    }
  };

  const renderModeAddOrEdit = () => {
    const {textInputNamePlaceholder, textPreview} = TEXTS;

    const name =
      selectedLanguage != null
        ? languages?.find((l) => l.language === selectedLanguage)?.name
        : inputName;

    return (
      <form className="fade-in-right" onSubmit={handleSubmit}>
        <div className="inputs-wrapper">
          <Input
            className="input-name"
            name="inputName"
            placeholder={selectedLanguage != null ? inputName : 'Name'}
            value={name}
            required
            onChange={handleNameChange}
          />
          {enableEditColorPicker === true && (
            <ColorPicker
              color={inputColor}
              disableAlpha
              onChange={(color) => setInputColor(color.hex)}>
              <Input
                labelTextLeft={
                  <span
                    className="preview-color"
                    style={{backgroundColor: inputColor}}></span>
                }
                className="input-color"
                placeholder="#FFFFFF"
                value={inputColor}
                disabled={selectedLanguage != null}
              />
            </ColorPicker>
          )}
        </div>
        {enableEditTagPreview === true && (
          <div className="tag-preview">
            <div className="tag-preview-overlay"></div>
            <TagButton
              interactive={false}
              tag={{
                name: inputName.length === 0 ? textInputNamePlaceholder : name,
                color: inputColor,
              }}
            />
            <p>{textPreview}</p>
          </div>
        )}
        <div className="actions">
          <Button
            muted
            onClick={() => {
              setMode(MODE_LIST);
              setTagToEditUid(null);
            }}>
            Cancel
          </Button>{' '}
          <Button primary loading={isSubmitting}>
            Confirm
          </Button>
        </div>
      </form>
    );
  };

  const renderModeList = () => {
    const {textEmptyList, textBtnAdd} = TEXTS;

    return (
      <div className="fade-in-left">
        {tags.length === 0 && <div className="empty">{textEmptyList}</div>}
        {tags.map((t) => (
          <TagEditor
            key={t.uid}
            tag={t}
            onEdit={() => {
              setMode(MODE_EDIT);
              setInputName(t.name);
              setInputColor(t.color);
              setTagToEditUid(t.uid);
              setLanguages(t.texts);
            }}
            onDelete={() => refetch()}
          />
        ))}
        {enableAdd === true && (
          <div className="actions">
            <Button
              primary
              iconRight="icon-plus"
              onClick={() => {
                setMode(MODE_ADD);
                setInputName('');
                setInputColor('#1557DB');
              }}>
              {textBtnAdd}
            </Button>
          </div>
        )}
      </div>
    );
  };

  const {textTitle} = TEXTS;

  const title = textTitle[mode];
  const classNames = classnames('modal-tag-manager', {
    'has-mode-add-or-edit': mode === MODE_ADD || mode === MODE_EDIT,
  });

  return (
    <Modal
      className={classNames}
      title={title}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      {...rest}>
      {mode === MODE_ADD || mode === MODE_EDIT
        ? renderModeAddOrEdit()
        : renderModeList()}
    </Modal>
  );
};

ModalTagManager.propTypes = propTypes;
ModalTagManager.defaultProps = defaultProps;

export default ModalTagManager;
