import React, {useRef, useState} from 'react';
import './_Styles.scss';
import {buildStormwindUrl} from 'helpers/utils';
import {projectService} from 'services';
import {generalSelector} from 'selectors';
import {useSelector} from 'react-redux';
import IframeResizer from 'iframe-resizer-react';
import {Swaler} from 'swaler';
import {func} from 'prop-types';

const logger = new Swaler('ChangelogDrawerPreview');

const propTypes = {
  onClose: func,
};

const defaultProps = {
  onClose: () => {},
};

const ChangelogDrawerPreview = ({onClose}) => {
  const iframeRef = useRef(null);

  const project = useSelector((state) => generalSelector.getProject(state));

  const url = buildStormwindUrl(
    projectService.getProjectPortalUrl(project),
    `/w/feed`
  );

  const [width, setWidth] = useState(null);

  return (
    <div
      className="changelog-drawer-preview"
      style={{
        ...(width != null ? {width: `${width}px`} : {}),
      }}
    >
      <div className="changelog-wrapper">
        <IframeResizer
          forwardRef={iframeRef}
          title="changelog-preview-iframe"
          loading="lazy"
          src={url}
          className="changelog-iframe"
          allowFullScreen
          sizeHeight={false}
          onMessage={({message}) => {
            try {
              const m = JSON.parse(message);

              switch (m.action) {
                case 'CLOSE_CHANGELOG': {
                  onClose();
                  break;
                }
                case 'POST_CHANGE_WIDTH': {
                  const width = m?.data?.width;
                  setWidth(width);
                  break;
                }
                default: {
                  return this.logger.debug(
                    `Received unknown action ${m.action}`
                  );
                }
              }
            } catch (err) {
              logger.error(`Couldn't parse message sent by widget: ${err}`);
            }
          }}
        />
      </div>
    </div>
  );
};

ChangelogDrawerPreview.propTypes = propTypes;
ChangelogDrawerPreview.defaultProps = defaultProps;

export default ChangelogDrawerPreview;
