import {BuilderContext} from 'contextes/builder';
import {hasFlag} from 'helpers/bitwise';
import {useContext} from 'react';
import {EVOLUTION_TYPE_CHECKLIST} from 'services/evolution';
import {
  BLOCK_TYPE_ANIMATION,
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHECKLIST_DISMISS,
  BLOCK_TYPE_CHECKLIST_HEADER,
  BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
  BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
  BLOCK_TYPE_CHECKLIST_TASK_LIST,
  BLOCK_TYPE_CHECKLIST_TRIGGER,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_CONCEPT,
  BLOCK_TYPE_HINT,
  BLOCK_TYPE_HOTSPOT,
  BLOCK_TYPE_INTERVIEW,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_NPS,
  BLOCK_TYPE_OPEN_QUESTION,
  BLOCK_TYPE_OPINION,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_SLIDER,
  BLOCK_TYPE_STEPPER,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
  STEP_TYPE_CHECKLIST,
} from 'services/steps';
import {F_SLOT_NAVIGATION} from 'shared/front/components/Poke/constants/poke';
import './_Styles.scss';
import Animation from './blocks/Animation';
import Button from './blocks/Button';
import ChecklistSettings from './blocks/Checklist';
import ChecklistDismiss from './blocks/ChecklistDismiss';
import ChecklistHeader from './blocks/ChecklistHeader';
import ChecklistItemButton from './blocks/ChecklistItemButton';
import ChecklistItemCheckbox from './blocks/ChecklistItemCheckbox';
import ChecklistList from './blocks/ChecklistList';
import ChecklistListItem from './blocks/ChecklistListItem';
import ChecklistProgress from './blocks/ChecklistProgress';
import ChecklistTrigger from './blocks/ChecklistTrigger';
import Concept from './blocks/Concept';
import Group from './blocks/Group';
import Hint from './blocks/Hint';
import Hotspot from './blocks/Hotspot';
import Interview from './blocks/Interview';
import Media from './blocks/Media';
import MultipleChoice from './blocks/MultipleChoice';
import Navigation from './blocks/Navigation';
import Nps from './blocks/Nps';
import OpenQuestion from './blocks/OpenQuestion';
import OpinionScale from './blocks/OpinionScale';
import Paragraph from './blocks/Paragraph';
import Profile from './blocks/Profile';
import Slider from './blocks/Slider';
import Stepper from './blocks/Stepper';
import Tag from './blocks/Tag';
import Title from './blocks/Title';

const propTypes = {};
const defaultProps = {};

const BlockEditor = () => {
  const {
    selectedStepId,
    selectedStep,
    selectedBlockType,
    selectedChecklistItemId,
    controlledEvolution,
  } = useContext(BuilderContext);

  const isNavigationStep = hasFlag(
    F_SLOT_NAVIGATION,
    controlledEvolution?.boostFlags
  );

  const isChecklist = controlledEvolution?.type === EVOLUTION_TYPE_CHECKLIST;
  const isChecklistStep = selectedStep?.type === STEP_TYPE_CHECKLIST;

  if ((selectedStepId == null || selectedStep == null) && !isNavigationStep) {
    return <></>;
  }

  return (
    <div
      className="poke-block-editor-wrapper"
      key={[selectedStepId, selectedChecklistItemId]}>
      {selectedBlockType == null &&
        (isChecklistStep ? (
          <ChecklistSettings />
        ) : !isNavigationStep ? (
          <Group updateStepStyle={isChecklist === true} />
        ) : (
          <></>
        ))}
      {[
        BLOCK_TYPE_TITLE,
        BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
        BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
      ].includes(selectedBlockType) && <Title />}
      {[
        BLOCK_TYPE_BODY,
        BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
        BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
      ].includes(selectedBlockType) && <Paragraph />}
      {[
        BLOCK_TYPE_PRIMARY_CTA,
        BLOCK_TYPE_SECONDARY_CTA,
        BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
        BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
      ].includes(selectedBlockType) &&
        (isChecklistStep ? <ChecklistItemButton /> : <Button />)}
      {selectedBlockType === BLOCK_TYPE_LABEL && <Tag />}
      {[BLOCK_TYPE_MEDIA, BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA].includes(
        selectedBlockType
      ) && <Media />}
      {selectedBlockType === BLOCK_TYPE_STEPPER && <Stepper />}
      {selectedBlockType === BLOCK_TYPE_USER && <Profile />}
      {selectedBlockType === BLOCK_TYPE_CHOICE && <MultipleChoice />}
      {selectedBlockType === BLOCK_TYPE_SLIDER && <Slider />}
      {selectedBlockType === BLOCK_TYPE_NPS && <Nps />}
      {selectedBlockType === BLOCK_TYPE_OPINION && <OpinionScale />}
      {selectedBlockType === BLOCK_TYPE_ANIMATION && <Animation />}
      {selectedBlockType === BLOCK_TYPE_OPEN_QUESTION && <OpenQuestion />}
      {selectedBlockType === BLOCK_TYPE_CONCEPT && <Concept />}
      {selectedBlockType === BLOCK_TYPE_INTERVIEW && <Interview />}
      {selectedBlockType === BLOCK_TYPE_HOTSPOT && <Hotspot />}
      {selectedBlockType === BLOCK_TYPE_HINT && <Hint />}
      {isNavigationStep && <Navigation />}
      {selectedBlockType === BLOCK_TYPE_CHECKLIST_HEADER && <ChecklistHeader />}
      {selectedBlockType === BLOCK_TYPE_CHECKLIST_TASK_LIST && (
        <ChecklistList />
      )}
      {selectedBlockType === BLOCK_TYPE_CHECKLIST_TASK_ITEM && (
        <ChecklistListItem />
      )}
      {selectedBlockType === BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX && (
        <ChecklistItemCheckbox />
      )}
      {selectedBlockType === BLOCK_TYPE_CHECKLIST_TRIGGER && (
        <ChecklistTrigger />
      )}
      {selectedBlockType === BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS && (
        <ChecklistProgress />
      )}
      {selectedBlockType === BLOCK_TYPE_CHECKLIST_DISMISS && (
        <ChecklistDismiss />
      )}
    </div>
  );
};

BlockEditor.propTypes = propTypes;
BlockEditor.defaultProps = defaultProps;

export default BlockEditor;
