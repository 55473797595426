import classNames from 'classnames';
import {InteractiveOption} from 'components/InteractiveOption';
import Label from 'components/Label';
import {hasFlag} from 'helpers/bitwise';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {number} from 'prop-types';
import React, {useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {AudienceContext, SECTION_GOAL} from 'scenes/PokeAudience';
import AudienceSection from 'scenes/PokeAudience/components/AudienceSection';
import EditTrackerDrawer from 'scenes/Tracker/components/TrackerHeader/components/EditTrackerDrawer';
import {generalSelector} from 'selectors';
import {
  F_PRICING_V0,
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import {TRACKER_TYPE_GOAL} from 'services/tracker';
import './_Styles.scss';

export const getGoalTitle = (evolution) => {
  let goalTitle = 'No goal set';

  const hasSetupGoal =
    evolution.tracker?.type === TRACKER_TYPE_GOAL &&
    (evolution.tracker?.events?.length > 0 ||
      evolution.tracker?.onTheFlyEvent != null);

  if (hasSetupGoal) {
    if (evolution.tracker?.events?.length > 0) {
      const event = evolution.tracker?.events[0];
      goalTitle = `Tracked goal: ${event.name}`;
    } else if (evolution.tracker?.onTheFlyEvent != null) {
      goalTitle = `Tracked goal: on the fly event`;
    }
  }

  return goalTitle;
};

const propTypes = {
  index: number.isRequired,
};

const Goal = () => {
  const {evolution, setEvolution} = useContext(AudienceContext);
  const {update} = useUpdateSubscription();

  const subscription = useSelector((state) =>
    generalSelector.getProjectSubscription(state)
  );
  const [showGoalModal, setShowGoalModal] = useState(false);

  const hasSubscriptionAccessToSuccessTracker =
    [PLAN_GROWTH_ID, PLAN_SCALE_ID].includes(subscription.plan) ||
    (subscription.plan === PLAN_STARTUP_ID &&
      hasFlag(F_PRICING_V0, subscription.extraFlags));
  const hasSetupGoal =
    evolution.tracker?.type === TRACKER_TYPE_GOAL &&
    (evolution.tracker?.events?.length > 0 ||
      evolution.tracker?.onTheFlyEvent != null);

  return (
    <AudienceSection
      className={classNames('goal')}
      section={SECTION_GOAL}
      title="Goal"
      subtitle={
        <Label secondary size="small" type="neutral">
          {getGoalTitle(evolution)}
        </Label>
      }
      icon={<i className="isax isax-medal" />}>
      <div className="goal-editor-wrapper">
        <div className="sub-section-title body-3 n-700">
          Choose how to measure the success of this flow based on what users
          accomplished after seeing it.
        </div>
        <InteractiveOption
          active
          iconComponent={<i className="icon icon-eye" />}
          title="Track views and completion stats"
          description="Monitor the views and completion rates of this experience."
          withCheckBox
          disabled
        />
        <span />
        <InteractiveOption
          active={hasSetupGoal === true}
          iconComponent={<i className="icon isax isax-status-up" />}
          title="Track a goal"
          description="Measure the success of this experience by monitoring an in-app event."
          onClick={() => {
            if (hasSubscriptionAccessToSuccessTracker === false) {
              return update({
                planId: PLAN_GROWTH_ID,
                title: 'Success Tracker',
                description:
                  'Track your experience goal and analyze your product adoption',
              });
            }
            if (evolution.tracker == null) {
              setEvolution({
                ...evolution,
                tracker: {
                  type: TRACKER_TYPE_GOAL,
                },
              });
            }
            setShowGoalModal(true);
          }}
          onToggleClick={(e) => {
            if (evolution.tracker != null) {
              e.stopPropagation();

              setEvolution({
                ...evolution,
                tracker: null,
              });
            }
          }}
          withCheckBox
        />
      </div>

      {showGoalModal && (
        <EditTrackerDrawer
          isOpen={showGoalModal}
          onRequestClose={() => {
            setShowGoalModal(false);
          }}
          tracker={{
            ...evolution.tracker,
            uid: null,
          }}
          onSave={(tracker) => {
            setEvolution({
              ...evolution,
              tracker: {
                ...tracker,
                uid: evolution.tracker?.uid,
              },
            });
            setShowGoalModal(false);
          }}
        />
      )}
    </AudienceSection>
  );
};

Goal.propTypes = propTypes;

export default Goal;
