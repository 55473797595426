import classNames from 'classnames';
import Button from 'components/Button';
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import dayjs from 'dayjs';
import {object, string} from 'prop-types';
import React, {useEffect} from 'react';
import {useQuery} from 'react-query';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {ROUTE_POKE_SETTINGS_FROM_TYPE} from 'router/routes.const';
import {TIME_FREQUENCY_DAILY} from 'scenes/Tracker/components/FeatureUsageChart/components/FrequencySelectionDropdown';
import StackedLineChart from 'scenes/Tracker/components/FeatureUsageChart/components/StackedLineChart';
import {trackerAnalyticsService} from 'services';
import {Swaler} from 'swaler';
import {generateDatesArray} from '../..';
import './_Styles.scss';

const propTypes = {
  evolution: object.isRequired,
  selectedSegment: string,
};

const defaultProps = {
  selectedSegment: null,
};

const logger = new Swaler('GoalChart');

const GoalChart = ({evolution, timeRange}) => {
  const history = useHistory();

  const [startDate, endDate] = timeRange;

  const {data: analyticsDaily, refetch: refetchAnalyticsDaily} = useQuery({
    queryKey: ['analyticsDaily', 'goal', evolution.uid, startDate, endDate],
    queryFn: () => {
      return trackerAnalyticsService.getAnalyticsDaily({
        startDate,
        endDate: dayjs(endDate).add(1, 'day').toDate(),
        trackerId: evolution.tracker?.uid,
      });
    },
    enabled: evolution.tracker != null,
    onError: (err) => {
      logger.error('Could not fetch analytics, failed with err', err.message);
    },
  });

  useEffect(() => {
    if (evolution.tracker != null) {
      refetchAnalyticsDaily();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange]);

  const datesArr = generateDatesArray(startDate, endDate).filter(
    (d) => !d.startOf('day').isAfter(dayjs().startOf('day'))
  );

  const datesArrWithAnalytics = datesArr.map((cur) => {
    const date = cur.format('YYYY-MM-DD');
    const timestamp = cur.unix();

    const foundData = analyticsDaily?.filter((d) => {
      return dayjs(parseInt(d.createdAt, 10)).isSame(cur, 'day');
    });
    let count = 0;

    if (foundData?.length > 0) {
      count = foundData.reduce(
        (acc, cur) => (acc += parseInt(cur.unique_count, 10)),
        0
      );
    }

    return {
      date,
      timestamp,
      count,
    };
  });

  return (
    <div
      className={classNames('goal-chart-wrapper', {
        empty: evolution.tracker == null,
      })}>
      {evolution.tracker == null ? (
        <div className="empty-state-wrapper">
          <EmptyStateBlock
            img={EmptyStateImgs.EmptyGoal}
            title="Start measuring the success of your experience"
            description="Configure a goal in the experience settings to define a goal"
          />
          <Button
            primary
            iconLeft={'icon-pencil-edit-outline'}
            onClick={() =>
              history.push(
                ROUTE_POKE_SETTINGS_FROM_TYPE(evolution.uid, evolution.type)
              )
            }>
            Start measuring
          </Button>
        </div>
      ) : (
        <div
          className={classNames('goal-chart', {
            empty: analyticsDaily == null,
          })}>
          {analyticsDaily != null ? (
            <StackedLineChart
              tracker={evolution.tracker}
              data={datesArrWithAnalytics}
              userChart
              frequency={TIME_FREQUENCY_DAILY}
            />
          ) : (
            <div className="no-data">No data to show</div>
          )}
        </div>
      )}
    </div>
  );
};

GoalChart.propTypes = propTypes;
GoalChart.defaultProps = defaultProps;

export default GoalChart;
