import classNames from 'classnames';
import Divider from 'components/Divider';
import Dropdown from 'components/Dropdown';
import {BuilderContext} from 'contextes/builder';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {func} from 'prop-types';
import React, {useContext} from 'react';
import {
  F_OPTION_COMMENTS_ENABLED,
  F_OPTION_LIKE_ENABLED,
} from 'services/evolution';
import './_Styles.scss';
import {
  BLOCKS,
  POST_BLOCK_CATEGORY_CONTENT,
  POST_BLOCK_CATEGORY_SOCIAL,
  POST_BLOCK_TYPE_COMMENTS,
  POST_BLOCK_TYPE_LIKES,
  getDefaultBlockFromType,
} from './utils';

const propTypes = {
  onClose: func,
};
const defaultProps = {
  onClose: () => {},
};

const SectionItem = ({block, selected = false, ...rest}) => {
  const {name, image, icon} = block;
  return (
    <div className={classNames('section-item', {selected: selected})} {...rest}>
      <div className="image-wrapper">
        <img src={image} alt="img" />
        <i className="icon-checkbox" />
        <i className="icon-plus-rounded" />
      </div>
      <div className="item-name">{name}</div>
    </div>
  );
};

const PostBlockManager = ({trigger, onClose}) => {
  const {evolution, setEvolution} = useContext(BuilderContext);

  const addBlock = (type) => {
    if ([POST_BLOCK_TYPE_COMMENTS, POST_BLOCK_TYPE_LIKES].includes(type)) {
      if (type === POST_BLOCK_TYPE_COMMENTS) {
        setEvolution({
          ...evolution,
          optionsFlags: addFlag(
            F_OPTION_COMMENTS_ENABLED,
            evolution.optionsFlags
          ),
        });
      } else {
        setEvolution({
          ...evolution,
          optionsFlags: addFlag(F_OPTION_LIKE_ENABLED, evolution.optionsFlags),
        });
      }
    } else {
      setEvolution({
        ...evolution,
        steps: [
          {
            ...evolution.steps[0],
            blocks: [
              ...(evolution.steps[0]?.blocks || []),
              getDefaultBlockFromType(type),
            ],
          },
        ],
      });
    }
    onClose();
  };

  const removeBlock = (type) => {
    if ([POST_BLOCK_TYPE_COMMENTS, POST_BLOCK_TYPE_LIKES].includes(type)) {
      if (type === POST_BLOCK_TYPE_COMMENTS) {
        setEvolution({
          ...evolution,
          optionsFlags: removeFlag(
            F_OPTION_COMMENTS_ENABLED,
            evolution.optionsFlags
          ),
        });
      } else {
        setEvolution({
          ...evolution,
          optionsFlags: removeFlag(
            F_OPTION_LIKE_ENABLED,
            evolution.optionsFlags
          ),
        });
      }
    } else {
      setEvolution({
        ...evolution,
        steps: [
          {
            ...evolution.steps[0],
            blocks: evolution.steps[0]?.blocks.filter(
              (block) => block.type !== type
            ),
          },
        ],
      });
    }
  };

  const step = evolution.steps[0] || {};
  const {blocks = []} = step;

  const filteredBlocks = BLOCKS.filter((block) => {
    return true;
  });

  const contentBlocks = filteredBlocks.filter(
    (block) => block.category === POST_BLOCK_CATEGORY_CONTENT
  );
  const socialBlocks = filteredBlocks.filter(
    (block) => block.category === POST_BLOCK_CATEGORY_SOCIAL
  );

  return (
    <Dropdown trigger={trigger}>
      <div className="block-manager-wrapper">
        <div className="block-manager">
          <div className="block-manager-header">
            <div className="block-manager-title">Add elements</div>
          </div>
          <Divider />
          <div className="sections">
            {contentBlocks.length > 0 && (
              <>
                <div className="section">
                  <div className="section-title">Text</div>
                  <div className="section-items">
                    {contentBlocks.map((block, index) => {
                      const isActive = blocks.find(
                        (b) => b.type === block.type
                      );

                      return (
                        <SectionItem
                          key={index}
                          block={block}
                          selected={isActive}
                          onClick={() => {
                            isActive
                              ? removeBlock(block.type)
                              : addBlock(block.type);
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
                <Divider />
              </>
            )}
            {socialBlocks.length > 0 && (
              <div className="section">
                <div className="section-title">Interactions</div>
                <div className="section-items">
                  {socialBlocks.map((block, index) => {
                    const isActive =
                      block.type === POST_BLOCK_TYPE_COMMENTS
                        ? hasFlag(
                            F_OPTION_COMMENTS_ENABLED,
                            evolution.optionsFlags
                          )
                        : hasFlag(
                            F_OPTION_LIKE_ENABLED,
                            evolution.optionsFlags
                          );

                    return (
                      <SectionItem
                        key={index}
                        block={block}
                        selected={isActive}
                        onClick={() => {
                          isActive
                            ? removeBlock(block.type)
                            : addBlock(block.type);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dropdown>
  );
};

PostBlockManager.propTypes = propTypes;
PostBlockManager.defaultProps = defaultProps;

export default PostBlockManager;
