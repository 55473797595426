import Coupon from './Coupon';
import './_Styles.scss';

const Subscription = () => {
  return (
    <div className="s-settings-subscription">
      <Coupon />
    </div>
  );
};

export default Subscription;
