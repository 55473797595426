import SelectGroup from 'components/Select';
import {toastDanger} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import React from 'react';
import {useQuery} from 'react-query';
import {evolutionService} from 'services';
import {F_OPTION_PORTAL_DISPLAY_FEED} from 'services/evolution';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('SettingsOpenPost');

const SettingsOpenPost = ({action, setAction}) => {
  const {data: posts, isLoading} = useQuery({
    queryKey: 'posts',
    queryFn: async () => {
      try {
        const posts = await evolutionService.getEvolutions({
          relations: [],
          onlyForMode: F_OPTION_PORTAL_DISPLAY_FEED,
        });

        return posts;
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        logger.error('Fetch posts failed with error ', code);
        toastDanger([title, message], {actions});
      }
    },
  });

  const postOptions = posts?.map((post) => ({
    label: post.title,
    value: post.uid,
  }));

  return (
    <div className="settings-open-post">
      <div className="settings-open-post-title body-3">Open post:</div>
      <SelectGroup
        options={postOptions}
        value={postOptions?.find((e) => e.value === action.value)}
        onChange={(selected) => setAction({...action, value: selected.value})}
        isLoading={isLoading}
      />
    </div>
  );
};

export default SettingsOpenPost;
