import Divider from 'components/Divider';
import RadioGroup from 'components/RadioGroup';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import {BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX} from 'services/steps';
import PopupSetting from '../../components/PopupSetting';
import ClickableInput from '../../components/items/ClickableInput';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import IconPicker, {
  checklistCheckboxIconsList,
  HINT_ICON_BUILT_IN,
} from '../../components/modals/IconPicker';
import './_Styles.scss';

const borderStyleOptions = [
  {label: 'Solid', value: 'solid'},
  {label: 'Dashed', value: 'dashed'},
];

const ChecklistItemCheckbox = () => {
  const {
    selectedStep: step,
    updateBlock: uptBlock,
    selectedChecklistItemId,
  } = useContext(BuilderContext);

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX, updateObj);
  };

  const block = step?.blocks.find(
    (b) =>
      b.type === BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX &&
      b.parentBlockId === selectedChecklistItemId
  );

  if (block == null) {
    return <></>;
  }

  const {style, file} = block;

  return (
    <div className="block-settings checklist-item-checkbox">
      <div className="section">
        <div className="section-title">
          <i className="isax isax-main-component5" />
          Style
          <div className="section-title-description">
            <div className="body-4 n-700">For every</div>
            <div className="block-group subtitle-4 n-800">
              <div className="icon-wrapper">
                <i className="icon-tick" />
              </div>
              Checkmark
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <ChecklistItemCheckboxStyle
        style={style}
        updateStyle={updateBlock}
        file={file}
      />
    </div>
  );
};

export const ChecklistItemCheckboxStyle = ({style, updateStyle, file}) => {
  return (
    <>
      <div className="section">
        <div className="section-content">
          <div className="section-subtitle subtitle-4 n-800">Incomplete</div>
          <CheckboxIncompleteStyle style={style} updateStyle={updateStyle} />
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-content">
          <div className="section-subtitle subtitle-4 n-800">Completed</div>
          <CheckboxCompleteStyle
            style={style}
            updateStyle={updateStyle}
            file={file}
          />
        </div>
      </div>
    </>
  );
};

export const CheckboxIncompleteStyle = ({style, updateStyle}) => {
  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Background</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Background color"
            value={style.incompleteBackgroundColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, incompleteBackgroundColor: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Border</div>
        <div className="section-item-content">
          <ColorPickerInput
            title="Border color"
            value={style.incompleteBorderColor || null}
            onChange={(value) =>
              updateStyle({
                style: {
                  ...style,
                  incompleteBorderColor: value,
                },
              })
            }
            erasable
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Border style</div>
        <div className="section-item-content">
          <RadioGroup
            value={style.borderStyle}
            options={borderStyleOptions}
            onSelect={(value) => {
              updateStyle({
                style: {
                  ...style,
                  borderStyle: value,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Radius</div>
        <div className="section-item-content">
          <PixelPicker
            value={style.borderRadius}
            min={0}
            max={50}
            onChange={(value) =>
              updateStyle({
                style: {
                  ...style,
                  borderRadius: value,
                },
              })
            }
            radius
          />
        </div>
      </div>
    </>
  );
};

export const CheckboxCompleteStyle = ({style, updateStyle, file}) => {
  const selectedIcon = checklistCheckboxIconsList.find(
    (i) => i.value === style.iconName
  );

  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Background</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Background color"
            value={style.completedBackgroundColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, completedBackgroundColor: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title">Border</div>
        <div className="section-item-content">
          <ColorPickerInput
            title="Border color"
            value={style.completedBorderColor || null}
            onChange={(value) =>
              updateStyle({
                style: {
                  ...style,
                  completedBorderColor: value,
                },
              })
            }
            erasable
          />
        </div>
      </div>
      <div className="section-item item-icon-picker">
        <div className="section-item-title">Icon</div>
        <div className="section-item-content">
          <PopupSetting
            className="picker-icon-popup"
            trigger={
              <ClickableInput
                value={
                  style.iconSource === HINT_ICON_BUILT_IN
                    ? selectedIcon?.label
                    : style.iconUrl != null
                    ? 'Custom Icon'
                    : null
                }
                leftLabel={
                  <span className="preview-icon" style={{}}>
                    {style.iconSource === HINT_ICON_BUILT_IN ? (
                      selectedIcon?.icon
                    ) : style.iconUrl ? (
                      <img src={style.iconUrl} alt="" />
                    ) : (
                      <></>
                    )}
                  </span>
                }
                inputProps={{
                  placeholder: 'Select an icon',
                }}
              />
            }
            title="Checkmark icons"
            content={
              <IconPicker
                style={style}
                file={file}
                type="checkbox"
                onChange={({style: updatedStyle, file}) => {
                  updateStyle({
                    style: {
                      ...style,
                      ...updatedStyle,
                    },
                    file,
                  });
                }}
              />
            }
          />
        </div>
      </div>
      {style.iconSource === HINT_ICON_BUILT_IN && (
        <div className="section-item">
          <div className="section-item-title body-3">Icon color</div>
          <div className="section-item-content">
            <ColorPickerInput
              inputProps={{small: true}}
              title="Icon color"
              value={style.iconColor}
              onChange={(value) =>
                updateStyle({
                  style: {...style, iconColor: value},
                })
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ChecklistItemCheckbox;
